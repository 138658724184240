import { render, staticRenderFns } from "./order.vue?vue&type=template&id=d53fde36&scoped=true&"
import script from "./order.vue?vue&type=script&lang=js&"
export * from "./order.vue?vue&type=script&lang=js&"
import style0 from "./order.vue?vue&type=style&index=0&id=d53fde36&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d53fde36",
  null
  
)

export default component.exports