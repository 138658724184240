<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>我要提现</el-breadcrumb-item>
        <el-breadcrumb-item>提现明细</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="search-box">
        <div class="text">时间范围</div>

        <el-date-picker
          v-model="start_time"
          type="date"
          format="yyyy-MM-dd "
          value-format="yyyy-MM-dd "
          style="margin-left: 29px; width: 120px"
          placeholder="开始时间"
        >
        </el-date-picker>

        <span
          style="width: 17px; height: 2px; background: #ebf0f5; margin: 0 12px"
        ></span>

        <el-date-picker
          v-model="end_time"
          type="date"
          format="yyyy-MM-dd "
          value-format="yyyy-MM-dd "
          style="width: 120px"
          placeholder="结束时间"
        >
        </el-date-picker>

        <el-button
          type="warning"
          style="background: #e66617; margin-left: 46px"
          @click="search"
          >搜索</el-button
        >
      </div>

      <el-table :data="tableData" style="width: 100%; margin-top: 32px">
        <el-table-column prop="money" label="提现金额" align="center">
        </el-table-column>
        <el-table-column prop="create_time" label="提交时间" align="center">
        </el-table-column>

        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">审核中</span>
            <span v-if="scope.row.status == 2">审核通过</span>
            <span v-if="scope.row.status == 3">审核驳回</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { withdrawalList } from "../../request/api";
export default {
  data() {
    return {
      start_time: "", //开始时间
      end_time: "", //结束时间
      tableData: [],
      count: 1,
      size: 4,
      page: 1,
    };
  },

  created() {
    this.getData();
  },
  methods: {
    getData() {
      withdrawalList({
        start_time: this.start_time,
        end_time: this.end_time,
        page: "",
        size: "",
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
      });
    },
    //搜索
    search() {
      this.getData();
    },

    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 23px 68px 23px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .search-box {
    display: flex;
    align-items: center;
    margin-top: 39px;
    padding-left: 15px;

    .text {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
    }
  }
}

.page-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}
/deep/ .el-table th {
  padding: 0;
}
</style>