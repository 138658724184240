<template>
	<div>
		<div class="top">
			<div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 240px;
        ">
				<div class="title">上传作品</div>
				<!-- <div style="color: #A40000;">上传方式二选一</div> -->
			</div>
			<div class="content">
				<div style="margin-top: 37px">
					<el-upload class="avatar-uploader" action="/api/newapi/Archives/articleImgUpload?isimg=1"
						:show-file-list="false" :on-success="handleAvatarSuccess" :disabled="disabled"
						accept=".png,.jpg" :before-upload="beforeUpload">
						<img v-if="litpic" :src="imageUrl(litpic)" class="avatar" />
						<div v-else @click="disableTrue">
							<i class="el-icon-plus el-icon-circle-plus" style="font-size: 47px"></i>
							<div style="
                  font-size: 16px;
                  margin-top: 10px;
                  color: rgba(0, 0, 0, 1);
                "> 点击上传预览图 </div>
							<div style="
                  font-size: 12px;
                  margin-top: 10px;
                  color: rgba(102, 102, 102, 1);
                "> 正方形jpg格式或png格式，72分辨率，建议尺寸20*20cm </div>
						</div>
						<!-- <div class="upload-text">点击上传作品</div> -->
					</el-upload>
				</div>
				<div style="
            display: flex;
            align-item: center;
            justify-content: space-between;
          ">
					<div>
						<!-- <div class="title1">上传方式一</div> -->
						<div style="margin-top: 20px">
							<el-upload class="avatar-uploader" :show-file-list="false" action=""
								:on-progress="uploadVideoProcess" :disabled="disabled" :on-change="handleChange"
								:http-request="handleChange"
								accept=".JCH,.JAR,.JCS,.JCG,.JC8,.JC1,.tiff,.ai,.cdr,.eps,.pdf,.tif,.psd,.zip,.7z,.rar">
								<div v-if="sourceLitpic" style="
                    font-size: 16px;
                    margin-top: 10px;
                    color: rgba(0, 0, 0, 1);
                  "> 上传成功 </div>
								<div v-else @click="disableTrue">
									<i class="el-icon-plus el-icon-circle-plus" style="font-size: 47px"></i>
									<div style="
                      font-size: 16px;
                      margin-top: 10px;
                      color: rgba(0, 0, 0, 1);
                    "> 上传源文件 </div>
								</div>
								<!-- <div class="upload-text">点击上传作品</div> -->
							</el-upload>
							<el-progress v-if="progressFlag" :percentage="Number(percentage.toFixed(2))"></el-progress>
						</div>
						<div class="title1">作品名称</div>
						<el-input v-model="title" placeholder="点击输入作品名称" style="width: 343px"></el-input>
						<div class="title1">作品标价</div>
						<el-input v-model="users_price" placeholder="建议价格不得低于30元" style="width: 343px"></el-input>
						<div class="title1">上传格式</div>
						<el-select v-model="format" placeholder="请选择上传格式" style="width: 343px">
							<el-option label="PSD" value="PSD"></el-option>
							<el-option label="TIFF" value="TIFF"></el-option>
							<el-option label="AI" value="AI"></el-option>
							<el-option label="CDR" value="CDR"></el-option>
							<el-option label="EPS" value="EPS"></el-option>
							<el-option label="PDF" value="PDF"></el-option>
							<el-option label="金昌" value="金昌"></el-option>
						</el-select>
					</div>
					<!-- <div style="margin-left: 100px;">
						<div class="title1">上传方式二<span style="margin-left: 26px;color: rgba(164, 0, 0, 1);font-size: 14px;">上传大文件建议使用网盘上传</span></div>
						<el-input v-model="disklink" placeholder="点击输入网盘链接" style="width: 343px"></el-input>

						<el-input v-model="disklinkpwd" placeholder="点击输入网盘提取码" style="width: 343px"></el-input>
					</div> -->
				</div>
			</div>
		</div>
		<div class="keyWord">
			<div class="title">关键词</div>
			<el-divider></el-divider>
			<div class="keyWord-search">
				<el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="点击输入关键词，多个关键词用空格隔开"
					v-model="seo_keywords" resize="none">
				</el-input>
				<el-tooltip content="多个关键词用空格隔开" placement="top">
					<i class="el-icon-question" style="margin-left: 20px"></i>
				</el-tooltip>
			</div>
		</div>
		<div class="keyWord">
			<div class="title">上传板块</div>
			<el-divider></el-divider>
			<div style="padding-left: 54px">
				<el-select v-model="onceActive" placeholder="请选择" style="width: 343px" @change="onceChange">
					<el-option v-for="item in once" :key="item.value" :label="item.typename" :value="item.id">
					</el-option>
				</el-select>
				<el-form label-position="top" label-width="80px" style="margin-top: 48px" v-if="cateOption">
					<el-form-item :label="item.typename" style="margin-bottom: 30px" v-for="(item, index) in cateOption"
						:key="index">
						<div style="color: red" v-if="index != 1">可多选</div>
						<el-radio-group v-model="radio" v-if="index == 1">
							<el-radio-button :label="itemm.id" v-for="(itemm, indexx) in item.third"
								style="margin: 18px 18px; border: 1px solid #aab7c2" :key="indexx">{{ itemm.typename }}
							</el-radio-button>
						</el-radio-group>
						<el-checkbox-group v-model="checkList" v-if="index != 1">
							<el-checkbox-button style="margin: 18px 18px; border: 1px solid #aab7c2" :label="itemm.id"
								v-for="(itemm, indexx) in item.third"
								:key="indexx">{{ itemm.typename }}</el-checkbox-button>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="uploadDo" @click="submit">上传作品</div>
		<el-dialog :visible.sync="show" width="23%" center>
			<div class="titles">您尚未进行实名认证</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" style="background: #08243c" @click="go('/seller/sellerInfo')">点 击 认 证
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		realNameAuth,
		cate,
		arcTypeTh,
		uploading,
		userArticDetail,
	} from "@/request/api.js";
	let OSS = require("ali-oss"); // 引入ali-oss插件
	const client = new OSS({
		region: "oss-cn-hangzhou",
		success_action_status: "200", // 默认200
		accessKeyId: "LTAI5tKTamXV5wVyLAqD8gwX",
		accessKeySecret: "uegS74bKck0j6LpceJTswm3rs1OgRC",
		bucket: "drawing-by-drawing",
	});
	export default {
		data() {
			return {
				once: "", //一级分类列表
				cateOption: "", //二级三级列表
				onceActive: "", //一级分类选中
				radio: [], //分类选择
				show: false, //是否认证
				title: "", //作品名称
				users_price: "", //价格
				pattern: "", //模式
				disklink: "", //网盘链接
				disklinkpwd: "", //网盘密码
				size: "", //尺寸
				format: "", //格式（tif/psd）
				seo_keywords: "", //关键词
				litpic: "", //缩略图
				sourceLitpic: "",
				loadProgress: 0, // 动态显示进度条
				progressFlag: false, // 关闭进度条
				disabled: false,
				checkList: [],
				percentage: 0,
				isUpload: false
			};
		},
		watch: {
			percentage(a, b) {
				if (a == 100) {
					this.progressFlag = false; //
					this.sourceLitpic = true;
				}
			},
		},
		created() {
			this.realNameAuth();
			this.getCate();
		},
		methods: {
			disableTrue() {
				if (this.disabled == true) {
					this.realNameAuth();
				}
			},
			go() {
				localStorage.setItem("tabbar", "4");
				this.$router.push("/seller/sellerInfo");
			},
			beforeUpload(file) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
				const whiteList = ["jpg", "png"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message.warning("上传预览图只能是 jpg,png格式");
					return false;
				}
				const isLt2M = file.size / 1024 / 1024 < 1;
				if (!isLt2M) {
					this.$message.error('上传预览图大小不能超过 1MB!');
					return false;
				}
			},
			beforeUpload2(file) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
				const whiteList = ['JCH', 'JAR', 'JCS', 'JCG', 'JC8', 'JC1', 'tiff', 'ai', 'cdr', 'eps', 'pdf', 'tif',
					'psd', 'zip', '7z', 'rar'
				];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message.warning("上传源文件格式错误");
					return false;
				}
			},
			//判断用户是否实名认证
			realNameAuth() {
				realNameAuth().then((res) => {
					// console.log(res);
					this.disabled = false;
					if (res.code == 500) {
						this.disabled = true;
						this.show = !this.show;
					}
				});
			},
			//获取一级分类
			getCate() {
				cate().then((res) => {
					this.once = res.data;
					// console.log(this.once);
				});
			},
			//一级分类改变
			onceChange(id) {
				arcTypeTh({
					id,
				}).then((res) => {
					this.cateOption = res.data;
				});
			},
			//上传成功回调
			handleAvatarSuccess(res, file) {
				console.log(res);
				console.log(file);
				this.litpic = res.data.img;
				console.log(this.litpic);
				if (res.code != 200) {
					this.$message.error(res.msg);
				}
			},
			handleAvatarSuccess1(res, file) {
				console.log(res);
				console.log(file);
				this.loadProgress = 100;
				this.sourceLitpic = res.data.img;
				console.log(this.sourceLitpic);
				setTimeout(() => {
					this.progressFlag = false;
				}, 1000); // 一秒后关闭进度条
			},
			uploadVideoProcess(event, file, fileList) {
				this.progressFlag = true; // 显示进度条
				this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
				console.log(this.loadProgress);
				if (this.loadProgress >= 100) {
					this.loadProgress = 99;
				}
			},
			// 选择文件发生改变
			handleChange(file, fileList) {
				this.fileList = fileList;
				const today = new Date();
				const year = today.getFullYear();
				const month = String(today.getMonth() + 1).padStart(2, '0');
				const day = String(today.getDate()).padStart(2, '0');
				this.multipartUpload("/UploadSeparately/" + year + month + day + '/', file.raw, file);
			},
			// 切片上传
			async multipartUpload(url, file, percentage) {
				console.log('multipartUpload:', percentage);
				if (this.isUpload) {
					return;
				} else {
					this.isUpload = true;
				}
				if (!file) {
					this.$message.error("请选择文件");
					return;
				}
				this.progressFlag = true; // 显示进度条
				this.uploadStatus = null;
				this.percentage = 0;
				try {
					const headers = {
						"Content-Disposition": "inline",
						"Content-Type": file.type,
					};
					let result = await client.multipartUpload(url + Date.now() + "_" + file.name, file, {
						headers: headers,
						timeout: 600 * 1000,
						progress: (p, checkpoint) => {
							if (checkpoint != undefined) {
								this.tempCheckpoint = checkpoint;
								this.upload = checkpoint.uploadId;
								this.uploadName = checkpoint.name;
								console.log('p:', p);
								this.percentage = p * 100;
							} else {
								this.percentage = 100;
							}
						},
						parallel: 4,
						// 设置分片大小。默认值为1 MB，最小值为100 KB。
						partSize: 1024 * 1024,
						meta: {
							year: 2020,
							people: "dev"
						},
						mime: file.type,
					});
					this.sourceLitpic = `https://image.yituhuitu.com${result.name}`;
					this.$message.success("上传成功");
					this.isUpload = false
				} catch (e) {
					console.log(e);
					window.addEventListener("online", this.resumeUpload); // 该监听放在断网的异常处理
					// 捕获超时异常。
					if (e.code === "ConnectionTimeoutError") {
						// 请求超时异常处理
						this.uploadStatus = "exception";
					}
				}
			},
			submit() {
				// console.log(this.radio);
				// console.log(this.checkList);
				// return;
				if (this.litpic == "" || this.litpic == null) {
					this.$message.error("请上传缩略图");
					return;
				}
				if (
					(this.sourceLitpic == "" || this.sourceLitpic == null) && (this.disklink == "" || this.disklink ==
						null)) {
					this.$message.error("请上传源文件或请输入网盘链接");
					return;
				}
				// if (this.disklink == "" || this.disklink == null) {
				// 	this.$message.error("请输入网盘链接");
				// 	return;
				// }
				if (
					(this.disklinkpwd == "" || this.disklinkpwd == null) && this.disklink != "") {
					this.$message.error("请输入网盘密码");
					return;
				}
				if (this.title == "" || this.title == null) {
					this.$message.error("请输入作品名称");
					return;
				}
				if (this.users_price == "" || this.users_price == null) {
					this.$message.error("请输入价格");
					return;
				}
				if (this.format == "" || this.format == null) {
					this.$message.error("请输入选择格式");
					return;
				}
				if (this.onceActive == "" || this.onceActive == null) {
					this.$message.error("请选择上传板块");
					return;
				}
				if (this.seo_keywords == "" || this.seo_keywords == null) {
					this.$message.error("请输入关键词");
					return;
				}
				if (this.radio.length == 0) {
					this.$message.error("请选择分类");
					return;
				}
				if (this.radio == null) {
					this.$message.error("请选择分类");
					return;
				}
				var typeid = this.onceActive.toString() + "," + this.radio.toString() + "," + this.checkList.toString();
				uploading({
					litpic: this.litpic,
					title: this.title,
					users_price: this.users_price,
					pattern: this.pattern,
					disklink: this.disklink,
					disklinkpwd: this.disklinkpwd,
					size: this.size,
					format: this.format,
					seo_keywords: this.seo_keywords,
					typeid: typeid,
					raw_litpic: this.sourceLitpic,
				}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						this.$router.push("/seller/manage");
					}
					if (res.code == 500) {
						this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.top {
		background: #ffffff;
		border-radius: 6px;
		padding: 34px 54px 26px 54px;

		.title {
			font-size: 20px;
			font-weight: 400;
			color: #08243c;
			line-height: 20px;
		}

		.title::after {
			content: "";
			width: 100px;
			height: 9px;
			background: #e66617;
			display: block;
			margin-top: -8px;
		}

		.content {}
	}

	.keyWord {
		width: 920px;
		background: #ffffff;
		border-radius: 6px;
		margin-top: 19px;
		padding-bottom: 37px;

		.title {
			font-size: 18px;
			font-weight: 400;
			color: #08243c;
			line-height: 18px;
			padding-left: 23px;
			padding-top: 33px;
		}

		.keyWord-search {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 54px;
		}
	}

	.uploadDo {
		width: 186px;
		height: 50px;
		background: #08243c;
		border-radius: 6px;
		font-size: 18px;
		font-weight: 400;
		color: #ffffff;
		line-height: 50px;
		text-align: center;
		margin-top: 28px;
		cursor: pointer;
	}

	.titles {
		width: 100%;
		font-size: 18px;
		font-weight: 400;
		color: #000000;
		line-height: 36px;
		text-align: center;
	}

	/deep/.el-input__inner,
	/deep/.el-textarea__inner {
		background: rgba(8, 36, 60, 0.06);
	}

	/deep/.el-input {
		margin-top: 22px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	/deep/ .el-upload {
		width: 237px;
		height: 154px;
		background: rgba(8, 36, 60, 0.06);
		// opacity: 0.06;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;

		.upload-text {
			font-size: 16px;
			font-weight: 400;
			color: #000000;
			line-height: 36px;
		}
	}

	/deep/.avatar[data-v-bc227386] {
		width: 237px;
		height: 154px;
		display: block;
	}

	/deep/.el-checkbox-button__inner,
	/deep/ .el-radio-button__inner {
		width: 130px;
		height: 50px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #08243c;
		line-height: 24px;
		// line-height: 50px;
	}

	.title1 {
		font-size: 16px;
		font-weight: 400;
		color: #08243c;
		line-height: 18px;
		padding-top: 33px;
	}
</style>