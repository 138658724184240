<template>
  <div>
    <div class="top">
      <div class="title">数据中心</div>

      <div class="recharge">
        <div class="recharge-title">充值金额</div>
        <el-divider></el-divider>
        <div class="month">
          <div class="money">￥{{ mothMoney }}元</div>
          <div class="month-text">当月充值金额</div>
        </div>

        <div class="month" style="margin-top: 47px">
          <div class="money">￥{{ money }}元</div>
          <div class="month-text">
            时间范围

            <el-date-picker
              v-model="start_time"
              type="date"
              style="width: 120px; margin-left: 46px"
              placeholder="开始时间"
               format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
            >
            </el-date-picker>
            <span style="margin: 0 12px">-</span>
            <el-date-picker
              v-model="end_time"
              type="date"
              style="width: 120px"
              placeholder="结束时间"
               format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
            >
            </el-date-picker>

            <el-button
              type="warning"
              style="background: #e66617; margin-left: 46px"
              @click="search"
              >搜索</el-button
            >
          </div>
        </div>
      </div>

      <div class="recharge" style="margin-top: 0">
        <div class="recharge-title">月下载作品金额</div>
        <el-divider></el-divider>
        <div class="month">
          <div class="money">￥{{ mdownloadMoney }}元</div>
          <div class="month-text">当月下载作品金额</div>
        </div>

        <div class="month" style="margin-top: 47px">
          <div class="money">￥{{   downloadMoney }}元</div>
          <div class="month-text">
            时间范围

            <el-date-picker
              v-model="start_timeb"
              type="date"
              style="width: 120px; margin-left: 46px"
              placeholder="开始时间"
               format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
            >
            </el-date-picker>
            <span style="margin: 0 12px">-</span>
            <el-date-picker
              v-model="end_timeb"
              type="date"
              style="width: 120px"
              placeholder="结束时间"
               format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
            >
            </el-date-picker>

            <el-button
              type="warning"
              style="background: #e66617; margin-left: 46px"
                @click="search"
              >搜索</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataCenter } from "../../request/api";
export default {
  data() {
    return {
      start_time: "", //充值金额开始
      end_time: "", //充值金额结束
      start_timeb: "", //月下载作品金额开始
      end_timeb: "", //月下载作品金额结束

      downloadMoney: "", //当月下载作品金额
      mdownloadMoney: "", //选择下载作品金额
      money: "", //选择充值金额
      mothMoney: "", //当月充值金额
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      dataCenter({
        type: 1,
        start_time: this.start_time,
        end_time: this.end_time,
        start_timeb: this.start_timeb,
        end_timeb: this.end_timeb,
      }).then((res) => {
        this.downloadMoney = res.data.downloadMoney;
        this.mdownloadMoney = res.data.mdownloadMoney;
        this.money = res.data.money;
        this.mothMoney = res.data.mothMoney;
      });
    },


    search(){
      this.getData()
    }
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 23px 0 23px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .recharge {
    margin-top: 47px;
    padding-bottom: 74px;
    .recharge-title {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
    }

    .month {
      display: flex;
      align-items: center;
      .money {
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
      }

      .month-text {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        padding-left: 178px;
        display: flex;
        align-items: center;
      }
    }
  }
}

/deep/.el-divider--horizontal {
  margin: 20px 0 35px 0;
}
</style>