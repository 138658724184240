<template>
  <div>
    <div class="top">
      <div
        v-show="!alterShow"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 200px;
          font-size: 20px;
          color: #08243c;
        "
      >
        <div>作品管理</div>
        <div>></div>
        <div>查看详情</div>
      </div>
      <div
        v-show="alterShow"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 200px;
          font-size: 20px;
          color: #08243c;
        "
      >
        <div style="cursor: pointer" @click="return1">作品管理</div>
        <div>></div>
        <div>修改作品</div>
      </div>

      <div class="content">
		<div class="uploadDo1" v-show="!alterShow" @click="alter">修改作品</div>
        <div style="margin-top: 37px">
          <el-upload
            class="avatar-uploader"
            action="/api/newapi/Archives/articleImgUpload?isimg=1"
            :show-file-list="false"
			accept=".png,.jpg"
            :on-success="handleAvatarSuccess"
            v-bind:disabled="disabledShow"
			:before-upload="beforeUpload"
          >
            <img v-if="litpic" :src="imageUrl(litpic)" class="avatar" />
            <div v-else>
              <i
                class="el-icon-plus el-icon-circle-plus"
                style="font-size: 47px"
              ></i>
              <div
                style="
                  font-size: 16px;
                  margin-top: 10px;
                  color: rgba(0, 0, 0, 1);
                "
              >
                点击上传预览图
              </div>
              <div
                style="
                  font-size: 12px;
                  margin-top: 10px;
                  color: rgba(102, 102, 102, 1);
                "
              >
                正方形PNG格式,72分辨率,建议尺寸20*20cm
              </div>
            </div>
          </el-upload>
        </div>
        <div
          style="
            display: flex;
            align-item: center;
            justify-content: space-between;
          "
        >
          <div>
            <!-- <div class="title1"  v-show="alterShow">上传方式一</div> -->
			<div style="margin-top: 20px">
			  <el-upload
                class="avatar-uploader"
                v-show="alterShow"
                action=""
                :show-file-list="false"
                :on-progress="uploadVideoProcess"
                :on-success="handleAvatarSuccess1"
                v-bind:disabled="disabledShow"
				accept=".JCH,.JAR,.JCS,.JCG,.JC8,.JC1,.tiff,.ai,.cdr,.eps,.pdf,.tif,.psd,.zip,.7z,.rar"
				:before-upload="beforeUpload2"
              >
                <!-- <img v-if="sourceLitpic" :src="imageUrl(sourceLitpic)" class="avatar" /> -->
                <div
                  v-if="sourceLitpic"
                  style="
                    font-size: 16px;
                    margin-top: 10px;
                    color: rgba(0, 0, 0, 1);
                  "
                >
                  上传成功
                </div>
                <div v-else>
                  <i
                    class="el-icon-plus el-icon-circle-plus"
                    style="font-size: 47px"
                  ></i>
                  <div
                    style="
                      font-size: 16px;
                      margin-top: 10px;
                      color: rgba(0, 0, 0, 1);
                    "
                  >
                    上传源文件
                  </div>
                </div>
                <!-- <div class="upload-text">点击上传作品</div> -->
              </el-upload>
              <el-progress
                v-if="progressFlag"
                :percentage="loadProgress"
              ></el-progress>
            </div>
            <div class="title1">作品名称</div>
            <el-input
              v-model="title"
              v-bind:disabled="disabledShow"
              placeholder="点击输入作品名称"
              style="width: 343px"
            ></el-input>
            <div class="title1">
              作品标价<span
                v-show="alterShow"
                style="
                  margin-left: 26px;
                  color: rgba(164, 0, 0, 1);
                  font-size: 14px;
                "
                >建议价格不得低于30元</span
              >
            </div>
            <el-input
              v-model="users_price"
              placeholder="点击输入标价"
              style="width: 343px"
              v-bind:disabled="disabledShow"
            ></el-input>
            <div class="title1">上传格式</div>
            <el-input
              v-show="!alterShow"
              v-model="format"
              style="width: 343px"
              v-bind:disabled="disabledShow"
              placeholder="请选择上传格式"
            ></el-input>
            <el-select
              v-show="alterShow"
              v-model="format"
              placeholder="请选择上传格式"
              style="width: 343px"
            >
              <el-option label="PSD" value="PSD"></el-option>
              <el-option label="TIFF" value="TIFF"></el-option>
              <el-option label="AI" value="AI"></el-option>
              <el-option label="CDR" value="CDR"></el-option>
              <el-option label="EPS" value="EPS"></el-option>
              <el-option label="PDF" value="PDF"></el-option>
              <el-option label="金昌" value="金昌"></el-option>
            </el-select>
          </div>
          <!-- <div style="margin-left: 100px;">
						<div class="title1"  v-show="alterShow">上传方式二<span
								style="margin-left: 26px;color: rgba(164, 0, 0, 1);font-size: 14px;">上传大文件建议使用网盘上传</span>
						</div>
						<el-input v-model="disklink" placeholder="点击输入PSD格式或TIF格式网盘链接" style="width: 343px" v-bind:disabled="disabledShow">
						</el-input>

						<el-input v-model="disklinkpwd" placeholder="点击输入网盘密码" style="width: 343px" v-bind:disabled="disabledShow"></el-input>
					</div> -->
        </div>
      </div>
    </div>

    <div class="keyWord">
      <div class="title">关键词</div>
      <el-divider></el-divider>
      <div class="keyWord-search">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="点击输入关键词，多个关键词用空格隔开"
          v-model="seo_keywords"
          resize="none"
          v-bind:disabled="disabledShow"
        >
        </el-input>

        <el-tooltip content="多个关键词用空格隔开" placement="top">
          <i class="el-icon-question" style="margin-left: 20px"></i>
        </el-tooltip>
      </div>
    </div>

    <div class="keyWord">
      <div class="title">上传板块</div>
      <el-divider></el-divider>

      <div style="padding-left: 54px" v-show="!alterShow">
        <el-input
          v-model="one"
          style="width: 343px"
          v-bind:disabled="disabledShow"
          placeholder="请选择"
        ></el-input>

        <el-form
          label-position="top"
          label-width="80px"
          style="margin-top: 48px"
        >
          <!-- <el-form-item
            :label="item.typename"
            style="margin-bottom: 30px"
            v-for="(item, index) in twoThird"
            :key="index"
          >
            <el-button type="primary" class="cate-active">
              {{ item.son.typename }}
            </el-button>
          </el-form-item> -->
        </el-form>
      </div>

      <div style="padding-left: 54px" v-show="alterShow">
        <el-select
          v-model="onceActive"
          placeholder="请选择"
          style="width: 343px"
          @change="onceChange"
        >
          <el-option
            v-for="item in once"
            :key="item.value"
            :label="item.typename"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-form
          label-position="top"
          label-width="80px"
          style="margin-top: 48px"
          v-if="cateOption"
        >
          <el-form-item
            :label="item.typename"
            style="margin-bottom: 30px"
            v-for="(item, index) in cateOption"
            :key="index"
          >
            <div style="color: red" v-if="index != 1">可多选</div>
            <el-radio-group v-model="radio" v-if="index == 1">
              <el-radio-button
                :label="itemm.id"
                v-for="(itemm, indexx) in item.third"
                style="margin: 18px 18px; border: 1px solid #aab7c2"
                :key="indexx"
                >{{ itemm.typename }}
              </el-radio-button>
            </el-radio-group>
            <el-checkbox-group v-model="checkList" v-if="index != 1">
              <el-checkbox-button
                style="margin: 18px 18px; border: 1px solid #aab7c2"
                :label="itemm.id"
                v-for="(itemm, indexx) in item.third"
                :key="indexx"
                >{{ itemm.typename }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="uploadDo1" v-show="!alterShow" @click="alter">修改作品</div>
    <div class="uploadDo" v-show="alterShow" @click="submit">上传作品</div>
  </div>
</template>

<script>
import {
  userArticDetail,
  userArticDetailType,
  cate,
  arcTypeTh,
  uploading,
} from "@/request/api.js";
export default {
  data() {
    return {
      title: "",
      litpic: "",
      author: "",
      users_price: "",
      arcrank: "",
      disklink: "",
      disklinkpwd: "",
      size: "",
      format: "",
      seo_keywords: "",
      pattern: "",

      one: "",
      oneId: "",
      twoThird: "",
      sourceLitpic: "",
      disabledShow: true,
      once: "", //一级分类列表
      cateOption: "", //二级三级列表
      onceActive: "",
      radio: [], //分类选择
      alterShow: false,
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      checkList: []
    };
  },

  created() {
    this.getData();
    this.getCate();
    this.getUserArticDetailType();
  },

  methods: {
    //获取一级分类
    getCate() {
      cate().then((res) => {
        this.once = res.data;
        console.log(this.once);
      });
    },
    uploadVideoProcess(event, file, fileList) {
      console.log(event);
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
	beforeUpload(file) {
	  const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
	
	  const whiteList = ["jpg", "png"];
	
	  if (whiteList.indexOf(fileSuffix) === -1) {
	    this.$message.warning("上传预览图只能是 jpg,png格式");
	    return false;
	  }
	},
	beforeUpload2(file) {
		const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
	
		const whiteList = ['JCH','JAR','JCS','JCG','JC8','JC1','tiff','ai','cdr','eps','pdf','tif','psd','zip','7z','rar'];
	
		if (whiteList.indexOf(fileSuffix) === -1) {
		this.$message.warning("上传源文件格式错误");
		return false;
		}
	},
    //一级分类改变
    onceChange(id,first=0) {
      arcTypeTh({
        id,
      }).then((res) => {
        this.cateOption = res.data;
        this.oneId = id;
		if(first==0){
			this.radio = '';
			this.checkList = [];
		}
        this.twoThird.forEach((a) => {
          this.cateOption.forEach((b,index) => {
            b.third.forEach((c) => {
				if(index==1){
					if (a.son.id == c.id) {
						this.radio = c.id;
					}
				}else{
					if (a.son.id == c.id) {
						this.checkList.push(c.id);
					}
				}
            });
          });
        });
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      console.log(file);
      this.litpic = res.data.img;
      console.log(this.litpic);
    },
    handleAvatarSuccess1(res, file) {
      console.log(res);
      console.log(file);
      this.sourceLitpic = res.data.img;
      console.log(this.sourceLitpic);
    },
    getData() {
      userArticDetail({
        aid: this.$route.query.aid,
      }).then((res) => {
        console.log(res.data);
        this.title = res.data.title;
        this.litpic = res.data.litpic;
        this.author = res.data.author;
        this.users_price = res.data.users_price;
        this.arcrank = res.data.arcrank;
        this.disklink = res.data.disklink;
        this.disklinkpwd = res.data.disklinkpwd;
        this.size = res.data.size;
        this.format = res.data.format;
        this.seo_keywords = res.data.seo_keywords;
        this.pattern = res.data.pattern;
        this.sourceLitpic = res.data.raw_litpic;
      });
    },
    return1() {
      this.$router.go(0);
    },
    //获取分区
    getUserArticDetailType() {
      userArticDetailType({
        aid: this.$route.query.aid,
      }).then((res) => {
        this.one = res.data.one.typename;
        this.oneId = res.data.one.id;
        this.twoThird = res.data.twoThird;
      });
    },
    //修改作品
    alter() {
      this.alterShow = true;
      this.disabledShow = false;
      this.onceActive = this.one;
      this.onceChange(this.oneId,1);
      // this.cateOption = this.twoThird;
    },
    //确认修改
    submit() {
      if (this.litpic == "" || this.litpic == null) {
        this.$message.error("请上传缩略图");
        return;
      }

      if (
        (this.sourceLitpic == "" || this.sourceLitpic == null) &&
        (this.disklink == "" || this.disklink == null)
      ) {
        this.$message.error("请上传源文件或请输入网盘链接");
        return;
      }

      // if (this.disklink == "" || this.disklink == null) {
      // 	this.$message.error("请输入网盘链接");
      // 	return;
      // }

      //   if (
      //     (this.disklinkpwd == "" || this.disklinkpwd == null) &&
      //     this.disklink != ""
      //   ) {
      //     this.$message.error("请输入网盘密码");
      //     return;
      //   }
      if (this.title == "" || this.title == null) {
        this.$message.error("请输入作品名称");
        return;
      }

      if (this.users_price == "" || this.users_price == null) {
        this.$message.error("请输入价格");
        return;
      }

      if (this.format == "" || this.format == null) {
        this.$message.error("请输入选择格式");
        return;
      }

      if (this.onceActive == "" || this.onceActive == null) {
        this.$message.error("请选择上传板块");
        return;
      }

      if (this.seo_keywords == "" || this.seo_keywords == null) {
        this.$message.error("请输入关键词");
        return;
      }

      if (this.radio.length == 0||this.radio == null) {
        this.$message.error("请选择适用产品");
        return;
      }
      if (this.checkList.length==0||this.checkList==null) {
        this.$message.error("请选择图案元素");
        return;
      }
      this.onceActive = this.oneId;
      var typeid =
        this.onceActive.toString() +
        "," +
        this.radio.toString() +
        "," +
        this.checkList.toString();
      let params = {
        aid: this.$route.query.aid,
        litpic: this.litpic,
        title: this.title,
        users_price: this.users_price,
        pattern: this.pattern,
        disklink: this.disklink,
        disklinkpwd: this.disklinkpwd,
        size: this.size,
        format: this.format,
        seo_keywords: this.seo_keywords,
        typeid: typeid,
        raw_litpic: this.sourceLitpic,
      };
      console.log(params);
      uploading(params).then((res) => {
        console.log(res);

        if (res.code == 200) {
          this.alterShow = false;
          this.disabledShow = true;
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$router.push("/seller/manage");
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 54px 26px 54px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .content {
  }
}

.keyWord {
  width: 920px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 19px;
  padding-bottom: 37px;

  .title {
    font-size: 18px;
    font-weight: 400;
    color: #08243c;
    line-height: 18px;
    padding-left: 23px;
    padding-top: 33px;
  }

  .keyWord-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 54px;
  }
}

.uploadDo {
  width: 186px;
  height: 50px;
  background: #08243c;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin-top: 28px;
  cursor: pointer;
}

.titles {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  text-align: center;
}

/deep/.el-input__inner,
/deep/.el-textarea__inner {
  background: rgba(8, 36, 60, 0.06);
}

/deep/.el-input {
  margin-top: 22px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload {
  width: 237px;
  height: 154px;
  background: rgba(8, 36, 60, 0.06);
  // opacity: 0.06;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .upload-text {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
  }
}

.cate-active {
  width: 130px;
  height: 50px;
  border: 1px solid #aab7c2;
  border-radius: 4px;
  text-align: center;
}

/deep/.avatar[data-v-bc227386] {
  width: 237px;
  height: 154px;
  display: block;
}

.uploadDo1 {
  width: 186px;
  height: 50px;
  background: #e66617;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin-top: 28px;
  cursor: pointer;
}

.title1 {
  font-size: 16px;
  font-weight: 400;
  color: #08243c;
  line-height: 18px;
  padding-top: 33px;
}
</style>
