<template>
	<div>
		<top />
		<div class="container">
			<div class="content">
				<div class="left">
					<div class="top">
						<div class="user-info">
							<div class="block">
								<el-avatar :size="82" :src="imageUrl(head_pic)" v-if="head_pic"></el-avatar>
							</div>
							<div class="uname">{{ nickname }}</div>
							<div class="balance">
								<span>余额</span>
								<span style="padding-left: 11px">￥{{ users_money }}</span>
							</div>
							<div class="consum-info">
								<div>
									<span>消费金额</span>
									<span style="padding-left: 11px">￥{{ ConAmount }}</span>
								</div>
								<div>
									<span>充值金额</span><span style="padding-left: 11px">￥{{ moneyOrder }}</span>
								</div>
							</div>
							<div class="recharge" @click="go('/buyer/recharge')">充值</div>
						</div>
					</div>
					<div class="bottom">
						<div :class="url == '/buyer/buyOrder' ? 'item item-active' : 'item'"
							@click="go('/buyer/buyOrder')"> 我的订单 </div>
						<div :class="url == '/buyer/buycar' ? 'item item-active' : 'item'" @click="go('/buyer/buycar')">
							购物车 </div>
						<div :class="url == '/buyer/myFollow' ? 'item item-active' : 'item'"
							@click="go('/buyer/myFollow')"> 我的关注 </div>
						<div :class="url == '/buyer/vip' ? 'item item-active' : 'item'" @click="go('/buyer/vip')">
							VIP会员计划 </div>
						<div :class="url == '/buyer/data' ? 'item item-active' : 'item'" @click="go('/buyer/data')">
							数据中心 </div>
						<div :class="url == '/buyer/promote' ? 'item item-active' : 'item'"
							@click="go('/buyer/promote')"> 我的推广 </div>
						<div :class="url == '/buyer/talk' ? 'item item-active' : 'item'" @click="go('/buyer/talk')">
							我的资讯 </div>
						<div :class="url == '/seller/order' ? 'item item-active' : 'item'"
							@click="go('/seller/center')"> 卖家中心 </div>
					</div>
				</div>
				<div class="right">
					<router-view></router-view>
				</div>
			</div>
		</div>
		<foot />
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import {
		memberRightData
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
		},
		data() {
			return {
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				ConAmount: "", //消费金额
				moneyOrder: "", //充值金额
				nickname: "", //名称
				head_pic: "", //头像
				users_money: "", //余额
				url: "/buyer/buyOrder",
			};
		},
		created() {
			this.getData();
		},
		watch: {
			$route(to, from) {
				this.url = this.$route.path;
			},
		},
		methods: {
			getData() {
				memberRightData({
					type: 1,
				}).then((res) => {
					this.nickname = res.data.userData.nickname;
					this.head_pic = res.data.userData.head_pic;
					this.users_money = res.data.userData.users_money;
					this.moneyOrder = res.data.moneyOrder;
					this.ConAmount = res.data.ConAmount;
				});
			},
			go(url) {
				this.$router.push(url);
			},
		},
	};
</script>
<style lang="less" scoped>
	.container {
		width: 100%;
		min-width: 1280px;
		overflow: hidden;
		margin-bottom: 89px;

		.content {
			width: 1280px;
			margin: 0 auto;
			margin-top: 43px;
			display: flex;
			justify-content: space-between;

			.left {
				.top {
					.user-info {
						width: 340px;
						padding-top: 19px;
						padding-bottom: 30px;
						background: #ffffff;
						border-radius: 6px;
						display: flex;
						flex-direction: column;
						align-items: center;

						.uname {
							font-size: 20px;
							font-weight: 400;
							color: #000000;
							line-height: 20px;
							padding-top: 11px;
						}

						.balance {
							font-size: 18px;
							font-weight: 400;
							color: #08243c;
							line-height: 18px;
							margin-top: 19px;
						}

						.consum-info {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-top: 33px;
						}

						.consum-info div:first-child::after {
							content: "";
							width: 1px;
							height: 15px;
							background: #80909e;
							opacity: 0.6;
							display: inline-block;
							margin: 0 26px;
						}

						.recharge {
							width: 292px;
							height: 44px;
							background: #08243c;
							border-radius: 4px;
							margin-top: 31px;
							font-size: 18px;
							font-weight: 400;
							color: #ffffff;
							line-height: 44px;
							text-align: center;
							cursor: pointer;
						}
					}
				}

				.bottom {
					width: 340px;
					background: #ffffff;
					border-radius: 6px;
					margin-top: 14px;

					.item {
						border-bottom: 1px solid #f1f4f6;
						padding: 31px 0;
						font-size: 18px;
						font-weight: 400;
						color: #000000;
						line-height: 18px;
						display: flex;
						align-items: center;
						padding-left: 25px;
						position: relative;
						cursor: pointer;
					}

					.item-active::before {
						content: "";
						width: 4px;
						height: 26px;
						background: #e66617;
						display: inline-block;
						position: absolute;
						left: 0;
					}
				}
			}

			.right {
				width: 920px;
			}
		}
	}
</style>