/**   
 * api接口统一管理
 */
import {
	get,
	post
} from './http'
//首页数据
export const IndexData = data => post('newapi/HomePage/index', data)
//顶部分类
export const topCate = data => post('/newapi/HomePage/topCate', data)
//底部公司信息
export const footData = data => post('/newapi/HomePage/webConfig', data)
//协议
export const clauseData = data => post('/newapi/HomePage/getAgreement', data)
//公开区 原创区 素材区
export const openAreaData = data => post('/newapi/SourceMaterial/index', data)
//公开区 原创区 素材区 详情
export const openAreaDetail = data => post('/newapi/SourceMaterial/sourceDetail', data)
//关注
export const fllowDo = data => post('/newapi/BuyOrder/follow', data)
//试衣间热门设计师
export const fittingRoomUnder = data => post('/newapi/SourceMaterial/fittingRoomUnder', data)
//试衣间左侧
export const fittingRoomClothes = data => post('/newapi/SourceMaterial/fittingRoomClothes', data)
//试衣间右侧分类
export const fittingRoomType = data => post('/newapi/SourceMaterial/fittingRoomType', data)
//推出登录
export const loginOut = data => post('/newapi/Login/logout', data)
//作者详情页面
export const authorDetail = data => post('/newapi/SourceMaterial/authorDetail', data)
//获取用户最低充值金额
export const balanceBefore = data => post('/newapi/BuyOrder/balanceBefore', data)
//素材详情
export const sourceDetail = data => post('/newapi/SourceMaterial/sourceDetail', data)
//文件上传
export const upload = data => post('/newapi/Archives/articleImgUpload', data)
//获取手机验证码
export const sendCode = data => post('/newapi/Login/sendSms', data)
//登录
export const login = data => post('/newapi/Login/index', data)
//是否认证
export const realNameAuth = data => post('/newapi/member/realNameAuth', data)
//用户实名认证
export const realName = data => post('/newapi/member/realName', data)
//上传作品 获取一级分类
export const cate = data => post('/newapi/Archives/beforeUploadwork', data)
//上传作品 通过一级获取 二级 三级
export const arcTypeTh = data => post('/newapi/Archives/getArcTypeTh', data)
//上传作品
export const uploading = data => post('/newapi/Archives/articleAdd', data)
//用户余额充值
export const balance = data => post('/newapi/BuyOrder/balance', data)
//微信支付二维码
export const pay_wechat_png = data => post('/newapi/BuyOrder/pay_wechat_png', data)
//订单支付轮询
export const order_pay_polling = data => post('/newapi/BuyOrder/order_pay_polling', data)
//支付接口列表
export const select_pay_method = data => post('/newapi/BuyOrder/select_pay_method', data)
//加入 删除 购物车
export const joinCart = data => post('/newapi/BuyOrder/joinCart', data)
//购物车页数据
export const userCart = data => post('/newapi/Member/userCart', data)
//用户 卖家 信息
export const memberRightData = data => post('/newapi/Member/memberRightData', data)
//买家数据
export const memberTopData = data => post('/newapi/Member/memberTopData', data)
//用户我的关注
export const myFollowList = data => post('/newapi/Member/myFollowList', data)
//用户vip 计划
export const userVipInfo = data => post('/newapi/BuyOrder/userVipInfo2', data)
//用户购买会员
export const rechargeCard = data => post('/newapi/BuyOrder/rechargeCard', data)
//
export const sourceDetailorder = data => post('/newapi/BuyOrder/sourceDetailorder', data)
//获取 是否购买
export const checkUserOrder = data => post('/newapi/BuyOrder/checkUserOrder', data)
//获取 是否购买
export const checkUserOrders = data => post('/newapi/BuyOrder/checkUserOrders', data)
//我的订单
export const myOrder = data => post('/newapi/Member/myOrder', data)
//购物车结算
export const cartSourceDetailorder = data => post('/newapi/BuyOrder/cartSourceDetailorder', data)
//订单确认收货
export const confirmOrder = data => post('/newapi/BuyOrder/confirmOrder', data)
//数据中心
export const dataCenter = data => post('/newapi/Member/userCenter', data)
//用户作品
export const userArticleList = data => post('/newapi/Archives/userArticleList', data)
// 销售订单
export const sellOrder = data => post('/newapi/Member/sellOrder', data)
//获取用户账户信息
export const collectionInfor = data => post('/newapi/Member/collectionInfor', data)
//修改用户账户信息
export const addCollectionInfor = data => post('/newapi/Member/addCollectionInfor', data)
//用户基本信息展示
export const usersInfo = data => post('/newapi/Member/usersInfo', data)
//用户基本信息修改
export const editUserInfo = data => post('/newapi/Member/editUserInfo', data)
//用户实名认证回填
export const realNameInfo = data => post('/newapi/Member/realNameInfo', data)
//搜页搜索
export const search = data => post('/newapi/HomePage/search', data)
//获取登录url
export const getWx = data => post('/newapi/Login/returnAppid', data)
//微信 登录
export const wxCodeLogin = data => post('/newapi/Login/wxCodeLogin', data)
//提现页面
export const withdrawal = data => post('/newapi/Member/withdrawal', data)
//体现操作
export const commitWithdrawal = data => post('/newapi/Member/commitWithdrawal', data)
//体现明细
export const withdrawalList = data => post('/newapi/Member/withdrawalList', data)
//用户查看作品详情
export const userArticDetail = data => post('/newapi/Archives/userArticDetail', data)
//用户查看作品分类详情
export const userArticDetailType = data => post('/newapi/Archives/userArticDetailType', data)
//绑定微信
export const bangWx = data => post('/newapi/Member/returnAppid', data)
export const bindingWx = data => post('/newapi/Member/bindingWx', data)
export const bindingPhone = data => post('/newapi/Member/bindingPhone', data)
export const bindingPhoneOp = data => post('/newapi/Member/bindingPhoneOp', data)
//用户充值明细
export const rechargeDetails = data => post('/newapi/Member/rechargeDetails', data)
//首页-新锐设计师
export const designer = data => post('/newapi/HomePage/designer', data)
//我的推广 - 我的推广
export const extensionMyPromotion = data => post('/newapi/Member/extensionMyPromotion', data)
//我的推广 - 我的下线
export const extensionMyReferrals = data => post('/newapi/Member/extensionMyReferrals', data)
//我的推广 - 我的收益
export const extensionMyEarnings = data => post('/newapi/Member/extensionMyEarnings', data)
// 检测原创作品是否可以访问
export const checkOriginalAuth = data => post('/newapi/SourceMaterial/checkOriginalAuth', data)
//站内消息
export const Messagelist = data => post('/newapi/Messagelist/messagelist', data)
//详情
export const MessagelistDetlle = data => post('/newapi/Messagelist/readnews', data)
//首页广告
export const webConfigAd = data => post('/newapi/HomePage/webConfigAd', data)
//全部热门精品
export const Popularboutiques = data => post('/newapi/Alldesign/Popularboutiques', data)
//多文件上传
export const articleImgsUploades = data => post('/newapi/Archives/articleImgsUploades', data) //多文件上传
//单文件上传
export const searchImgUploadBase64 = data => post('/newapi/HomePage/searchImgUploadBase64', data)
export const askindex = data => post('/newapi/ask/index', data)
export const askdetail = data => get('/newapi/ask/detail', data)
export const askapply_list = data => get('/newapi/ask/apply_list', data)
export const askapply_prize = data => get('/newapi/ask/apply_prize', data)
export const askapply_add = data => post('/newapi/ask/apply_add', data)
export const askadd = data => post('/newapi/ask/add', data)
export const askmylist = data => get('/newapi/ask/mylist', data)
export const askmymyapply = data => get('/newapi/ask/myapply', data)
export const askdel = data => get('/newapi/ask/del', data)
export const askmydelapply = data => get('/newapi/ask/delapply', data)
export const upcolor = data => post('/newapi/SourceMaterial/upcolor', data)