<template>
	<div>
		<div class="bottom">
			<div class="title">我的推广</div>



			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
				text-color="#08243C">
				<el-menu-item index="1">下线推广</el-menu-item>
				<el-menu-item index="2">我的下线</el-menu-item>
				<el-menu-item index="3">我的收益</el-menu-item>
			</el-menu>

			<div class="content" style="margin-top: 39px">
				<div v-show="activeIndex == '1'">
					<div
						style="font-size: 14px;padding: 0 24px 31px 24px;border-bottom: 1px rgba(8, 36, 60, 0.2) solid;">
						<div>1.{{ content[0] }}</div>
						<div style="margin-top: 27px;">2.{{ content[1] }}</div>
					</div>
					<div style="padding: 0 24px;">
						<div style="color: rgba(8, 36, 60, 1);font-size: 16px;margin-top: 31px;">您的推广链接</div>
						<div
							style="background-color: RGBA(240, 242, 244, 1);padding: 18px 26px;display: flex;justify-content: space-between;align-items: center;margin-top: 27px;width: 70%;">
							<div style="color: rgba(136, 136, 136, 1);">
								{{ message }}
							</div>
							<div style="color: rgba(230, 102, 23, 1);cursor: pointer;"  v-clipboard:copy="message" v-clipboard:success="onCopy">复制</div>
						</div>
						<div style="margin-top: 29px;">
							<div style="border: 1rpx red solid;" id="qrcode" ref="qrcode"></div>
							<!-- <img src="../../assets/erweima.png" style="width: 180px;height: 180px;"> -->
						</div>
					</div>

				</div>



				<el-table :data="tableData" style="width: 100%" v-show="activeIndex == '2'">
					<el-table-column prop="users_id" label="ID" align="center">
					</el-table-column>
					<el-table-column prop="nickname" label="昵称" align="center">
					</el-table-column>

					<el-table-column prop="archives_count" label="作品数量" align="center">
					</el-table-column>

					<el-table-column prop="reg_time" label="注册时间" align="center">
					</el-table-column>
				</el-table>

				<el-table :data="tableData1" style="width: 100%" v-show="activeIndex == '3'">
					<el-table-column prop="memo" label="奖励缘由" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="奖励时间" align="center">
					</el-table-column>

					<el-table-column prop="money" label="推广收益" align="center">
					</el-table-column>

				</el-table>
			</div>
			<div class="page-box" v-show="activeIndex == '2'">
				<div class="pages">
					<el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size"
						layout="total, prev, pager, next ,jumper" :total="count">
					</el-pagination>
				</div>
			</div>
			<div class="page-box" v-show="activeIndex == '3'">
				<div class="pages">
					<el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size"
						layout="total, prev, pager, next ,jumper" :total="count1">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import {
		userArticleList,
		topCate,
		extensionMyPromotion,
		extensionMyReferrals,
		extensionMyEarnings
	} from "../../request/api";
	export default {
		data() {
			return {
				activeIndex: "1", //menu 默认显示
				tableData: [],
				tableData1: [],
				keyWords: "",
				arctype: "",
				choose: "",
				start_time: "",
				end_time: "",
				count: 1,
				count1: 1,
				size: 10,
				page: 1,
				cate: '',
				content: '',
				invitation_code: '',
				localhostPath: '',
				message: ''
			};
		},

		created() {
			let wPath = window.document.location.href;
			let pathName = this.$route.path;
			let pos = wPath.indexOf(pathName);
			this.localhostPath = wPath.substring(0, pos);
			this.getCata();
			this.getMyPromotion();
			this.getMyReferrals();
			this.getMyEarnings();
		},

		methods: {
			onCopy() {
				this.$message({
					message: `复制成功！`,
					type: 'success'
				});
			},
			qrcode() {
				let aaa = "/login?invitation_code=" + this.invitation_code
				let qrcode = new QRCode("qrcode", {

					width: 200, // 二维码宽度，单位像素

					height: 200, // 二维码高度，单位像素

					text: this.localhostPath + aaa // 生成二维码的链接

				});

			},
			//获取顶部分区
			getCata() {
				topCate().then((res) => {
					// console.log(res)
					this.cate = res.data;
				});
			},
			//我的推广 - 我的推广
			getMyPromotion() {
				extensionMyPromotion().then((res) => {
					this.content = res.data.content
					this.invitation_code = res.data.invitation_code
					this.qrcode();
					this.message = this.localhostPath + "/login?invitation_code=" + this.invitation_code
				});
			},
			//我的推广 - 我的下线
			getMyReferrals() {
				extensionMyReferrals({
					page: this.page
				}).then((res) => {
					console.log(res)
					this.tableData = res.data.data
					this.count = res.data.total
				});
			},
			// 我的推广 - 我的收益
			getMyEarnings() {
				extensionMyEarnings({
					page: this.page
				}).then((res) => {
					this.tableData1 = res.data.data
					this.count1 = res.data.total
				});
			},
			go(aid) {
				this.$router.push({
					path: "/seller/manageDetail",
					query: {
						aid: aid,
					},
				});
			},

			goUpdata(aid) {
				this.$router.push({
					path: "/seller/manageUpdata",
					query: {
						aid: aid,
					},
				});

			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.getMyReferrals();
				this.getMyEarnings()
			},

			handleSelect(key, keyPath) {
				// console.log(key, keyPath);
				// alert(key);
				this.page = 1;
				this.activeIndex = key;
				this.getMyReferrals();
				this.getMyEarnings()
				// console.log(this.activeIndex)
			},

		},
	};
</script>

<style lang="less" scoped>
	.bottom {
		background: #ffffff;
		border-radius: 6px;

		padding: 23px 19px 23px 19px;

		.title {
			font-size: 20px;
			font-weight: 400;
			color: #08243c;
			line-height: 36px;
			padding-left: 4px;
		}

		.title::after {
			content: "";
			width: 83px;
			height: 9px;
			background: #e66617;
			display: block;
			margin-top: -15px;
		}

	}

	/deep/.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 2px solid #08243c;
	}

	.page-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 20px;
	}

	/deep/.el-table th {
		background: rgba(8, 36, 60, 0.04);
		font-size: 14px;
		font-weight: 400;
		color: #000000;
		line-height: 36px;
	}

	/deep/.el-table td {
		padding: 25px 0;
	}

	/deep/.el-pager li {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		line-height: 32px;
	}

	/deep/.el-pager li.active {
		width: 32px;
		height: 32px;
		background: #08243c;
		border-radius: 50%;
	}

	/deep/.el-pager li.active {
		color: #ffffff;
	}

	/deep/.el-dialog,
	/deep/.el-pager li {
		background: none;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		background: none;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 16px;
		line-height: 32px;
	}
</style>
