<template>
  <div>
    <div class="top">
      <div class="top-item">
        <div class="num">{{ ConAmount }}</div>
        <div class="text">消费金额</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{ buyNum }}</div>
        <div class="text">购买次数</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{ moneyOrder }}</div>
        <div class="text">充值金额</div>
      </div>
    </div>

    <div class="bottom">
      <div class="title">账户充值</div>
      <div class="notic">账户信息：充值金额不允许提现，仅允许进行消费使用!</div>
      <div class="price-box">
        <div class="price">
          <div class="limit">充值金额</div>
          <div
            :class="quicklyActive == 1 ? ' num num-active' : ' num '"
            @click="quick('100', 1)"
            style="margin-left: 30px"
          >
            100
          </div>
          <div
            :class="quicklyActive == 2 ? ' num num-active' : ' num '"
            @click="quick('500', 2)"
          >
            500
          </div>
          <div
            :class="quicklyActive == 3 ? ' num num-active' : ' num '"
            @click="quick('1000', 3)"
          >
            1000
          </div>
          <el-input
            v-model="money"
            placeholder="自定义金额"
            style="width: 139px; margin-left: 16px; height: 42px"
          ></el-input>
          <span style="padding-left: 14px">元</span>
        </div>
        <div class="limit">最低充值{{ lastMoney }}元</div>
      </div>

      <div class="methods">
        <div>充值方式</div>
        
        <div style="margin-left: 46px; cursor: pointer" @click="choose()">
          <img src="@/assets/wx1.png" alt="" v-if="zfMe" />
          <img src="@/assets/wx2.png" alt="" v-else />
        </div>
      </div>

      <div class="ok-box">
        <div class="ok" @click="operation">确认充值</div>
        <div class="detail" @click="go('/buyer/rechargeDetails')">充值明细</div>
      </div>

      <el-divider></el-divider>
      <div class="note" v-html="value">
       
      </div>
    </div>

    <!-- 充值弹框 -->

    <el-dialog
      title="打开微信扫一扫，向我付款"
      :visible.sync="bounced"
      width="20%"
      :close-on-click-modal="isClose"
      center
    >
      <vue-qr
        style="margin: 0 auto"
        :text="downloadData.url"
        :margin="0"
        colorDark="black"
        colorLight="#fff"
        :size="200"
      ></vue-qr>
    </el-dialog>
  </div>
</template>

<script>
import {
  balanceBefore,
  balance,
  pay_wechat_png,
  order_pay_polling,
  select_pay_method,
  memberTopData,
} from "../../request/api";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
		ConAmount: "", //消费金额
		buyNum: "", //购买数量
		
		moneyOrder: "", //充值金额
      quicklyActive: "",
      isClose: false,
      money: "", //充值金额
      zfMe: false,
      value: "", //下方提示信息
      lastMoney: "", //最低充值金额
      bounced: false, //充值弹框
      orderInfo: "",
      downloadData: {
        url: "扫码访问的链接地址",
        icon: "随便一张图片的地址也行",
      },
    };
  },

  created() {
	this.getTopData();
    this.getBalanceBefore();
  },

  methods: {
	  getTopData() {
	  	memberTopData({
	  		type: 1,
	  	}).then((res) => {
	  		this.ConAmount = res.data.ConAmount;
	  		this.buyNum = res.data.buyNum;
	  		this.moneyOrder = res.data.moneyOrder;
	  	})
	  },
    //获取最低充值金额
    getBalanceBefore() {
      balanceBefore({}).then((res) => {
        this.value = this.showHtml(res.data.rechargeprotocol.value);
        this.lastMoney = res.data.config.value;
      });
    },

    //快速选择
    quick(money, num) {
      this.money = money;
      this.quicklyActive = num;
    },

    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },

    //确认充值
    operation() {
      if (this.money == "" || this.money == null) {
        this.$message.error("请输入金额");
        return;
      }

      if (!this.zfMe) {
        this.$message.error("请选择支付方式");
        return;
      }

      balance({
        money: this.money,
        payment_type: "zxzf_wechat",
      }).then((res) => {
      
        if (res.code == 200) {
          this.orderInfo = res.data;

          pay_wechat_png({
            unified_number: this.orderInfo.unified_number,
            transaction_type: this.orderInfo.transaction_type,
          }).then((res) => {
            if (res.code == 200) {
              this.downloadData.url = res.data;
              this.bounced = true;
              // this.order_pay();
              this.getSelect_pay_method();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //支付接口列表
    getSelect_pay_method() {
      select_pay_method({
        unified_id: this.orderInfo.unified_id,
        unified_number: this.orderInfo.unified_number,
        transaction_type: 1,
        pay_mark: "wechat",
        pay_id: 1,
      }).then((res) => {
        if (res.code == 200) {
          this.order_pay();
        } else {
          this.$message.error(res.msg);
          this.bounced = false;
        }
      });
    },

    // //订单支付轮询
    order_pay() {
      var time = setInterval(() => {
        order_pay_polling({
          unified_id: this.orderInfo.unified_id,
          unified_number: this.orderInfo.unified_number,
          transaction_type: 1,
          pay_mark: "wechat",
          pay_id: 1,
        }).then((res) => {
          console.log("222", res);
          if (res.code == 200) {
            this.$message({
              message: "支付成功",
              type: "success",
            });

            clearInterval(time)
            this.$router.push("/buyer/buyOrder");
          }
        });
      }, 2000);
    },

    //选择支付方式
    choose() {
      this.zfMe = !this.zfMe;
    },

    go(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 35px 0px 38px 0px;

  .top-item {
    display: flex;

    align-items: center;
    flex-direction: column;

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #08243c;
      line-height: 24px;
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      padding-top: 20px;
    }
  }

  .shu {
    width: 1px;
    height: 37px;
    background: #d7dadd;
  }
}

.bottom {
  background: #ffffff;
  border-radius: 6px;
  margin-top: 14px;
  padding: 34px 23px 35px 23px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .notic {
    font-size: 14px;
    font-weight: 400;
    color: #c92323;
    line-height: 14px;
    padding-top: 30px;
  }

  .price-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 39px;
    .price {
      display: flex;
      align-items: center;

      .num {
        width: 100px;
        height: 40px;
        border: 1px solid #cdd6de;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 400;
        color: #08243c;
        line-height: 40px;
        text-align: center;
        margin-left: 16px;
        cursor: pointer;
      }

      .num-active {
        background: #f6f8f9;
      }
    }

    .limit {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
    }
  }

  .methods {
    display: flex;
    align-items: center;
    margin-top: 44px;
  }

  .ok-box {
    display: flex;
    align-items: center;
    padding-left: 94px;
    margin-top: 35px;
    .ok {
      width: 100px;
      height: 36px;
      background: #08243c;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      cursor: pointer;
    }

    .detail {
      width: 100px;
      height: 36px;
      background: #e66617;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      margin-left: 63px;
      cursor: pointer;
    }
  }

  .note {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 36px;

    .shuoming {
      margin-top: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
    }
  }
}

.ewm-box {
  width: 251px;
  height: 251px;
  background: red;
  margin: 0 auto;
  padding: 13px;
}

/deep/.el-divider--horizontal {
  margin-top: 31px;
  margin-bottom: 36px;
}

/deep/.el-dialog__body {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>