<template>
  <div class="container">
    <div class="content">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="go('/openAreaDetail', item.aid,item.sign)"
      >
        <div class="poa">
          <img
            v-if="item.sales_num >= hot_limit"
            src="../../assets/bao.png"
            width="32px"
            height="38px"
            alt=""
          />
        </div>
        <img :src="imageUrl(item.litpic)" alt="" :id="'img'+item.aid" v-if="show_model=='1'"/>
		<div class="yiwrap" v-if="show_model=='2'">
			<img class="closeimg" :src="imageUrl(item.clothingTem)" alt="" v-if="item.clothingTem">
			<div v-if="item.repeat == 1" :id="'img'+item.aid" class="logo" :style="{
			  'z-index':'1',
			  position: 'absolute', 
			  width: '100%',
			  height: '100%',
			  backgroundImage: 'url(' + imageUrl(item.imgs) + ')',
			  backgroundSize: '55px',
			}"></div>
			<img v-if="item.repeat != 1" :id="'img'+item.aid" class="logo1" :src="imageUrl(item.imgs)" alt=""/>
			<div class="bj" :style="{ backgroundColor: item.color }"></div>
		</div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkOriginalAuth } from "../../request/api";
export default {
  props: {
    list: [],
    hot_limit: "",
    hot_max: "",
	show_model:{
		default:"1"
	}
  },
  mounted() {
	var that = this;
	for(var i=0;i<this.list.length;i++){
	    this.list[i].imgs = this.list[i].imgs.replace(/\s/g,'%20');
	}
  },
  methods: {
    go(url, aid,sign) {
      this.checkOriginalAuth(url, aid,sign);
      // this.$router.push({
      //   path:url,
      //   query:{
      //     aid:aid
      //   }
      // });
    },
    checkOriginalAuth(url, aid,sign) {
      // console.log(aid);
      checkOriginalAuth({
        aid: aid,
		sign: sign
      }).then((res) => {
        console.log(res);
        if (res.code == 500) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          return;
        }
        if (res.code == 200) {
          let routeData = this.$router.resolve({
            path: url,
            query: {
              aid: aid,
            },
          });
          window.open(routeData.href, "_blank");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  min-width: 1280px;
  overflow: hidden;
  .content {
    width: 1280px;
    margin: 0 auto;

    flex-wrap: wrap;
    .item {
      width: 235px;
      height: 234px;
      border-radius: 4px;
      display: inline-flex;
      margin-left: 26px;
      margin-top: 26px;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
		object-fit: contain;
        display: block;
      }
    }

    .item:nth-child(5n-4) {
      margin-left: 0;
    }
  }
}
.poa {
  position: absolute;
  left: 8px;
  top: 6px;
  z-index: 3;
}
.el-progress-bar__inner {
  background-image: url();
}
.container .content .item img.closeimg{
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.container .content .item img.logo1{
	position: absolute; 
	width: 54px;
	height: 54px;
	top: 84px;
	left: 84px;
	z-index: 1;
}
.bj{
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 0;
}
</style>