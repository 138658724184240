<template>
	<div class="page">
		<div class="header-box">
			<top />
			<div class="block">
				<div class="left">
					<div class="li" v-for="item in list" @click="gourl(item.ask_id)">
						<div class="title">
							{{item.ask_title}}
						</div>
						<div class="info">
							<div class="img" v-if="item.thumb">
								<img class="img" :src="imageUrl(item.thumb)" alt="" />
							</div>
							<div class="cont">
								<div class="p">
									{{item.content}}
								</div>
								<div class="user_info">
									<div class="user_avatar">
										<img :src="imageUrl(item.head_pic)" alt="" />
									</div>
									<span class="user_name">{{item.nickname}}</span>
									<span class="read_number">阅读 {{item.click}}</span>
									<span class="reply_number">回复 {{item.replies}}</span>
									<span class="time">发布时间 {{item.add_time}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="btn-s">
						<div class="btn" @click="release"> <img src="../../static/edit.png" alt="" />发布资讯 </div>
						<div class="tips">发布资讯来分享、交流、提问</div>
					</div>
				</div>
			</div>
			<div class="page-box">
				<div class="pages">
					<el-pagination @current-change="handleCurrentChange" :page-size="size"
						layout="total, prev, pager, next ,jumper" :total="count" :current-page="page">
					</el-pagination>
				</div>
			</div>
		</div>
		<foot />
		<el-dialog :visible.sync="adShow" show-close width="25%" center>
			<a :href="`http://` + ahref" @click="adShow = false">
				<img style="width: 100%" :src="adimg" alt="" />
			</a>
		</el-dialog>
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer";
	import {
		IndexData,
		askindex,
		webConfigAd,
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
		},
		created() {
			this.getIndexData();
			this.getaskindex()
			this.head_pic = localStorage.getItem("head_pic");
			webConfigAd().then((res) => {
				if (res.data.ad_mode.value == "2") {
					// this.adShow = true;
					this.adimg = res.data.ad_content.value;
					this.ahref = res.data.ad_url.value;
					this.$forceUpdate();
				}
			});
		},
		data() {
			return {
				topCate: "", //顶部分类
				head_pic: "", //用户头像
				keysWord: "",
				search: "", //搜索
				topShow: false, //header文件上传
				adimg: "",
				adShow: false,
				ahref: "",
				count: 1,
				size: 20,
				page: 1,
				list: ''
			};
		},
		methods: {
			//获取首页数据
			getIndexData() {
				IndexData({}).then((res) => {
					console.log(res);
					this.topCate = res.data.topCate;
				});
			},
			getaskindex() {
				askindex({
					page: this.page
				}).then((res) => {
					console.log(res);
					this.list = res.data.list;
					this.count = res.data.count
				});
			},
			//公开区跳转
			goOpenArea(id) {
				console.log(id);
				this.$router.push({
					path: `/openArea/${id}`,
				});
			},
			//详情页跳转
			gourl(id) {
				console.log(id);
				this.$router.push({
					path: `/RecruitDetail`,
					query: {
						id: id,
					},
				});
			},
			release(id) {
				var userInfo = localStorage.getItem("head_pic");
				if(!userInfo){
					this.$message({
						message: '请登录账号发布',
						type: "error",
					});
					return;
				}
				this.$router.push({
					path: `/RecruitRelease`,
				});
			},
			searchDo1() {
				this.$router.push({
					path: "/search",
					query: {
						keysWord: this.search,
						imgs: 0,
					},
				});
			},
			loginOut() {
				loginOut({}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.go(0);
					}
				});
			},
			go(url) {
				this.$router.push(url);
			},
			handleRemove() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;
					this.$router.push({
						url: "",
					});
				}
				this.$router.push({
					path: "/search",
					query: {
						keysWord: "",
						imgs: 1,
					},
				});
			},
			handleCurrentChange(val) {
				console.log(val);
				this.page = val;
				this.getaskindex()
			},
		},
	};
</script>
<style lang="less" scoped>
	.page {
		background: #f8f8f8;

		.header-box {
			width: 100%;
			min-width: 1280px;
			overflow: hidden;
			position: relative;

			.header {
				width: 1480px;
				height: 72px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				z-index: 100;
				left: 50%;
				transform: translate(-50%);

				.header-left {
					width: 700px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 16px;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					cursor: pointer;

					.active {
						font-weight: bold;
					}

					.gongkai::after {
						content: "";
						width: 1px;
						height: 14px;
						background: #efefef;
						display: inline-block;
						margin: 0 9px;
					}
				}

				.header-right {
					display: flex;
					align-items: center;
					position: relative;

					.search-box {
						display: flex;
						align-items: center;
						//   width: 494px;
						height: 36px;
						background: #ffffff;
						border-radius: 6px 0px 0px 6px;
						padding-right: 19px;

						.tu-search-box {
							display: flex;
							align-items: center;

							.tu-search {
								font-size: 14px;
								font-weight: 400;
								color: #333333;
								line-height: 36px;
								padding: 0 18px;
							}
						}

						.tu-search-box::after {
							content: "";
							width: 1px;
							height: 20px;
							background: #cccccc;
							display: inline-block;
						}

						.search {
							input {
								background: none;
								outline: none;
								border: none;
								padding: 0 18px;
								padding-right: 46px;
							}
						}

						.search-camera {
							img {
								display: block;
							}
						}
					}

					.search-btn {
						width: 52px;
						height: 36px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}

					.login {
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						line-height: 36px;
						padding-left: 43px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}
			}

			.search-content {
				width: 1031px;
				height: 82px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.46);
				border-radius: 6px;
				position: absolute;
				z-index: 100;
				top: 382px;
				left: 444px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					display: flex;

					.search-select {
						display: flex;
						align-items: center;
						width: 140px;
						margin-left: 40px;

						/deep/.el-input__inner {
							border: none;
						}
					}

					.search-select::after {
						content: "";
						width: 1px;
						height: 29px;
						background: #929292;
						display: block;
					}

					/deep/.el-input__inner {
						border: none;
						width: 355px;
					}
				}

				.right {
					display: flex;
					align-items: center;

					.btn {
						width: 136px;
						height: 82px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						margin-left: 46px;
					}
				}

				.upload {
					position: absolute;
					top: 92px;

					/deep/.el-upload-dragger {
						height: 146px;
						width: 1031px;
					}
				}
			}
		}

		.block {
			width: 1280px;
			min-height: 680px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.left {
				width: 75%;
				background-color: #ffffff;
				margin: 30px 0;
				padding: 20px;
				box-sizing: border-box;

				.li {
					overflow: hidden;
					margin-bottom: 20px;
					border-bottom: #eee 1px dashed;
					padding-bottom: 20px;
					position: relative;
					min-height: 120px;
					cursor: pointer;

					.title {
						margin: 0 0 20px 0;
						font-size: 18px;
						overflow: hidden;
						font-weight: bold;
					}

					.info {
						.img {
							float: left;
							width: 23.2%;
							margin-right: 20px;
							display: block;
							overflow: hidden;
							border-radius: 3px;
							position: relative;

							img {
								display: block;
								width: 100%;
								-moz-transition: all .5s ease;
								-webkit-transition: all .5s ease;
								-ms-transition: all .5s ease;
								-o-transition: all .5s ease;
								transition: all .5s ease;
								transition: all 0.5s;
							}
						}

						.img:hover img {
							transform: scale(1.1);
						}

						.cont {
							.p {
								font-size: 14px;
								color: #666;
								overflow: hidden;
								text-overflow: ellipsis;
								-webkit-box-orient: vertical;
								display: -webkit-box;
								-webkit-line-clamp: 3;
							}

							.user_info {
								margin-top: 20px;
								overflow: hidden;
								color: #999;
								line-height: 34px;

								.user_avatar {
									position: relative;
									float: left;
									width: 30px;
									height: 30px;
									border-radius: 100%;
									padding: 2px;
									display: block;

									img {
										width: 100%;
										border-radius: 50%;
									}
								}

								span {
									margin: 0 5px;
								}
							}
						}
					}
				}
			}

			.right {
				width: 23.5%;
				margin: 30px 0;
				background-color: #fff;

				.btn-s {
					background: #fff;
					border-radius: 3px;
					margin-bottom: 20px;
					text-align: center;
					padding: 30px 0;

					.btn {
						width: 240px;
						height: 60px;
						background-color: #317ADA;
						border-radius: 6px;
						color: #fff;
						justify-content: center;
						font-size: 18px;
						margin: 0 auto 17px;
						display: flex;
						align-items: center;
						cursor: pointer;

						img {
							margin-right: 8px;
						}
					}

					.tips {
						font-size: 14px;
						color: #999;
					}
				}
			}
		}
	}

	.upload-demo {
		position: absolute;
		z-index: 100;
		top: 36px;

		/deep/.el-upload-dragger {
			width: 322px;
			height: 146px;
		}
	}

	.page-box {
		width: 100%;
		min-width: 1280px;
		overflow: hidden;

		.pages {
			width: 1280px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 30px;
			padding-bottom: 60px;
		}
	}

	/deep/.el-pager li {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		line-height: 32px;
	}

	/deep/.el-pager li.active {
		width: 32px;
		height: 32px;
		background: #08243c;
		border-radius: 50%;
	}

	/deep/.el-pager li.active {
		color: #ffffff;
	}

	/deep/.el-dialog,
	/deep/.el-pager li {
		background: none;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		background: none;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 16px;
		line-height: 32px;
	}
</style>