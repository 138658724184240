<template>
  <div>
    <div class="top">
      <div class="title">我的购物车</div>
      <div class="search-box">
        <div class="search">
          <el-input placeholder="作品名称编号" v-model="key_words">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button type="warning" style="margin-left: 33px" @click="search"
            >搜索</el-button
          >
        </div>

        <div class="partition">
          <div class="partition-text">选择分区</div>
          <el-select v-model="type" placeholder="请选择" @change="change">
            <el-option :label="item.typename" :value="item.id" v-for="(item,index) in cate" :key="index"></el-option>
           
          </el-select>
        </div>

        <div class="del" style="padding-right: 41px">
          <img
            src="@/assets/del.png"
            style="cursor: pointer"
            alt=""
            @click="del"
          />
        </div>
      </div>

      <div class="content" style="margin-top: 36px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
		  :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center">
          </el-table-column>
          <el-table-column label="作品展示图" width="120" align="center">
            <template slot-scope="scope">
              <img
                style="width: 54px; height: 54px; border-radius: 4px"
                :src="imageUrl(scope.row.litpic)"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="title" label="名称" width="120" align="center">
          </el-table-column>
          <el-table-column
            prop="product_id"
            label="编号"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>


          <el-table-column
            prop="method"
            label="购买方式"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="cost"
            label="价格"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="car-info">
          <div class="car-text">
           合计<span style="font-size: 20px; color: #e66617"
              >￥{{ money }}</span
            >
          </div>
          <div class="buy" @click="settle"  :style="shopActive == '' ?'background: #333;':''">立即购买</div>
        </div>
      </div>

      <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userCart, joinCart, cartSourceDetailorder,topCate } from "../../request/api";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      key_words: "", //作品名称编号搜索
      type: "", //所属区域
      count: 1,
      size: 4,
      page: 1,
      money: 0, //总价钱
	  chooseList:[],//选中列表
      shopActive: "",
      cate:''
    };
  },

  created() {
    this.getUserCart()
    this.getCata()
  },

  methods: {
	getRowKey (row) {
	    return row.product_id
	},
     //获取顶部分区
    getCata() {
      topCate().then((res) => {
        console.log(res);
        this.cate = res.data;
      });
    },

    //
    getUserCart() {
      userCart({
        key_words: this.key_words,
        type: this.type,
        page: this.page,
        size: this.size,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.tableData = res.data.cartInfo;
          this.count = res.data.count;
        }
      });
    },

    //多选
    handleSelectionChange(val) {
		// val.forEach(a=> {
		// 	this.chooseList.push(a)
		// })
	  
	 //  console.log(this.chooseList);
	 //  return;
      this.shopActive = val.map((i, index, item) => {
        return item[index].product_id;
      });

      this.multipleSelection = val.map((i, index, item) => {
        return item[index].cart_id;
      });


      var money = 0;
      for (var i = 0; i < val.length; i++) {
        money += Number(val[i].cost);
      }

      this.money = money;
    },

    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getUserCart();
    },

    //单删
    handleDelete(index, row) {
      console.log(row.cart_id);

      joinCart({
        cart_id: row.cart_id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });

          this.getUserCart();
        }
      });
    },

    //多删
    del() {
    
      if(this.multipleSelection == '') return
      joinCart({
        cart_id: this.multipleSelection.toString(),
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });

          this.getUserCart();
        }
      });
    },

    //搜索
    search() {
      this.getUserCart();
    },

    //分区搜索
    change() {
      this.getUserCart();
    },

    //结算

    settle() {
      if(this.shopActive == '' || this.shopActive == null) return


      this.$confirm("确定结算吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cartSourceDetailorder({
          card_id: this.shopActive.toString(),
        }).then((res) => {
			console.log(res);
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.go(0)
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 23px 0 23px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 29px;

    .search {
      display: flex;
    }

    .partition {
      display: flex;
      align-items: center;

      .partition-text {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        padding-right: 29px;
      }
    }
  }

  .content {
    .car-info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 80px;
      .car-text {
        font-size: 16px;

        font-weight: 400;
        color: #000000;
        line-height: 16px;
      }

      .buy {
        width: 100px;
        height: 36px;
        background: #e66617;
        border-radius: 4px;
        margin-left: 66px;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        cursor: pointer;
      }
    }
  }
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}
/deep/ .el-table th {
  padding: 0;
}

.page-box {
  overflow: hidden;
  .pages {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
</style>