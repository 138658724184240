<template>
  <div>
    <div class="top">
      <div class="title">上传作品</div>

      <div class="content">
        <div
          style="
            display: flex;
            align-item: center;
            justify-content: space-around;
          "
        >
          <el-upload
            class="avatar-uploader"
            action="/api/newapi/Archives/articleImgUpload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="litpic"
              :src="imageUrl(litpic)"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-plus el-icon-circle-plus"
              style="font-size: 47px"
            ></i>
            <!-- <div class="upload-text">点击上传作品</div> -->
          </el-upload>
        </div>

        <el-input v-model="title" placeholder="点击输入作品名称"></el-input>

        <div
          style="
            display: flex;
            align-item: center;
            justify-content: space-between;
          "
        >
          <el-input
            v-model="disklink"
            placeholder="点击输入PSD格式或TIF格式网盘链接"
            style="width: 343px"
          ></el-input>

          <el-input
            v-model="disklinkpwd"
            placeholder="点击输入网盘密码"
            style="width: 343px"
          ></el-input>
        </div>

        <div
          style="
            display: flex;
            align-item: center;
            justify-content: space-between;
          "
        >
          <!-- <el-input
            v-model="pattern"
            placeholder="点击输入模式"
            style="width: 343px"
          ></el-input> -->

          <el-input
            v-model="users_price"
            placeholder="点击输入标价"
            style="width: 343px"
          ></el-input>
		  
		  <el-select
		    v-model="format"
		    placeholder="请选择格式"
		    style="width: 343px"
		  >
		    <el-option label="PSD" value="PSD"></el-option>
		    <el-option label="TIFF" value="TIFF"></el-option>
		    <el-option label="AI" value="AI"></el-option>
		    <el-option label="CDR" value="CDR"></el-option>
		    <el-option label="EPS" value="EPS"></el-option>
		    <el-option label="PDF" value="PDF"></el-option>
		    <el-option label="金昌" value="金昌"></el-option>
		  </el-select>
        </div>

        <div
          style="
            display: flex;
            align-item: center;
            justify-content: space-between;
          "
        >
          <!-- <el-input
            v-model="size"
            placeholder="点击输入尺寸"
            style="width: 343px"
          ></el-input> -->

         
        </div>
      </div>
    </div>

    <div class="keyWord">
      <div class="title">关键词</div>
      <el-divider></el-divider>
      <div class="keyWord-search">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="点击输入关键词，多个关键词用英文','分割"
          v-model="seo_keywords"
          resize="none"
        >
        </el-input>

        <el-tooltip content="多个关键词用英文','分割" placement="top">
          <i class="el-icon-question" style="margin-left: 20px"></i>
        </el-tooltip>
      </div>
    </div>

    <div class="keyWord">
      <div class="title">上传板块</div>
      <el-divider></el-divider>

      <div style="padding-left: 54px">
        <el-select
          v-model="onceActive"
          placeholder="请选择"
          style="width: 343px"
          @change="onceChange"
        >
          <el-option
            v-for="item in once"
            :key="item.value"
            :label="item.typename"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-form
          label-position="top"
          label-width="80px"
          style="margin-top: 48px"
          v-if="cateOption"
        >
          <el-form-item
            :label="item.typename"
            style="margin-bottom: 30px"
            v-for="(item, index) in cateOption"
            :key="index"
          >
            <el-radio-group v-model="radio[index]">
              <el-radio-button
                style="margin: 18px 18px; border: 1px solid #aab7c2"
                :label="itemm.id"
                v-for="(itemm, indexx) in item.third"
                :key="indexx"
                >{{ itemm.typename }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="uploadDo" @click="submit">上传作品</div>

    <el-dialog :visible.sync="show" width="23%" center>
      <div class="titles">您尚未进行实名认证</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background: #08243c"
          @click="go('/seller/sellerInfo')"
          >点 击 认 证</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { realNameAuth, cate, arcTypeTh, uploading ,userArticDetail} from "@/request/api.js";
export default {
  data() {
    return {
      once: "", //一级分类列表
      cateOption: "", //二级三级列表
      onceActive: "", //一级分类选中
      radio: [], //分类选择
      show: false, //是否认证
      title: "", //作品名称
      users_price: "", //价格
      pattern: "", //模式
      disklink: "", //网盘链接
      disklinkpwd: "", //网盘密码
      size: "", //尺寸
      format: "", //格式（tif/psd）
      seo_keywords: "", //关键词
      litpic: "", //缩略图
     aid:''
    };
  },

  created() {
    this.getInfo()
    this.realNameAuth();
    this.getCate();
    this.aid = this.$route.query.aid
  },

  methods: {
    getInfo(){
      userArticDetail({
        aid:this.$route.query.aid
      }).then((res)=>{
        console.log(res.data)
       this.title = res.data.title
        this.disklink = res.data.disklink
        this.disklinkpwd =res.data.disklinkpwd
        this.format =res.data.format
        this.seo_keywords =res.data.seo_keywords
        this.users_price =res.data.users_price
        this.pattern =res.data.pattern
        this.size = res.data.size
        this.litpic = res.data.litpic
      })

    },

    go() {
      this.$router.push("/seller/sellerInfo");
    },

    //判断用户是否实名认证
    realNameAuth() {
      realNameAuth().then((res) => {
        // console.log(res);
        if (res.code == 500) {
          this.show = !this.show;
        }
      });
    },

    //获取一级分类
    getCate() {
      cate().then((res) => {
        this.once = res.data;
        // console.log(this.once);
      });
    },

    //一级分类改变
    onceChange(id) {
      arcTypeTh({ id }).then((res) => {
        this.cateOption = res.data;
      });
    },

    //上传成功回调
  

    handleAvatarSuccess(res, file) {
      this.litpic = res.data.img;
      console.log(this.litpic);
     
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },

    submit() {
      if (this.title == "" || this.title == null) {
        this.$message.error("请输入作品名称");
        return;
      }

      if (this.users_price == "" || this.users_price == null) {
        this.$message.error("请输入价格");
        return;
      }

      // if (this.pattern == "" || this.pattern == null) {
      //   this.$message.error("请输入模式");
      //   return;
      // }

      if (this.disklink == "" || this.disklink == null) {
        this.$message.error("请输入网盘链接");
        return;
      }

      if (this.disklinkpwd == "" || this.disklinkpwd == null) {
        this.$message.error("请输入网盘密码");
        return;
      }

      // if (this.size == "" || this.size == null) {
      //   this.$message.error("请输入尺寸");
      //   return;
      // }

      if (this.format == "" || this.format == null) {
        this.$message.error("请输入选择格式");
        return;
      }

      if (this.litpic == "" || this.litpic == null) {
        this.$message.error("请上传缩略图");
        return;
      }

      if (this.onceActive == "" || this.onceActive == null) {
        this.$message.error("请选择上传板块");
        return;
      }

      if (this.seo_keywords == "" || this.seo_keywords == null) {
        this.$message.error("请输入关键词");
        return;
      }

      if (this.radio.length == 0) {
        this.$message.error("请选择分类");
        return;
      }

      if (this.radio.length != this.cateOption.length) {
        this.$message.error("请选择分类");
        return;
      }

      var typeid = this.onceActive.toString() + "," + this.radio.toString();

      uploading({
        litpic: this.litpic,
        title: this.title,
        users_price: this.users_price,
        pattern: this.pattern,
        disklink: this.disklink,
        disklinkpwd: this.disklinkpwd,
        size: this.size,
        format: this.format,
        seo_keywords: this.seo_keywords,
        typeid: typeid,
        aid:this.aid
        
      }).then((res) => {
        console.log(res);

        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          this.$router.back()
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 54px 26px 54px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .content {
  }
}

.keyWord {
  width: 920px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 19px;
  padding-bottom: 37px;
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #08243c;
    line-height: 18px;
    padding-left: 23px;
    padding-top: 33px;
  }

  .keyWord-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 54px;
  }
}

.uploadDo {
  width: 186px;
  height: 50px;
  background: #08243c;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin-top: 28px;
  cursor: pointer;
}

.titles {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  text-align: center;
}

/deep/.el-input__inner,
/deep/.el-textarea__inner {
  background: rgba(8, 36, 60, 0.06);
}

/deep/.el-input {
  margin-top: 22px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload {
  width: 237px;
  height: 154px;
  background: rgba(8, 36, 60, 0.06);
  // opacity: 0.06;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .upload-text {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
  }
}

/deep/.avatar[data-v-bc227386] {
  width: 237px;
  height: 154px;
  display: block;
}

/deep/.el-radio-button__inner {
  width: 130px;
  height: 50px;

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #08243c;
  line-height: 24px;
  // line-height: 50px;
}
</style>