<template>
  <div class="box">
    <el-dialog :visible.sync="qqShow" width="23%" center>
      <div class="qq-text">
        <span>客服QQ</span>
        <span style="padding-left: 29px">{{ webQq }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="qqShow = false" class="qqbtn">确 定</el-button>
      </span>
    </el-dialog>
    <top />

    <div class="cate-box">
      <div class="cate">
        <el-select
          class="select"
          v-model="choose_type[index]"
          :placeholder="item.typename"
          v-for="(item, index) in arctype"
          :key="index"
          @change="change"
        >
          <el-option label="全部" value=""></el-option>
          <el-option
            :label="itemm.typename"
            :value="itemm.id"
            v-for="(itemm, indexx) in item.son"
            :key="indexx"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div
      v-if="is_search_img == 1"
      style="width: 100%; height: 300px; margin-left: 13%"
    >
      <img
        :src="imageUrl(search_img_url)"
        style="width: 200px; height: 200px"
      />
      <div class="bottom-top flex sb wrap">
        <div class="bt-left flex">
          <div>以图搜图结果</div>
          均来自于优质合作方提供的优质素材&nbsp;&nbsp;&nbsp;搜索结果数：{{
            list.length
          }}&nbsp;&nbsp;&nbsp;<span
            class="shou"
            @click="qqShow = true"
            target="_blank"
            style="color: rgb(49, 122, 218); font-weight: bold"
            >没有找到一样的图案？联系客服马上定制！</span
          >
        </div>
      </div>
    </div>
    <list :list="list" :hot_limit="hot_limit" :hot_max="hot_max" v-if="list.length" />
    <div v-else class="df">
      <el-empty
        description="正在搜索中 如果没有搜到此款图案，联系客服单独定制"
      ></el-empty>
    </div>

    <div class="page-box" v-if="is_search_img != 1">
      <div class="pages">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="size"
          layout="total, prev, pager, next ,jumper"
          :total="count"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
	<div class="original-wrap" v-if="is_search_img == 1 && showorigin && !closeorigin">
		<div class="original-header">
			<i class="close el-icon-close" @click="closeimg()"></i>
		</div>
		<div class="con">
			<img :src="imageUrl(search_img_url)" alt="">
		</div>
	</div>
    <foot style="margin-top: 86px" />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import list from "../../components/open-list/open-list.vue";
import foot from "../../components/common/footer.vue";
import { search, footData } from "../../request/api";
export default {
  components: {
    top,
    list,
    foot,
  },
  data() {
    return {
      list: "",
      type: "",
      count: 1,
      size: 20,
      page: 1,
      arctype: "", //分类
      choose_type: [],
      is_search_img: null,
      bounced: false,
      qqShow: false,
      webQq: "",
      href: "",
      hot_limit: "",
      hot_max: "",
	  showorigin:false,
	  closeorigin:false
    };
  },

  created() {
    this.getData();
    this.getFootData();
  },
  mounted() {
	window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    //获取数据
    getData() {
      search({
        imgs: this.$route.query.imgs,
        type: this.$route.query.type,
        keysWord: this.$route.query.keysWord,
        page: this.page,
      }).then((res) => {
        console.log(res);
        this.count = res.data.count;
        this.list = res.data.list;
		// for(var i=0;i<res.data.list.length;i++){
		// 	if(this.checkImgExists(imageUrl(res.data.list[i].litpic))){
		// 		this.list.push(res.data.list[i]);
		// 	}else{
		// 		this.count = this.count - 1;
		// 	}
		// }
        this.is_search_img = Number(res.data.is_search_img);
        this.search_img_url = res.data.search_img_url;
        this.bounced = true;
		this.hot_limit = res.data.hot_limit;
		this.hot_max = res.data.hot_max;
      });
    },
	handleScroll(event) {
	   let scrollTop =
	          window.pageYOffset ||
	          document.documentElement.scrollTop ||
	          document.body.scrollTop;
	  if(this.closeorigin){
		  this.showorigin = false;
	  }else{
		if(scrollTop > 420){
			this.showorigin = true;
		}else{
			this.showorigin = false;
		}  
	  }
	},
	closeimg(){
		this.closeorigin = true;
		this.showorigin = false;
	},
	checkImgExists(imgurl){
		var ImgObj = new Image();
		ImgObj.src = imgurl;
		if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) { 
			return true;
		} else {
			return false;
		}
	},
    //获取数据
    getFootData() {
      footData({}).then((res) => {
        this.webQq = res.data.webQq.value;
      });
    },
    //分页
    handleCurrentChange(val) {
      console.log(val);
      this.page = val;
      this.getData();
    },
    contact() {
      this.bounced = false;
      this.qqShow = true;
    },
  },

  watch: {
    $route: {
      // 亲测有效,我是用来监听query参数变化
      handler() {
        this.aid = this.$route.query.aid;
        this.getData();
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f6f6f6;
}

.cate-box {
  width: 100%;
  min-width: 1280px;
  .cate {
    width: 1280px;
    margin: 0 auto;
    padding: 43px 0;

    .select {
      margin-left: 63px;
      width: 146px;
    }

    .select:first-child {
      margin-left: 0;
    }
  }
}

.page-box {
  width: 100%;
  min-width: 1280px;
  overflow: hidden;
  .pages {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
/deep/.el-dialog {
  background-color: #fff !important;
  text-align: center;
}
.content {
  text-align: center;
  font-size: 16px;
}
.df {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.bottom-top {
  padding: 30px 20px;
  text-align: left;
}
.wrap {
  width: 100%;
}
.sb {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.bt-left div {
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  background-color: #000;
  padding: 0 15px;
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
}
.bt-left {
  font-size: 14px;
  color: #666;
}
.original-wrap{
	position: fixed;
	left: 0px;
	top: 306px;
	padding: 5px;
	margin: 0 auto;
	cursor: pointer;
	overflow: hidden;
	z-index: 1988;
	width: 200px;
	box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
	background-color: #fff;
	border-radius: 2px;
	box-sizing: border-box;
	.original-header{
		.close{
			position: absolute;
			right: 5px;
			top: 5px;
			text-align: left;
		}
	}
	.con{
		padding: 10px;
		margin-top: 8px;
		img{
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%
		}
	}
}
</style>