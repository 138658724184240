<template>
	<div class="footer-box">
		<div class="footer">
			<div class="top">
				<div class="footer-item">
					<img :src="imageUrl(logo)" style="width: 200px; display: block;" alt="">
				</div>
				<div class="footer-item">
					<div class="footer-item-title">新手帮助</div>
					<div class="footer-item-text">
						<div @click="protocol('exclusivesigning')">以图绘图网声明</div>
						<div @click="protocol('loginterms')">条款协议</div>
						<div @click="protocol('copyrightissues')">版权问题</div>
						<div @click="protocol('aboutus')">关于我们</div>
					</div>
				</div>
				<div class="footer-item">
					<div class="footer-item-title">常见问题</div>
					<div class="footer-item-text">
						<div @click="protocol('uploadproblem')">上传作品问题</div>
						<div @click="protocol('salesproblems')">售前售后问题</div>
						<div @click="protocol('fordesigners')">设计师行为准则</div>
						<div @click="protocol('buyerproblem')">买家问题</div>
						<div @click="protocol('sellerissues')">卖家问题</div>
					</div>
				</div>
				<div class="footer-item">
					<div class="footer-item-title">联系我们</div>
					<div class="footer-item-text">
						<div>客服电话：{{webAttr }}</div>
						<div>客服Q Q： {{webQq }}</div>
						<div>咨询时间：{{webSiteTime }}</div>
						<div> 邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：{{ webEmail
              }}
						</div>
					</div>
				</div>
				<div class="footer-item">
					<div class="footer-item-title">公司简介</div>
					<div class="footer-item-text">
						<div style="width: 154px">
							{{webintroduction }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-divider></el-divider>
		<div class="bottom">
			<div class="bottom-item">
				{{webCopyright }}
			</div>
			<div class="bottom-item">
				<a href="http://beian.miit.gov.cn" target="_blank"> 备案号：{{webRecordnum }}
				</a>
			</div>
			<div class="bottom-item">地址：{{webCompanyAddress}}</div>
		</div>
	</div>
</template>
<script>
	import {
		footData
	} from "../../request/api";
	export default {
		data() {
			return {
				webAttr: "",
				webCompanyAddress: "",
				webCopyright: "",
				webEmail: "",
				webQq: "",
				webRecordnum: "",
				webSiteTime: "",
				webintroduction: "",
				logo: ''
			};
		},
		created() {
			this.getFootData();
		},
		methods: {
			//获取数据
			getFootData() {
				footData({}).then((res) => {
					// console.log(res.data)
					this.webAttr = res.data.webAttr.value
					this.webCompanyAddress = res.data.webCompanyAddress.value
					this.webCopyright = res.data.webCopyright.value
					this.webEmail = res.data.webEmail.value
					this.webQq = res.data.webQq.value
					this.webRecordnum = res.data.webRecordnum.value
					this.webSiteTime = res.data.webSiteTime.value
					this.webintroduction = res.data.webintroduction.value
					this.logo = res.data.webLogo.value
				});
			},
			protocol(type) {
				this.$router.push({
					path: "/clause",
					query: {
						type: type,
					},
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.footer-box {
		width: 100%;
		// height: 342px;
		background: #08243c;

		.footer {
			width: 1280px;
			margin: 0 auto;
			padding-top: 46px;

			// height: 100%;
			.top {
				display: flex;
				justify-content: space-around;

				.footer-item {
					.footer-item-title {
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						line-height: 25px;
					}

					.footer-item-title::after {
						content: "";
						width: 17px;
						height: 1px;
						background: #a5a5a5;
						border: 1px solid #797979;
						display: block;
						margin: 16px 0px 20px 0px;
					}

					.footer-item-text {
						font-size: 12px;
						font-weight: 400;
						color: #a8a8a8;
						line-height: 30px;
					}

					.footer-item-text div:hover {
						color: #fff;
						cursor: pointer;
					}
				}
			}
		}

		.bottom {
			width: 1280px;
			margin: 0 auto;
			display: flex;
			// justify-content: space-around;
			align-items: center;
			justify-content: space-evenly;
			padding-bottom: 24px;

			.bottom-item,
			.bottom-item a {
				font-size: 12px;
				font-weight: 400;
				color: #7d7d7d;
				line-height: 12px;
				text-decoration: none;
			}

			.bottom-item:hover,
			.bottom-item a:hover {
				color: #fff;
				cursor: pointer;
			}
		}
	}

	/deep/.el-divider {
		background: rgba(255, 255, 255, 0.06);
	}
</style>