<template>
	<div>
		<top />
		<div class="container">
			<div class="content">
				<div class="warp">
					<div>
						<div class="left">
							<div class="photo">
								<div class="choose">
									<div class="up" @click="up">
										<i class="el-icon-caret-top" style="color: #b7b7b7"></i>
									</div>

									<div class="choose-box">
										<div class="wrap" :style="{ top: -index * 118.3 + 'px' }">
											<div class="item" v-for="(item, index) in archivesInfo" :key="index"
												@click="activeIty(index)">
												<img :src="imageUrl(item.litpic)" alt="" />
											</div>
										</div>
									</div>

									<div class="down" @click="down">
										<i class="el-icon-caret-bottom" style="color: #1a3c59"></i>
									</div>
								</div>
							</div>

							<div class="img-box">
								<img v-if="clothingTem.length" class="img1"
									:src="imageUrl(clothingTem[active1].arctype_clothes_img)" alt="" />
								<div v-if="archivesInfo.length && fourConsecutive == true" id="img" class="logo" :style="{
								  width: width + 'px',
								  height:height + 'px',
								  top: top + 'px',
								  left: left + 'px',
								  backgroundImage: 'url(' + imgs + ')',
								  backgroundSize: bcSize + 'px',
								}">
								</div>
								<img class="logo" id="img" v-if="archivesInfo.length && fourConsecutive == false"
									:src="imgs" :style="{
									width: width + 'px',
									height:height + 'px',
									top: top + 'px',
									left: left + 'px',
									transform: `scale(${diploid}, ${diploid})`,
								  }" alt="" />
								<div class="bj" :style="{backgroundColor: bc}"></div>
							</div>

							<div class="clothes" v-if="clothingTem">
								<div class="item" v-for="(item, index) in clothingTem" :key="index"
									@click="active1 = index">
									<img :src="imageUrl(item.arctype_clothes_img)" alt="" />
								</div>
							</div>
						</div>

						<div class="function-box">
							<div class="enlarge">
								<div style="font-size: 180%;">
									<i class="el-icon-caret-top" style="cursor: pointer" @click="top -= 5"></i>
									<i class="el-icon-caret-bottom" style="margin-left: 24px; cursor: pointer"
										@click="top += 5"></i>
								</div>
								<div style="font-size: 180%;">
									<i class="el-icon-caret-left" style="cursor: pointer" @click="left -= 5"></i>
									<i class="el-icon-caret-right" style="margin-left: 24px; cursor: pointer"
										@click="left += 5"></i>
								</div>
								<div style="font-size: 180%;">
									<i class="el-icon-zoom-in" style="cursor: pointer" @click="add"></i>
									<i class="el-icon-zoom-out" style="margin-left: 24px; cursor: pointer"
										@click="del"></i>
								</div>
							</div>

							<el-button class="keep" style="width: 85px" @click="rect">重置</el-button>
							<el-button class="keep" style="width: 103px" @click="show = true">细节图</el-button>
						</div>
					</div>

					<div class="right">
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
							@select="handleSelect" text-color="#08243C">
							<el-menu-item :index="item.id.toString()" v-for="(item,index) in cate" :key="index">
								{{item.typename}}
							</el-menu-item>

						</el-menu>

						<div v-if="activeIndex == 1">
							<div class="cate-item" v-for="(item, index) in openCate" :key="index">
								<div class="cate-name">{{ item.typename }}</div>
								<div class="cate-box">
									<el-radio-group v-model="choose[index]" @change="change">
										<el-radio-button class="cate" label="">
											全部
										</el-radio-button>

										<el-radio-button class="cate" v-for="(itemm, indexx) in item.parent_id"
											:key="indexx" :label="itemm.id">
											{{ itemm.typename }}
										</el-radio-button>
									</el-radio-group>
								</div>
							</div>
						</div>

						<div v-if="activeIndex == 2">
							<div class="cate-item" v-for="(item, index) in originalCate" :key="index">
								<div class="cate-name">{{ item.typename }}</div>
								<div class="cate-box">
									<el-radio-group v-model="choose[index]" @change="change">
										<el-radio-button class="cate" label="">
											全部
										</el-radio-button>

										<el-radio-button class="cate" v-for="(itemm, indexx) in item.parent_id"
											:key="indexx" :label="itemm.id">
											{{ itemm.typename }}
										</el-radio-button>
									</el-radio-group>
								</div>
							</div>
						</div>

						<div v-if="activeIndex == 20">
							<div class="cate-item" v-for="(item, index) in materialCate" :key="index">
								<div class="cate-name">{{ item.typename }}</div>
								<div class="cate-box">
									<el-radio-group v-model="choose[index]" @change="change">
										<el-radio-button class="cate" v-for="(itemm, indexx) in item.parent_id"
											:key="indexx" :label="itemm.id">
											{{ itemm.typename }}
										</el-radio-button>
									</el-radio-group>
								</div>
							</div>
						</div>

						<div class="buy" @click="text">购买商品</div>
					</div>
				</div>
			</div>

			<div class="title-box">
				<div class="title">
					热门图案推荐 <img src="@/assets/hot.png" alt="" />
				</div>
			</div>

			<div class="hot-box">
				<div class="hot-list">
					<div class="hot-item" v-for="(item, index) in hotArc" :key="index"
						@click="go('/openAreaDetail', item.product_id)">
						<img :src="imageUrl(item.product_litpic)" alt="" />
					</div>
				</div>
			</div>

			<div class="title-box">
				<div class="title">热门设计师</div>
			</div>

			<div style="padding-bottom: 104px">
				<c-swiper :propOption="swipeBoxSpace" :designers="designersData" v-if="designersData.length"></c-swiper>
			</div>
		</div>

		<foot />

		<!-- 细节图开始 -->
		<el-dialog :visible.sync="show" width="46%">
			<div class="show">
				<img :src="imageUrl(archivesInfo[active2].litpic)" v-if="archivesInfo.length" />
				<!-- {{archivesInfo[active2]['litpic']}} -->
			</div>
		</el-dialog>

		<!-- 细节图结束 -->
	</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import Swiper from "../../components/designer/swiper.vue";
	import SwiperGroup from "../../components/designer/swipe-group.vue";
	import foot from "../../components/common/footer.vue";
	import {
		fittingRoomUnder,
		fittingRoomClothes,
		fittingRoomType,
		topCate,
		checkOriginalAuth
	} from "../../request/api";
	export default {
		components: {
			top,
			"c-swiper": Swiper,
			"c-swiper-group": SwiperGroup,
			foot,
		},
		data() {
			return {
				swipeBoxSpace: {
					propId: "swipeBoxSpace",
					height: "280px",
					width: "1280px",
					slidesPerView: 5,
					spaceBetween: 45,
				},
				designersData: "", //热门设计师
				activeIndex: "1", //右侧选项卡
				radio1: [], //分类选择
				clothingTem: "", //衣服
				openCate: "", //公开区分类
				originalCate: "", //原创区分类
				materialCate: "", //素材区
				choose: [], //选中的分类

				archivesInfo: "", //logo
				clothingTem: "", //衣服
				top: 150,
				left: 150,
				width: 100,
				height: 100,
				diploid: 1,
				active1: 0,
				active2: 0,
				hotArc: "", //热门图案
				cate: {

				},
				show: false,

				index: 0, //当前选中标识
				isShow: false,
				imgs: null,
				bc: null,
				fourConsecutive: false,
				bcSize: 100
			};
		},

		created() {
			this.getDenger();
			this.getFittingRoomType();
			this.getCata()
			this.checkOriginalAuth()
			// 热门图案列表
		},
		methods: {
			//获取左侧衣服
			getfittingRoomClothes() {
				fittingRoomClothes({
					choose_type: this.activeIndex + "," + this.choose.toString(),
				}).then((res) => {
					console.log(res);
					this.archivesInfo = res.data.archivesInfo;
					this.clothingTem = res.data.clothingTem;
					// console.log(this.archivesInfo[this.active2].litpic);
					if (this.archivesInfo[this.active2].repeat) {
						this.top = 0
						this.left = 0
						this.width = 425
						this.height = 444
						this.fourConsecutive = true
					} else {
						this.top = 150
						this.left = 150
						this.width = 100
						this.height = 100
						this.fourConsecutive = false

						this.getBodymovinJson()
					}
					this.imgs = this.imageUrl(this.archivesInfo[this.active2].litpic)
				});
			},
			activeIty(ind) {
				this.active2 = ind
				if (this.archivesInfo[this.active2].repeat) {
					this.top = 0
					this.left = 0
					this.width = 425
					this.height = 444
					this.fourConsecutive = true
				} else {
					this.top = 150
					this.left = 150
					this.width = 100
					this.height = 100
					this.fourConsecutive = false

					this.getBodymovinJson()
				}
				this.imgs = this.imageUrl(this.archivesInfo[this.active2].litpic)
			},
			
			add() {
				if (this.fourConsecutive == true) {
					this.bcSize = 100
					this.diploid += 0.1
					this.bcSize = this.bcSize * this.diploid
					this.width = this.width * (this.bcSize / 100)
					this.height = this.height * (this.bcSize / 100)
				} else {
					this.diploid += 0.1
				}
			
			},
			del() {
				if (this.fourConsecutive == true) {
					this.bcSize = 100
					this.diploid -= 0.1
					this.bcSize = this.bcSize * this.diploid
					this.width = this.width * (100 / this.bcSize)
					this.height = this.height * (100 / this.bcSize)
				} else {
					this.diploid -= 0.1
				}
			},
			//获取热门设计师
			getDenger() {
				fittingRoomUnder({}).then((res) => {
					console.log(res);
					this.designersData = res.data.designersData


					this.hotArc = res.data.hotArc;


				});
			},

			//获取分类
			getCata() {
				topCate().then((res) => {

					this.cate = res.data;

					console.log(this.cate)
				});
			},
			go(url, aid) {

				this.$router.push({
					path: url,
					query: {
						aid: aid,
					},
				});
			},

			//获取分类
			getFittingRoomType() {
				fittingRoomType().then((res) => {
					console.log('111', res)
					this.openCate = res.data.arctype[0].parent_id;
					this.originalCate = res.data.arctype[1].parent_id;
					this.materialCate = res.data.arctype[2].parent_id;
					this.getfittingRoomClothes();
					this.choose = [];
				});
			},

			//切换分类 加载数据
			change() {
				this.getfittingRoomClothes();
			},

			text() {

				let aid = this.archivesInfo[this.active2].aid

				this.$router.push({
					path: '/openAreaDetail',
					query: {
						aid: aid
					}
				})

			},



			//tab切换
			handleSelect(key) {
				console.log(key);
				if (this.isShow == true && key == 20) {
					this.$message({
						message: '请购买原创会员',
						type: "warning",
					});
					return
				}
				this.activeIndex = key;
				this.choose = [];
				this.getfittingRoomClothes();
			},
			checkOriginalAuth() {
				checkOriginalAuth().then((res) => {
					console.log(res)
					if (res.code == 500) {
						this.isShow = true
					}
				});
			},
			//重置按钮

			up() {
				if (this.archivesInfo.length <= 4) {
					return;
				}
				if (this.index == this.archivesInfo.length - 4) {
					return;
				}

				this.index++;
			},

			down() {
				if (this.index == 0) {
					return;
				}

				this.index--;
			},

			//重置
			rect() {
				if (this.fourConsecutive == true) {
					this.diploid = 1;
					this.top = 0
					this.left = 0
					this.width = 425
					this.height = 444
					this.bcSize = 100
				} else {
					this.top = 150;
					this.left = 150;
					this.diploid = 1;
				}
			},

			getBodymovinJson() {
				var that = this;
				let image = that.imageUrl(that.archivesInfo[that.active2].litpic)
				this.getBase64(image, res => {
					// console.log(res);
					that.imgs = res
					setTimeout(function() {
						that.initCanvas();
					}, 500)
				})
			},
			getBase64(url, callback) {
				//通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
				var Img = new Image(),
					dataURL = '';
				Img.src = url + "?v=" + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
				Img.setAttribute("crossOrigin", 'Anonymous') // 解决控制台跨域报错的问题
				Img.onload = function() { //要先确保图片完整获取到，这是个异步事件
					var canvas = document.createElement("canvas"), //创建canvas元素
						width = Img.width, //确保canvas的尺寸和图片一样
						height = Img.height;
					canvas.width = width;
					canvas.height = height;
					console.log(Img);
					console.log(width);
					console.log(height);
					canvas.getContext("2d").drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
					dataURL = canvas.toDataURL('image/png'); //转换图片为dataURL
					callback ? callback(dataURL) : null; //调用回调函数
				};
			},
			initCanvas() {
				var that = this;
				var img = document.getElementById('img');
				// var output = document.getElementById('output');
				var canvas = this.draw(img);
				var x = 0;
				var y = 0;
				var color = (canvas.getColor(x, y)).rgba;
				// document.body.style.background = color;
				console.log(color)
				that.bc = color;
				canvas.canvas.remove()
				// output.innerHTML = '坐标点颜色：' + color;
			},
			draw(img) {
				var style = window.getComputedStyle(img);
				var width = parseInt(style.width, 10);
				var height = parseInt(style.height, 10);
				var canvas = document.createElement('canvas');
				canvas.width = width;
				canvas.height = height;
				var ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);

				document.body.appendChild(canvas);
				// 获取坐标颜色
				function getPixelColor(x, y) {
					var imageData = ctx.getImageData(x, y, 1, 1);
					var pixel = imageData.data;
					console.log(pixel);
					var r = pixel[0];
					var g = pixel[1];
					var b = pixel[2];
					var a = pixel[3] / 255;
					a = Math.round(a * 100) / 100;
					var rHex = r.toString(16);
					r < 16 && (rHex = "0" + rHex);
					var gHex = g.toString(16);
					g < 16 && (gHex = "0" + gHex);
					var bHex = b.toString(16);
					b < 16 && (bHex = "0" + bHex);
					var rgbaColor = "rgba(" + r + "," + g + "," + b + "," + a + ")";
					var rgbColor = "rgb(" + r + "," + g + "," + b + ")";
					var hexColor = "#" + rHex + gHex + bHex;

					return {
						rgba: rgbaColor,
						rgb: rgbColor,
						hex: hexColor,
						r: r,
						g: g,
						b: b,
						a: a,
					};
				}
				return {
					getColor: getPixelColor,
					canvas: canvas
				};
			},
		},
	};
</script>

<style lang="less" scoped>
	.container {
		width: 100%;
		min-width: 1280px;
		overflow: hidden;

		.content {
			width: 1220px;
			margin: 0 auto;
			margin-top: 43px;
			// height: 799px;
			background: #fff;
			border-radius: 10px;
			display: flex;

			flex-direction: column;
			// align-items: center;
			padding-top: 86px;
			padding-left: 31px;
			padding-bottom: 93px;
			padding-right: 30px;

			.warp {
				display: flex;

				.left {
					display: flex;
					// align-items: center;

					.photo {
						.choose {
							width: 102px;

							.up,
							.down {
								width: 102px;
								height: 26px;
								background: rgba(8, 36, 60, 0.06);
								// opacity: 0.06;
								border-radius: 4px;
								text-align: center;
								line-height: 26px;
								cursor: pointer;
							}

							.choose-box {
								height: 472px;
								overflow: hidden;
								position: relative;
								padding-bottom: 16px;

								.wrap {
									position: absolute;

									.item {
										width: 80px;
										height: 80px;
										padding: 10px;
										border: 1px solid #d9d9d9;
										margin-top: 16px;
										cursor: pointer;

										img {
											width: 100%;
											height: 100%;
											display: block;
										}
									}
								}
							}
						}
					}

					.img-box {
						width: 425px;
						height: 444px;
						margin-left: 72px;
						position: relative;
						overflow: hidden;

						.img1 {
							width: 100%;
							height: 100%;
							position: absolute;
							z-index: 2;
						}

						.logo {
							position: absolute;
							z-index: 1;
						}
					}

					.clothes {
						width: 103px;
						height: 472px;

						overflow-y: scroll;

						.item {
							width: 80px;
							height: 80px;
							background: #ffffff;
							border: 1px solid #d9d9d9;
							margin-top: 16px;
							padding: 10px;
							cursor: pointer;

							img {
								width: 100%;
								height: 100%;
								display: block;
							}
						}

						.item:first-child {
							margin-top: 9px;
						}
					}

					.clothes::-webkit-scrollbar {
						display: none;
					}
				}

				.right {
					width: 100%;
					padding-left: 35px;

					.cate-item {
						margin-top: 10px;

						.cate-name {
							font-size: 20px;
							font-weight: 400;
							color: #000000;
							line-height: 28px;
						}

						.cate-box {
							margin-top: 21px;
							display: flex;
							align-items: center;

							flex-wrap: wrap;

							.cate {
								border-radius: 4px;
								font-size: 16px;
								font-weight: 400;
								color: #666666;
								line-height: 36px;
								text-align: center;
								margin-left: 18px;
							}

							.cate:nth-child(4n-3) {
								margin-left: 0px;
							}

							.cate:nth-child(n + 5) {
								margin-top: 20px;
							}
						}
					}

					.buy {
						width: 193px;
						height: 50px;
						background: #e66617;
						border-radius: 4px;
						margin-top: 95px;
						font-size: 20px;
						font-weight: 400;
						color: #ffffff;
						line-height: 50px;
						text-align: center;
						cursor: pointer;
					}
				}
			}

			.function-box {
				margin-top: 93px;
				padding-left: 48px;
				display: flex;
				align-items: center;
				justify-content: space-around;

				.enlarge {
					width: 298px;
					height: 53px;
					border: 1px solid #eaeaea;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}

				.keep {
					width: 126px;
					height: 54px;

					text-align: center;
					font-size: 16px;
					font-weight: 400;
					color: #1a3c59;
				}
			}
		}
	}

	.hot-box {
		width: 100%;
		min-width: 1280px;
		overflow: hidden;

		.hot-list {
			width: 1280px;
			margin: 0 auto;
			display: flex;
			align-items: center;

			.hot-item {
				width: 235px;
				height: 234px;
				border-radius: 4px;
				margin-left: 27px;
				background: #fff;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}

			.hot-item:first-child {
				margin-left: 0;
			}
		}
	}

	// 细节图
	.show {
		// width: 895px;
		//   height: 761px;
		// background: red;

		padding: 0 69px;

		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.title-box {
		width: 100%;
		min-width: 1280px;

		.title {
			width: 1280px;
			margin: 0 auto;
			padding-top: 56px;
			padding-bottom: 29px;
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			line-height: 24px;
		}
	}

	/deep/.el-radio-button__inner {
		width: 100px;
		height: 36px;
		border: 1px solid #c2cad1;
		border-radius: 4px;
	}

	/deep/.el-menu-item {
		font-size: 20px;
		font-weight: 400;
		color: #000000;
		line-height: 28px;
	}

	/deep/[data-v-5af97724] .el-menu-item {
		font-size: 18px;
	}

	.bj {
		z-index: 1111;
		width: 100%;
		height: 100%
	}
</style>
