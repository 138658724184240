var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.go('/openAreaDetail', item.aid,item.sign)}}},[_c('div',{staticClass:"poa"},[(item.sales_num >= _vm.hot_limit)?_c('img',{attrs:{"src":require("../../assets/bao.png"),"width":"32px","height":"38px","alt":""}}):_vm._e()]),(_vm.show_model=='1')?_c('img',{attrs:{"src":_vm.imageUrl(item.litpic),"alt":"","id":'img'+item.aid}}):_vm._e(),(_vm.show_model=='2')?_c('div',{staticClass:"yiwrap"},[(item.clothingTem)?_c('img',{staticClass:"closeimg",attrs:{"src":_vm.imageUrl(item.clothingTem),"alt":""}}):_vm._e(),(item.repeat == 1)?_c('div',{staticClass:"logo",style:({
			  'z-index':'1',
			  position: 'absolute', 
			  width: '100%',
			  height: '100%',
			  backgroundImage: 'url(' + _vm.imageUrl(item.imgs) + ')',
			  backgroundSize: '55px',
			}),attrs:{"id":'img'+item.aid}}):_vm._e(),(item.repeat != 1)?_c('img',{staticClass:"logo1",attrs:{"id":'img'+item.aid,"src":_vm.imageUrl(item.imgs),"alt":""}}):_vm._e(),_c('div',{staticClass:"bj",style:({ backgroundColor: item.color })})]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }