<template>
  <div class="all">
    <top />

    <div class="special">
      <div class="df">
        <div class="special-warp">
          <div>热门精品</div>
          <div>Special recommendation</div>
        </div>
      </div>

      <div class="special-content">
        <div
          class="special-item"
          v-for="(item, index) in recommendType"
          :key="index"
          @click="searchDo2(item.typename)"
        >
          <img :src="imageUrl(item.litpic)" alt="" />
          <div class="special-text">{{ item.typename }}</div>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import list from "../../components/open-list/open-list.vue";
import foot from "../../components/common/footer.vue";
import { Popularboutiques } from "../../request/api";
export default {
  components: {
    top,
    list,
    foot,
  },
  data() {
    return {
      recommendType: "",
    };
  },

  created() {
    this.getIndexData();
  },

  methods: {
    getIndexData() {
      Popularboutiques({}).then((res) => {
        this.recommendType = res.data;
      });
    },
    searchDo2(name) {
      this.$router.push({
        path: "/search",
        query: {
          keysWord: name,
          imgs: 0,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f6f6f6;
}

.cate-box {
  width: 100%;
  min-width: 1280px;
  .cate {
    width: 1280px;
    margin: 0 auto;
    padding: 43px 0;

    .select {
      margin-left: 63px;
      width: 146px;
    }

    .select:first-child {
      margin-left: 0;
    }
  }
}

.page-box {
  width: 100%;
  min-width: 1280px;
  overflow: hidden;
  .pages {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
.block {
  width: 1280px;
  margin: 0 auto;
}
.small {
  font-size: 500px;
}
.special {
  width: 1280px;
  margin: 0 auto;
  margin-top: 80px;

  .special-warp {
    display: flex;
    align-items: center;
  }

  .special-warp div:first-child {
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }

  .special-warp div:first-child::after {
    content: "/";
    font-size: 16px;

    font-weight: 400;
    color: #afafaf;
    padding: 0 12px;
  }

  .special-warp div:last-child {
    font-size: 16px;

    font-weight: 400;
    color: #afafaf;
    line-height: 16px;
  }

  .special-content {
    padding-top: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .special-item {
      margin-bottom: 69px;
      width: 361px;
      height: 336px;
      background: #ffffff;
      border-radius: 6px;
      cursor: pointer;

      img {
        width: 361px;
        height: 285px;
        display: block;
      }

      .special-text {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 51px;
      }
    }
  }
}
</style>