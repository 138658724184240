<template>
	<div v-if="visible" class="loading-mask">
		<div class="loading-spinner"></div>
		<div class="">下载中...</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				visible: false
			}
		},
		methods: {
			show() {
				this.visible = true
			},
			hide() {
				this.visible = false
			}
		}
	}
</script>
<style>
	.loading-mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
		display: flex;
		flex-direction: column;
		color: #ffffff;
		align-items: center;
		justify-content: center;
	}

	.loading-spinner {
		border: 4px solid #f3f3f3;
		border-radius: 50%;
		border-top: 4px solid #3498db;
		width: 60px;
		height: 60px;
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
		margin-bottom: 20px;
	}

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
</style>