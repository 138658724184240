<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script type="text/javascript">
export default {
  created() {
    if (window.innerWidth < 1000) {
		var url = document.URL.replace(window.location.host,'m.yituhuitu.com');
		url = url.replace('https','http');
		url = url.replace('m.yituhuitu.com/openAreaDetail','m.yituhuitu.com/details');
		window.location.href = url;
    }else{
		var that = this;
		that.$router.onReady(() => {
		    if (that.$route.matched.length <= 0){
		    	window.location.href = "/";
		    	return false;
		    }
		})
	}
  },
};
</script>


<style lang="scss">
@import "./static/iconfont.css";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
html {
  height: 100%;
}

body {
  height: 100%;
}
shou #app {
  //  height: 100%;
  background: #f6f6f6;
}
.shou {
  cursor: pointer;
}
</style>
