<template>
  <div>
    <div class="top">
      <div class="title">充值明细</div>
      <div class="search-box">
        <div class="text">时间范围</div>
        <el-date-picker
          v-model="start_time"
          type="date"
          style="margin-left: 29px; width: 120px"
          placeholder="开始时间"
           format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
        >
        </el-date-picker>


        <span
          style="width: 17px; height: 2px; background: #ebf0f5; margin: 0 12px"
        ></span>
        <el-date-picker
          v-model="end_time"
          type="date"
          placeholder="结束时间"
          style="width: 120px"
           format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
        >
        </el-date-picker>
        <el-button type="warning" style="background: #e66617; margin-left: 46px" @click="search"
          >搜索</el-button
        >
      </div>

      <el-table :data="tableData" style="width: 100%; margin-top: 32px">
        <el-table-column prop="money" label="充值金额" align="center">
        </el-table-column>
        <el-table-column label="充值方式" align="center">
          <template slot-scope="scope"> 微信 </template>
        </el-table-column>
        <el-table-column prop="add_time" label="提交时间" align="center">
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">失败</span>
            <span v-if="scope.row.status == 2">未付款</span>
            <span v-if="scope.row.status == 3">已付款</span>
            <span v-if="scope.row.status == 4">已完成</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { rechargeDetails } from "../../request/api";
export default {
  data() {
    return {
      start_time:'',
      end_time:'',
      tableData: [],
    };
  },

  created() {
    this.getData();
  },
  methods: {
    getData() {
      rechargeDetails({
        start_time:this.start_time,
        end_time:this.end_time
      }).then((res) => {
        console.log(res);

        this.tableData = res.data.moneyOrder;
      });
    },

    search(){
       this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 23px 68px 23px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .search-box {
    display: flex;
    align-items: center;
    margin-top: 39px;
    padding-left: 15px;

    .text {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
    }
  }
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}
/deep/ .el-table th {
  padding: 0;
}
</style>