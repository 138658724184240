<template>
  <div>
    <top />

    <div class="container">
      <div class="content">
        <!-- <div class="title">免责声明</div> -->
        <div class="text-area" v-html="content"></div>
        <!-- <quillEditor v-model="content" /> -->
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import { clauseData } from "../../request/api";

export default {
  components: {
    top,
    foot,
  },

  data() {
    return {
      content: "",
      type: "",
    };
  },

  created() {
    this.type = this.$route.query.type
    this.getClauseData();
  },

  watch: {
    $route: {
      handler() {
        this.type = this.$route.query.type;
        this.getClauseData();
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
  },
  methods: {
    getClauseData() {
      clauseData({
        type: this.type,
      }).then((res) => {
        // console.log(res.data);
        this.content = this.showHtml(res.data);
      });
    },

    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  min-width: 1280px;

  .content {
    width: 1280px;
    margin: 0 auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 37px;
    padding-bottom: 139px;
    margin-top: 43px;
    margin-bottom: 80px;
    padding-left: 112px;
    padding-right: 76px;

    .textarea {
    }
  }
}
</style>