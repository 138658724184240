<template>
  <div>
    <top />
    <div class="container">
      <div class="content">
        <div class="left">
          <div class="top">
            <div class="user-info">
              <div class="block">
                <el-avatar
                  :size="82"
                  :src="imageUrl(head_pic)"
                  v-if="head_pic"
                ></el-avatar>
              </div>
              <div class="uname">{{ nickname }}</div>
              <div class="balance">
                <span>余额</span>
                <span style="padding-left: 11px">￥{{ users_money }}</span>
              </div>
              <div class="consum-info">
                <div>
                  <span>粉丝</span>
                  <span style="padding-left: 11px">{{ fanNum }}</span>
                </div>
                <div>
                  <span>作品数</span
                  ><span style="padding-left: 11px">{{ arctNum }}</span>
                </div>
              </div>

              <div class="recharge" @click="go('/seller/withdrawal')">
                我要提现
              </div>
            </div>
          </div>

          <div class="bottom">
            <div
              :class="url == '/seller/center' ? 'item item-active' : 'item'"
              @click="go('/seller/center')"
            >
              个人中心
            </div>
            <div
              :class="url == '/seller/upload' ? 'item item-active' : 'item'"
              @click="go('/seller/upload')"
            >
              上传作品
            </div>
            <div
              :class="url == '/seller/uploads' ? 'item item-active' : 'item'"
              @click="go('/seller/uploads')"
            >
              批量上传
            </div>
            <div
              :class="url == '/seller/manage' ? 'item item-active' : 'item'"
              @click="go('/seller/manage')"
            >
              作品管理
            </div>
            <div
              :class="url == '/seller/order' ? 'item item-active' : 'item'"
              @click="go('/seller/order')"
            >
              销售订单
            </div>
            <div
              :class="url == '/seller/sellerData' ? 'item item-active' : 'item'"
              @click="go('/seller/sellerData')"
            >
              数据中心
            </div>
            <div
              :class="url == '/seller/sellerInfo' ? 'item item-active' : 'item'"
              @click="go('/seller/sellerInfo')"
            >
              基本资料
            </div>
            <div
              :class="url == '/seller/mail' ? 'item item-active' : 'item'"
              @click="go('/seller/mail')"
              class="weidu"
            >
              站内信息
              <div class="red" v-if="unreadnum > 0">{{ unreadnum }}</div>
            </div>
            <div
              :class="url == '/buyer/buyOrder' ? 'item item-active' : 'item'"
              @click="go('/buyer/buyOrder')"
            >
              买家中心
            </div>
          </div>
        </div>

        <div class="right">
          <router-view />
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>


<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import { memberRightData } from "../../request/api";
export default {
  components: {
    top,
    foot,
  },
  data() {
    return {
      arctNum: "",
      fanNum: "",
      head_pic: "",
      nickname: "",
      users_money: "",
      url: "/seller/center",
      unreadnum: "",
    };
  },

  created() {
    this.getData();
  },

  watch: {
    $route(to, from) {
      this.url = this.$route.path;
    },
  },

  methods: {
    getData() {
      memberRightData({
        type: 2,
      }).then((res) => {
        // console.log(res)
        this.arctNum = res.data.arctNum;
        this.fanNum = res.data.fanNum;
        this.head_pic = res.data.userData.head_pic;
        this.nickname = res.data.userData.nickname;
        this.users_money = res.data.userData.users_money;
        this.unreadnum = res.data.unreadnum;
      });
    },
    go(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  min-width: 1280px;
  overflow: hidden;
  margin-bottom: 89px;
  .content {
    width: 1280px;
    margin: 0 auto;
    margin-top: 43px;
    display: flex;
    justify-content: space-between;

    .left {
      .top {
        .user-info {
          width: 340px;
          padding-top: 19px;
          padding-bottom: 30px;

          background: #ffffff;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .uname {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
            padding-top: 11px;
          }

          .balance {
            font-size: 18px;
            font-weight: 400;
            color: #08243c;
            line-height: 18px;
            margin-top: 19px;
          }

          .consum-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 33px;
          }

          .consum-info div:first-child::after {
            content: "";
            width: 1px;
            height: 15px;
            background: #80909e;
            opacity: 0.6;
            display: inline-block;
            margin: 0 26px;
          }

          .recharge {
            width: 292px;
            height: 44px;
            background: #08243c;
            border-radius: 4px;
            margin-top: 31px;

            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            line-height: 44px;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      .bottom {
        width: 340px;
        background: #ffffff;
        border-radius: 6px;
        margin-top: 14px;

        .item {
          border-bottom: 1px solid #f1f4f6;
          padding: 31px 0;
          font-size: 18px;
          font-weight: 400;
          color: #000000;
          line-height: 18px;
          display: flex;
          align-items: center;
          padding-left: 25px;
          position: relative;
          cursor: pointer;
        }

        .item-active::before {
          content: "";
          width: 4px;
          height: 26px;
          background: #e66617;
          display: inline-block;
          position: absolute;
          left: 0;
        }
      }
    }

    .right {
      width: 920px;
    }
  }
}
.weidu {
  position: relative;
  .red {
    position: absolute;
    right: 10px;
    top: 25px;
    width: 30px;
    height: 30px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
  }
}
</style>