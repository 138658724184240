<template>
  <div>
    <div class="bottom">
      <div class="title">销售订单</div>

      <div class="search-box">
        <el-input
          v-model="keyWords"
          placeholder="作品名称编号"
          style="width: 130px"
        ></el-input>

        <div class="time">时间范围</div>
        <div class="block">
          <el-date-picker
            v-model="start_time"
            type="date"
            format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
            style="width: 120px"
            placeholder="开始时间"
          >
          </el-date-picker>
        </div>

        <div class="gang"></div>

        <div class="block">
          <el-date-picker
            v-model="end_time"
            type="date"
            placeholder="结束时间"
            style="width: 120px"
            format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
          >
          </el-date-picker>
        </div>

        <el-select
          v-model="arctype"
          placeholder="请选择"
          style="width: 88px"
        >
          <el-option :label="item.typename" :value="item.id" v-for="(item,index) in cate" :key="index"></el-option>
          
        </el-select>

        <el-select
          v-model="choose"
          placeholder="请选择"
          style="width: 88px"
        >
          <el-option label="正序" value="1"></el-option>
          <el-option label="倒叙" value="1"></el-option>
        </el-select>

        <el-button
          type="warning"
          style="width: 84px; background: #e66617"
          @click="search"
          >搜索</el-button
        >
      </div>

    

      <div class="content" style="margin-top: 39px">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="img" label="作品展示图" align="center">
            <template slot-scope="scope">
              <img
                :src="imageUrl(scope.row.product_litpic)"
                alt=""
                style="width: 54px; height: 54px; display: block; margin: auto"
              />
            </template>
          </el-table-column>
          <el-table-column prop="product_name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="product_id" label="编号" align="center">
          </el-table-column>

          <el-table-column prop="author_order_amount" label="价格" align="center">
          </el-table-column>

          <el-table-column prop="add_time" label="时间" align="center">
          </el-table-column>

          <!-- <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="danger">查看详情</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sellOrder ,topCate} from "../../request/api";
export default {
  data() {
    return {
    
      tableData: [],
      keyWords: "",
      arctype: "",
      choose: "",
      start_time: "",
      end_time: "",
      count: 1,
      size: 4,
      page: 1,
      cate:''
    
    };
  },

  created() {
    this.getData()
    this.getCata()
  },

  methods: {

 //获取顶部分区
    getCata() {
      topCate().then((res) => {
        console.log(res);
        this.cate = res.data;
      });
    },

//


    getData() {
      sellOrder({
      
        keyWords: this.keyWords,
        arctype: this.arctype,
        choose: this.choose,
        start_time: this.start_time,
        end_time: this.end_time,
        page: this.page,
        size: this.size,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.order;
        this.count = res.data.count
      });
    },

    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

   

    search() {
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.bottom {
  background: #ffffff;
  border-radius: 6px;

  padding: 23px 19px 23px 19px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 36px;
    padding-left: 4px;
  }

  .title::after {
    content: "";
    width: 83px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -15px;
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 38px;

    .time {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
    }

    .gang {
      width: 17px;
      height: 2px;
      background: #ebf0f5;
    }
  }
}

/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #08243c;
}

.page-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
</style>