<template>
	<div class="box">
		<top />
		<div class="container">
			<div class="content">
				<div class="warp">
					<div class="left">
						<div class="photo">
							<div class="choose">
								<div class="up" @click="up">
									<i class="el-icon-caret-top" style="color: #b7b7b7"></i>
								</div>
								<div class="choose-box">
									<div class="wrap" :style="{ top: -index * 118.3 + 'px' }">
										<div class="item" v-for="(item, index) in clothingTem" :key="index"
											@click="change(index)">
											<img :src="imageUrl(item.arctype_clothes_img)" alt="" />
										</div>
									</div>
								</div>
								<div class="down" @click="down">
									<i class="el-icon-caret-bottom" style="color: #1a3c59"></i>
								</div>
							</div>
						</div>
						<div class="df">
							<div class="img-box">
								<img class="img1" :src="imageUrl(clothingTem[active].arctype_clothes_img)" alt=""
									v-if="clothingTem.length" />
								<div v-if="fourConsecutive == true" id="img" class="logo" :style="{
                    width: width + 'px',
                    height: height + 'px',
                    top: top + 'px',
                    left: left + 'px',
                    backgroundImage: 'url(' + imgs + ')',
                    backgroundSize: bcSize + 'px',
                  }"></div>
								<img v-if="fourConsecutive == false" id="img" class="logo" :src="imgs" alt="" :style="{
                    width: width + 'px',
                    height: height + 'px',
                    top: top + 'px',
                    left: left + 'px',
                    transform: `scale(${diploid}, ${diploid})`,
                  }" />
								<div class="bj" :style="{ backgroundColor: bc }"></div>
							</div>
							<div class="df_flex">
								<el-progress :text-inside="true" :stroke-width="25" :format="format"
									:percentage="(archivesInfo.sales_num / hot_max) * 100 || 0"
									status="exception"></el-progress>
								<div class="Hot" :style="{
                    left: 50 + `%`,
                  }"> 火爆指数 </div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="uname-box">
							<div class="name">{{ archivesInfo.title }}</div>
							<div class="follow" v-if="follow == 'no'" @click="fllowDo(1)"> 关注+ </div>
							<div class="follow" v-else @click="fllowDo(2)">已关注</div>
						</div>
						<div class="user-info">
							<div class="touxiang" @click="goAuthor(archivesInfo.users_id)">
								<img :src="imageUrl(archivesInfo.head_pic)" alt="" />
							</div>
							<div class="user-content">
								<p>上传者：{{ archivesInfo.author }}</p>
								<p>发布时间：{{ archivesInfo.update_time }}</p>
							</div>
						</div>
						<div class="shop-info">
							<div class="shop-info-item">
								<div style="width: 50%">
									<span style="color: #666666">编号</span><span>{{ archivesInfo.aid }}</span>
								</div>
								<div style="width: 50%">
									<span style="color: #666666">格式</span><span>{{ archivesInfo.format }}</span>
								</div>
							</div>
							<!-- <div class="shop-info-item">
                <div style="width: 50%">
                  <span style="color: #666666">模式</span
                  ><span>{{ archivesInfo.pattern }}</span>
                </div>
                <div style="width: 50%">
                  <span style="color: #666666">尺寸</span
                  ><span>{{ archivesInfo.size }}</span>
                </div>
              </div> -->
						</div>
						<el-divider></el-divider>
						<div class="use-box">
							<div class="no"></div>
							<div class="problem">
								<span @click="protocol('copyrightissues')">版权问题!</span>
								<span @click="protocol('salesproblems')">售后问题</span>
							</div>
						</div>
						<div class="price-box">
							<div class="price">
								<span v-if="level != 1"> 原价<span
										style="color: #888; padding: 0 5px;text-decoration: line-through;">￥{{ archivesInfo.line_price }}</span>
								</span> 价格<span
									style="color: #e66617; padding-left: 18px">￥{{ archivesInfo.users_price }}</span>
							</div>
							<div class="getuser" v-if="level == 1" @click="go('/buyer/vip')"> 点击成为会员即享优惠 </div>
						</div>
						<div class="download-box">
							<div class="upload" v-if="!isShow" @click="getShopInfo"> 下载源文件 </div>
							<div class="upload" v-else @click="getShopInfo">下载中</div>
							<div class="car">
								<img style="widrh: 28px; height: 25px" src="@/assets/car.png" alt="" />
								<span style="padding-left: 17px" @click="joinCartDo"> 加入购物车</span>
							</div>
						</div>
					</div>
				</div>
				<div class="function-box">
					<div class="enlarge">
						<div style="font-size: 180%">
							<i class="el-icon-caret-top" style="cursor: pointer" @click="top -= 5"></i>
							<i class="el-icon-caret-bottom" style="margin-left: 24px; cursor: pointer"
								@click="top += 5"></i>
						</div>
						<div style="font-size: 180%">
							<i class="el-icon-caret-left" style="cursor: pointer" @click="left -= 5"></i>
							<i class="el-icon-caret-right" style="margin-left: 24px; cursor: pointer"
								@click="left += 5"></i>
						</div>
						<div style="font-size: 180%">
							<i class="el-icon-zoom-in" style="cursor: pointer" @click="add"></i>
							<i class="el-icon-zoom-out" style="margin-left: 24px; cursor: pointer" @click="del"></i>
						</div>
					</div>
					<el-button class="keep" style="width: 85px; margin-left: 60px; cursor: pointer" @click="rect">重置
					</el-button>
					<el-button class="keep" style="width: 103px; cursor: pointer; margin-left: 60px"
						@click="show = true">细节图</el-button>
				</div>
			</div>
		</div>
		<div class="title-box">
			<div class="title">作者其他作品</div>
		</div>
		<order :list="authorAbout" />
		<div class="title-box">
			<div class="title">热门设计师</div>
		</div>
		<div style="padding-bottom: 104px">
			<c-swiper :propOption="swipeBoxSpace" :designers="designersData" v-if="designersData"></c-swiper>
		</div>
		<foot />
		<!-- 细节图开始 -->
		<el-dialog :visible.sync="show" width="46%">
			<div class="show">
				<img :src="imageUrl(archivesInfo.litpic_watermark)" alt="" />
			</div>
		</el-dialog>
		<!-- 细节图结束 -->
		<!-- 细节图开始 -->
		<el-dialog title="提示" :visible.sync="merchandise" width="30%" center>
			<el-form label-position="left" label-width="80px">
				<el-form-item label="网盘链接"> {{ panUrl }} </el-form-item>
				<el-form-item label="网盘密码"> {{ panPass }} </el-form-item>
			</el-form>
		</el-dialog>
		<loading ref="loading"></loading>
		<!-- 细节图结束 -->
	</div>
</template>
<script>
	import Loading from "../../components/loading.vue";
	import top from "../../components/common/header.vue";
	import order from "../../components/open-list/open-list.vue";
	import Swiper from "../../components/designer/swiper.vue";
	import SwiperGroup from "../../components/designer/swipe-group.vue";
	import foot from "../../components/common/footer.vue";
	import {
		openAreaDetail,
		fllowDo,
		joinCart,
		sourceDetailorder,
		checkUserOrder,
		checkUserOrders,
		upcolor
	} from "../../request/api";
	export default {
		components: {
			top,
			order,
			"c-swiper": Swiper,
			"c-swiper-group": SwiperGroup,
			foot,
			Loading,
		},
		mounted() {
			this.$loading = this.$refs.loading
		},
		data() {
			return {
				swipeBoxSpace: {
					propId: "swipeBoxSpace",
					height: "280px",
					width: "1280px",
					slidesPerView: 5,
					spaceBetween: 45,
				},
				aid: "",
				archivesInfo: {
					sales_num: 0,
				}, //logo 信息
				authorAbout: "", //作者其他作品
				designersData: "", //热门设计师
				clothingTem: "", //衣服
				follow: "", //是否关注
				active: 0, //衣服选中
				top: 150,
				left: 150,
				width: 100,
				height: 100,
				diploid: 1, //放大倍数
				show: false,
				index: 0, //当前选中标识
				level: "", //用户等级
				merchandise: false, //商品弹框
				panUrl: "",
				panPass: "",
				imgs: null,
				bc: null,
				fourConsecutive: false,
				bcSize: 100,
				downloadUrl: "",
				isShow: false,
				hot_max: "",
			};
		},
		created() {
			this.aid = this.$route.query.aid;
			this.getData();
			this.$loading.show()
		},
		methods: {
			goAuthor(author_id) {
				this.$router.push({
					path: "/author",
					query: {
						author_id: author_id,
					},
				});
			},
			//获取数据
			getData() {
				openAreaDetail({
					aid: this.aid,
				}).then((res) => {
					if (res.code != 200) {
						this.$router.replace({
							path: '/'
						});
						return false;
					}
					this.archivesInfo = res.data.archivesInfo;
					this.authorAbout = res.data.authorAbout;
					this.designersData = res.data.designersData;
					this.clothingTem = res.data.clothingTem;
					this.follow = res.data.follow;
					this.hot_max = res.data.hot_max;
					if (this.archivesInfo.sales_num > this.hot_max) {
						this.archivesInfo.sales_num = this.hot_max;
					}
					this.level = res.data.level;
					let result = res.data.archivesInfo.typeid.split(",");
					if (this.archivesInfo.repeat != undefined) {
						this.top = 0;
						this.left = 0;
						this.width = 425;
						this.height = 444;
						this.fourConsecutive = true;
					}
					this.imgs = this.imageUrl(this.archivesInfo.litpic);
					this.getBodymovinJson();
				});
			},
			ucolor(color){
				upcolor({
					aid: this.aid,
					color:color
				}).then((res) => {})
			},
			up() {
				if (this.index == this.clothingTem.length - 4) {
					return;
				}
				this.index++;
			},
			down() {
				if (this.index == 0) {
					return;
				}
				this.index--;
			},
			add() {
				if (this.fourConsecutive == true) {
					this.bcSize = 100;
					this.diploid += 0.1;
					this.bcSize = this.bcSize * this.diploid;
					this.width = this.width * (this.bcSize / 100);
					this.height = this.height * (this.bcSize / 100);
				} else {
					this.diploid += 0.1;
				}
			},
			del() {
				if (this.fourConsecutive == true) {
					this.bcSize = 100;
					this.diploid -= 0.1;
					this.bcSize = this.bcSize * this.diploid;
					this.width = this.width * (100 / this.bcSize);
					this.height = this.height * (100 / this.bcSize);
				} else {
					this.diploid -= 0.1;
				}
			},
			//关注操作
			fllowDo(type) {
				fllowDo({
					aid: this.archivesInfo.users_id,
					type: type,
				}).then((res) => {
					if (res.code == 200) {
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			change(index) {
				this.active = index;
			},
			//重置
			rect() {
				if (this.fourConsecutive == true) {
					this.diploid = 1;
					this.top = 0;
					this.left = 0;
					this.width = 425;
					this.height = 444;
					this.bcSize = 100;
				} else {
					this.top = 150;
					this.left = 150;
					this.diploid = 1;
				}
			},
			//加入购物车
			joinCartDo() {
				joinCart({
					aid: this.aid,
					type: 1,
				}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			format(percentage) {
				return percentage === 100 ? `${this.archivesInfo.sales_num}` : `${this.archivesInfo.sales_num}`;
			},
			downloadWithBlob(url) {
				this.isShow = true;
				fetch(url).then((res) => res.blob().then((blob) => {
					this.isShow = false;
					this.$loading.hide()
					this.$message({
						message: '下载完成',
						type: "success",
					});
					console.log(blob);
					var a = document.createElement("a");
					var name = url;
					var url = window.URL.createObjectURL(blob);
					var filename = this.archivesInfo.title + this.downloadUrl.substring(this.downloadUrl
						.lastIndexOf("."));
					console.log(filename);
					a.href = url;
					a.download = filename;
					a.click();
					window.URL.revokeObjectURL(url);
				}));
			},
			//查询是否购买
			getShopInfo() {
				var that = this
				this.$loading.show()
				checkUserOrder({
					aid: this.aid,
				}).then((res) => {
					if (res.code == 200) {
						this.panUrl = res.data.panurl;
						this.panPass = res.data.panpwd;
						this.downloadUrl = res.data.raw_litpic;
						if (typeof(res.data.raw_litpic) == "object" || res.data.raw_litpic == "") {
							this.merchandise = true;
						} else {
							this.downloadWithBlob(res.data.raw_litpic);
						}
					}
					if (res.code == 300) {
						this.$confirm(res.msg, {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						}).then(() => {
							checkUserOrders({
								aid: this.aid,
							}).then((res) => {
								if (res.code == 200) {
									this.panUrl = res.data.panurl;
									this.panPass = res.data.panpwd;
									this.downloadUrl = res.data.raw_litpic;
									if (typeof(res.data.raw_litpic) == "object" || res.data
										.raw_litpic == "") {
										this.merchandise = true;
									} else {
										this.$message({
											message: res.msg,
											type: "success",
										});
										this.downloadWithBlob(res.data.raw_litpic);
									}
								}
								if (res.code == 500) {
									this.$message.error(res.msg);
								}
							});
						});
						that.$loading.hide()
					}
					if (res.code == 500) {
						this.upload();
						that.$loading.hide()
					}
				});
			},
			//下载
			upload() {
				this.$confirm("确定购买该素材吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					sourceDetailorder({
						aid: this.aid,
					}).then((res) => {
						if (res.code == 200) {
							this.$message({
								message: res.msg,
								type: "success",
							});
							this.$router.push("/buyer/buyOrder");
						}
						if (res.code == 500) {
							this.$message.error(res.msg);
						}
					});
				});
			},
			//协议
			protocol(type) {
				this.$router.push({
					path: "/clause",
					query: {
						type: type,
					},
				});
			},
			go(url) {
				this.$router.push(url);
			},
			getBodymovinJson() {
				var that = this;
				let image = that.imageUrl(that.archivesInfo.litpic);
				this.getBase64(image, (res) => {
					// console.log(res);
					that.imgs = res;
					setTimeout(function() {
						that.initCanvas();
					}, 500);
				});
			},
			getBase64(url, callback) {
				//通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
				var Img = new Image(),
					dataURL = "";
				Img.src = url + "?v=" + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
				Img.setAttribute("crossOrigin", "Anonymous"); // 解决控制台跨域报错的问题
				Img.onload = function() {
					//要先确保图片完整获取到，这是个异步事件
					var canvas = document.createElement("canvas"), //创建canvas元素
						width = Img.width, //确保canvas的尺寸和图片一样
						height = Img.height;
					canvas.width = width;
					canvas.height = height;
					console.log(Img);
					console.log(width);
					console.log(height);
					canvas.getContext("2d").drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
					dataURL = canvas.toDataURL("image/png"); //转换图片为dataURL
					callback ? callback(dataURL) : null; //调用回调函数
				};
			},
			initCanvas() {
				var that = this;
				var img = document.getElementById("img");
				// var output = document.getElementById('output');
				var canvas = this.draw(img);
				var x = 0;
				var y = 0;
				var color = canvas.getColor(x, y).rgba;
				// document.body.style.background = color;
				console.log(color);
				that.bc = color;
				that.ucolor(color);
				canvas.canvas.remove();
				// output.innerHTML = '坐标点颜色：' + color;
			},
			draw(img) {
				var style = window.getComputedStyle(img);
				var width = parseInt(style.width, 10);
				var height = parseInt(style.height, 10);
				var canvas = document.createElement("canvas");
				canvas.width = width;
				canvas.height = height;
				var ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0);
				document.body.appendChild(canvas);
				// 获取坐标颜色
				function getPixelColor(x, y) {
					var imageData = ctx.getImageData(x, y, 1, 1);
					var pixel = imageData.data;
					console.log(pixel);
					var r = pixel[0];
					var g = pixel[1];
					var b = pixel[2];
					var a = pixel[3] / 255;
					a = Math.round(a * 100) / 100;
					var rHex = r.toString(16);
					r < 16 && (rHex = "0" + rHex);
					var gHex = g.toString(16);
					g < 16 && (gHex = "0" + gHex);
					var bHex = b.toString(16);
					b < 16 && (bHex = "0" + bHex);
					var rgbaColor = "rgba(" + r + "," + g + "," + b + "," + a + ")";
					var rgbColor = "rgb(" + r + "," + g + "," + b + ")";
					var hexColor = "#" + rHex + gHex + bHex;
					return {
						rgba: rgbaColor,
						rgb: rgbColor,
						hex: hexColor,
						r: r,
						g: g,
						b: b,
						a: a,
					};
				}
				return {
					getColor: getPixelColor,
					canvas: canvas,
				};
			},
		},
		watch: {
			$route: {
				// 亲测有效,我是用来监听query参数变化
				handler() {
					this.aid = this.$route.query.aid;
					this.getData();
					//深度监听，同时也可监听到param参数变化
				},
				deep: true,
			},
		},
	};
</script>
<style lang="less" scoped>
	.box {
		background: #f6f6f6;
	}

	.container {
		width: 100%;
		min-width: 1280px;
		overflow: hidden;

		.content {
			width: 1220px;
			margin: 0 auto;
			margin-top: 43px;
			// height: 799px;
			background: #fff;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			// align-items: center;
			padding-top: 86px;
			padding-left: 31px;
			padding-bottom: 93px;
			padding-right: 30px;

			.warp {
				display: flex;
				justify-content: space-between;

				.left {
					display: flex;
					align-items: center;

					.photo {
						.choose {
							width: 102px;

							.up,
							.down {
								width: 102px;
								height: 26px;
								background: rgba(8, 36, 60, 0.06);
								// opacity: 0.06;
								border-radius: 4px;
								text-align: center;
								line-height: 26px;
								cursor: pointer;
							}

							.choose-box {
								height: 472px;
								overflow: hidden;
								position: relative;
								padding-bottom: 16px;

								.wrap {
									position: absolute;

									.item {
										width: 80px;
										height: 80px;
										padding: 10px;
										border: 1px solid #d9d9d9;
										margin-top: 16px;
										cursor: pointer;

										img {
											width: 100%;
											height: 100%;
											display: block;
										}
									}

									// .item:first-child {
									//   margin-top: 0;
									// }
									// .item:last-child{
									//   margin-bottom: 16px;
									// }
								}
							}
						}
					}

					.df {
						display: flex;
						flex-direction: column;
						width: 425px;
						//   height: 444px;
						margin-left: 72px;
					}

					.img-box {
						width: 100%;
						// height: 100%;
						height: 444px;
						position: relative;
						overflow: hidden;
						margin-bottom: 50px;

						.img1 {
							width: 100%;
							height: 100%;
							position: absolute;
							z-index: 2;
						}

						.logo {
							position: absolute;
							// width: 165px;
							// height: 165px;
							object-fit: contain;
							transition: all 0.5s ease;
							z-index: 1;
						}
					}
				}

				.right {
					width: 446px;

					.uname-box {
						display: flex;
						justify-content: space-between;

						.name {
							font-size: 30px;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
						}

						.follow {
							width: 90px;
							height: 35px;
							background: #08243c;
							border-radius: 18px;
							font-size: 18px;
							font-weight: 400;
							color: #ffffff;
							line-height: 35px;
							text-align: center;
							cursor: pointer;
						}
					}

					.user-info {
						display: flex;
						align-items: center;
						padding-top: 31px;

						.touxiang {
							width: 63px;
							height: 63px;
							cursor: pointer;

							img {
								width: 100%;
								height: 100%;
								display: block;
								border-radius: 50%;
							}
						}

						.user-content {
							padding-left: 27px;
							line-height: 25px;

							p:first-child {
								font-size: 18px;
								font-weight: 400;
								color: #000000;
							}

							p:last-child {
								font-size: 14px;
								font-weight: 400;
								color: #000000;
							}
						}
					}

					.shop-info {
						.shop-info-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							font-size: 16px;
							font-weight: 400;
							color: #000000;
							line-height: 25px;
							margin-top: 21px;
						}
					}

					.use-box {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.no {
							font-size: 16px;
							font-weight: 400;
							color: #08243c;
							line-height: 16px;
						}

						.problem {
							font-size: 14px;
							font-weight: 400;
							color: #888888;
							line-height: 14px;
							cursor: pointer;

							span:first-child::after {
								content: "";
								width: 1px;
								height: 13px;
								background: #c6c6c6;
								display: inline-block;
								margin: 0 12px;
							}
						}
					}

					.price-box {
						background: #f1f2f3;
						border-radius: 4px;
						padding: 27px 9px;
						font-size: 16px;
						font-weight: 400;
						color: #000000;
						line-height: 16px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 31px;

						.getuser {
							font-size: 14px;
							font-weight: 400;
							color: #08243c;
							line-height: 14px;
							cursor: pointer;
						}
					}

					.download-box {
						display: flex;
						align-items: center;
						margin-top: 49px;

						.upload {
							width: 160px;
							height: 48px;
							background: #f9f0ea;
							border: 1px solid #e66617;
							border-radius: 4px;
							font-size: 20px;
							font-weight: 400;
							color: #e66617;
							line-height: 50px;
							text-align: center;
							cursor: pointer;
						}

						.car {
							width: 193px;
							height: 50px;
							background: #e66617;
							border-radius: 4px;
							font-size: 20px;
							font-weight: 400;
							color: #ffffff;
							line-height: 50px;
							text-align: center;
							margin-left: 44px;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
						}
					}
				}
			}

			.function-box {
				margin-top: 93px;
				padding-left: 48px;
				display: flex;
				align-items: center;

				.enlarge {
					width: 298px;
					height: 53px;
					border: 1px solid #eaeaea;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}

				.keep {
					width: 126px;
					height: 54px;
					text-align: center;
					font-size: 16px;
					font-weight: 400;
					color: #1a3c59;
				}
			}
		}
	}

	// 细节图
	.show {
		// width: 895px;
		//   height: 761px;
		// background: red;
		padding: 0 69px;

		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.title-box {
		width: 100%;
		min-width: 1280px;

		.title {
			width: 1280px;
			margin: 0 auto;
			padding-top: 56px;
			padding-bottom: 29px;
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			line-height: 24px;
		}
	}

	.pan {
		font-size: 14px;
		font-weight: 400;
		color: #000000;
	}

	.bj {
		z-index: 1111;
		width: 100%;
		height: 100%;
	}

	.el-progress {
		//   width: 300px;
		width: 75%;
	}

	.df_flex {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
	}

	.loader {
		// width: 40%;
		width: 75%;
		height: 25px;
		border-radius: 14px;
		border-bottom: 1px solid #fff;
		border-top: 1px solid #999;
		background: #ccc;
		// overflow: hidden;
		z-index: 5;
		// top: 90.5%;
		// left: 25%;
		position: relative;
	}

	.yellow .progress-bar {
		background: #f56c6c;
	}

	.progress-bar {
		height: inherit;
		width: 0%;
		border-radius: inherit;
		position: relative;
		overflow: hidden;
	}

	.yellow .progress-stripes {
		color: #f56c6c;
	}

	.progress-stripes {
		width: inherit;
		height: inherit;
		font-size: 180px;
		font-weight: bold;
		margin-top: -50px;
		letter-spacing: -14px;
	}

	.yellow .percentage {
		color: #fff;
	}

	.percentage {
		position: absolute;
		top: 4px;
		right: 5px;
		font-weight: bold;
		font-size: 16px;
	}

	.Hot {
		position: absolute;
		top: 50px;
		width: fit-content;
		transform: translateX(-50%);
	}
</style>