<template>
	<div class="page">
		<div class="header-box">
			<div style="
          width: 100%;
          height: 72px;
          background-color: #08243c;
          margin: 0 auto;
        ">
				<div class="header">
					<div class="header-left">
						<div>
							<img src="../../assets/logo1.png" style="width: 117px; height: 39px" />
						</div>
						<div>首页</div>
						<div @click="goOpenArea(item.id)" v-for="(item, index) in topCate" :key="index">
							{{ item.typename }}
						</div>
						<div @click="go('/recruit')">行业资讯</div>
						<div @click="go('/fitting')">试衣间</div>
					</div>
					<div class="header-right">
						<div class="search-box">
							<div class="search">
								<input type="text" style="width: 220px" placeholder="请输入关键词，或截图后按Ctrl+v搜索"
									v-model="search" />
							</div>
							<div class="search-camera">
								<img src="@/assets/camera.png" class="shou" title="以图搜图"
									@click="topShow = topShow ? false : true" />
							</div>
						</div>
						<div class="search-btn shou" @click="searchDo1">
							<img src="@/assets/search.png" />
						</div>
						<el-upload class="upload-demo" drag action="/api/newapi/HomePage/searchImgUpload" multiple
							:show-file-list="false" :on-success="handleRemove" v-if="topShow">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text"> 将文件拖到此处，或<em>点击上传</em>
							</div>
						</el-upload>
						<div class="login" v-if="head_pic">
							<div style="display: flex; align-item: center" @click="go('/buyer/buyOrder')">
								<el-avatar :size="35" :src="imageUrl(head_pic)"></el-avatar>
							</div>
							<span style="padding-left: 10px; cursor: pointer" @click="loginOut">退出</span>
						</div>
						<div class="login" @click="go('login')" v-else>登录</div>
					</div>
				</div>
			</div>
			<div>
				<el-carousel indicator-position="none" :interval="5000" arrow="never">
					<el-carousel-item v-for="(item, index) in banner" :key="index">
						<img :src="imageUrl(item.litpic)" alt="" style="width: 100%; height: 100%" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="search-content">
				<div class="left">
					<el-select class="search-select" v-model="type" placeholder="请选择">
						<el-option :label="item.typename" :value="item.id" v-for="(item, index) in topCate"
							:key="index"></el-option>
					</el-select>
					<el-input style="margin-left: 41px;font-size:24px;" v-model="keysWord"
						placeholder="请输入关键词，或截图后按Ctrl+v搜索">
					</el-input>
				</div>
				<div class="right">
					<div @click="bannerSearch = bannerSearch ? false : true">
						<img src="@/assets/camera1.png" class="shou" alt="" style="display: block" />
					</div>
					<el-button class="btn" type="warning" @click="searchDo">
						<i class="el-icon-search" style="font-size: 33px"></i>
					</el-button>
				</div>
				<el-upload class="upload" drag action="/api/newapi/HomePage/searchImgUpload" multiple
					:show-file-list="false" :on-success="handleRemove" v-if="bannerSearch">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				</el-upload>
			</div>
			<div class="hot-list">
				<div class="hot-item" v-for="(item, index) in tags_list" :key="index"
					@click="gotags(item.url,item.title)">{{item.title}}</div>
			</div>
		</div>
		<div class="Fitting-box">
			<div class="Fitting-content">
				<div class="Fitting-warp" @click="go('/fitting')">
					<img :src="imageUrl(imgA)" alt="" />
				</div>
				<div class="Fitting-warp" @click="go('/buyer/vip')">
					<img :src="imageUrl(imgB)" alt="" />
				</div>
			</div>
		</div>
		<div class="resources-box">
			<div class="resources">
				<div class="title">海量设计资源，超高优惠</div>
				<div class="title-text"> 目前在线 <span style="color: #e66617">{{ onlineData.onlineImgNum }}</span>
					张图片，<span style="color: #e66617">{{ onlineData.designersNum
          }}</span>设计师，昨日新增 <span style="color: #e66617">{{ onlineData.yesterdayArchivesNum
          }}</span> 张 </div>
				<div class="contribution" @click="go('/buyer/vip')">
					<img src="@/assets/contribution.png" alt="" />
					<span>我要优惠！</span>
				</div>
			</div>
			<div class="originality">
				<div class="originality-item" v-for="(item, index) in original_list" :key="index"
					@click="gourl(item.url)">
					<div class="originality-title">{{item.title}}</div>
					<div class="originality-text">{{item.intro}}</div>
					<img :src="imageUrl(item.litpic)" style="object-fit: contain;" alt="" />
				</div>
			</div>
			<div class="special">
				<div class="df">
					<div class="special-warp">
						<div>热门精品</div>
						<div>Special recommendation</div>
					</div>
				</div>
				<div class="special-content">
					<div class="special-item" v-for="(item, index) in recommendType" :key="index"
						@click="searchDo2(item.typename)">
						<img :src="imageUrl(item.litpic)" alt="" />
						<div class="special-text">{{ item.typename }}</div>
					</div>
				</div>
				<div class="all" @click="goallBoutiques">查看更多热门精品</div>
			</div>
			<div class="designer">
				<div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
					<div class="designer-warp">
						<div>新锐设计师</div>
						<div>Cutting edge designers</div>
					</div>
					<div class="designer-warp1">
						<div :class="arrange == true ? 'colorE66617' : 'color333333'" @click="arrangement('two')">
							按上传数量排列 </div>
						<div :class="arrange == false ? 'colorE66617' : 'color333333'" @click="arrangement('one')">
							按销量排列 </div>
					</div>
				</div>
				<div class="hello" style="margin-top: 34px;">
					<!-- <c-swiper
            :propOption="swipeBoxSpace"
            :designers="designers"
            v-if="designersShow"
          ></c-swiper> -->
					<div class="swiper-container-box" >
						<div class="swiper-wrapper">
							<div v-for="(item, index) in designers" :key="index" class="swiper-slide"  v-show="index < 15"
								@click="goDetile(item.users_id)">
								<img class="swiper-slide-container" :src="imageUrl(item.head_pic)" />
								<div class="swiper-slide-text">{{ item.nickname }}</div>
								<div class="swiper-slide-more">
									<span class="text">查看更多</span>
								</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
			<div class="link">
				<div class="df">
					<div class="link-warp">
						<div>友情链接</div>
						<div>Friendship link</div>
					</div>
				</div>
				<div class="link-content">
					<div class="link-item" v-for="(item, index) in link_list" :key="index" @click="gourl(item.url)">
						<img :src="imageUrl(item.litpic)" alt="" />
					</div>
				</div>
			</div>
		</div>
		<foot />
		<div class="suspension">
			<div class="discount" @click="go('/buyer/vip')">
				<div>我要优惠</div>
			</div>
			<div class="bottom">
				<div class="suspension-item" @click="go('/buyer/buycar')">
					<div class="suspension-ico">
						<img src="@/assets/car-ico.png" alt="" />
					</div>
					<div class="suspension-text">购物车</div>
				</div>
				<div class="suspension-item" @click="go('/buyer/myFollow')">
					<div class="suspension-ico">
						<img src="@/assets/follow-ico.png" alt="" />
					</div>
					<div class="suspension-text" @click="go('/buyer/myFollow')"> 我的关注 </div>
				</div>
				<div class="suspension-item" @click="qqShow = true">
					<div class="suspension-ico">
						<img src="@/assets/qq-ico.png" alt="" />
					</div>
					<div class="suspension-text">客服QQ</div>
				</div>
				<div class="suspension-item">
					<div class="suspension-ico">
						<img src="@/assets/home-ico.png" alt="" />
					</div>
					<div class="suspension-text" @click="go('/buyer/buyOrder')"> 我的主页 </div>
				</div>
				<!-- <div class="suspension-item" > -->
				<el-backtop>
					<div class="suspension-ico">
						<img src="@/assets/top-ico.png" alt="" />
					</div>
					<div class="suspension-text">顶部</div>
				</el-backtop>
				<!-- </div> -->
			</div>
		</div>
		<el-dialog :visible.sync="qqShow" width="23%" center>
			<div class="qq-text">
				<span>客服QQ</span>
				<span style="padding-left: 29px">{{ webQq }}</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="qqShow = false" class="qqbtn">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="adShow" show-close width="25%" center>
			<a :href="`http://` + ahref" @click="adShow = false">
				<img style="width: 100%" :src="adimg" alt="" />
			</a>
		</el-dialog>
	</div>
</template>
<script>
	import Swiper from "../../components/designer/swiper.vue";
	import SwiperGroup from "../../components/designer/swipe-group.vue";
	import foot from "../../components/common/footer";
	import {
		IndexData,
		loginOut,
		search,
		designer,
		webConfigAd,
		searchImgUploadBase64
	} from "../../request/api";
	export default {
		components: {
			"c-swiper": Swiper,
			"c-swiper-group": SwiperGroup,
			foot,
		},
		created() {
			this.getIndexData();
			this.getDesigner();
			this.head_pic = localStorage.getItem("head_pic");
			webConfigAd().then((res) => {
				if (res.data.ad_mode.value == "2") {
					this.adShow = true;
					this.adimg = res.data.ad_content.value;
					this.ahref = res.data.ad_url.value;
					this.$forceUpdate();
				}
			});
			document.addEventListener('paste', this.paste)
		},
		destroyed(d) {
			document.removeEventListener('paste', this.paste)
		},
		data() {
			return {
				swipeBoxSpace: {
					propId: "swipeBoxSpace",
					height: "280px",
					width: "1280px",
					slidesPerView: 5,
					spaceBetween: 45,
				},
				banner: "", //轮播图
				topCate: "", //顶部分类
				imgA: "",
				imgB: "",
				onlineData: "", // 海量设计资源
				recommendType: "", //专题推荐
				designers: [], //新锐设计师
				designersShow: false,
				webQq: "", //客服qq
				head_pic: "", //用户头像
				type: "",
				keysWord: "",
				input: "",
				headerActive: 1,
				search: "", //搜索
				qqShow: false,
				topShow: false, //header文件上传
				bannerSearch: false,
				arrange: true,
				adimg: "",
				adShow: false,
				ahref: "",
				original_list: [],
				link_list: [],
				tags_list: []
			};
		},
		methods: {
			//获取首页数据
			getIndexData() {
				IndexData({}).then((res) => {
					console.log(res);
					this.banner = res.data.rotation;
					this.topCate = res.data.topCate;
					this.imgA = res.data.imgA.value;
					this.imgB = res.data.imgB.value;
					this.onlineData = res.data.onlineData;
					this.recommendType = res.data.recommendType;
					this.designersShow = true;
					this.webQq = res.data.webQq;
					this.original_list = res.data.originality_list;
					this.link_list = res.data.link_list;
					this.tags_list = res.data.tags_list;
				});
			},
			paste(event) {
				let image = null;
				const items = (event.clipboardData || window.clipboardData).items;
				if (!items || items.length === 0) return this.$message.error('当前浏览器不支持本地打开图片，请复制粘贴文本内容！');
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf('image') !== -1) {
						image = items[i].getAsFile();
						break;
					}
				}
				if (image) {
					const reader = new FileReader();
					reader.readAsDataURL(image);
					reader.onload = e => {
						searchImgUploadBase64({
							file: e.target.result
						}).then((res) => {
							this.handleRemove();
						})
					};
				}
			},
			// 全部精品
			goallBoutiques() {
				this.$router.push({
					path: "/all/all",
				});
			},
			//公开区跳转
			goOpenArea(id) {
				console.log(id);
				this.$router.push({
					path: `/openArea/${id}`,
				});
			},
			searchDo() {
				this.$router.push({
					path: "/search",
					query: {
						type: this.type,
						keysWord: this.keysWord,
						imgs: 0,
					},
				});
			},
			goDetile(author_id) {
				console.log(12321);
				this.$router.push({
					path: "/author",
					query: {
						author_id: author_id,
					},
				});
			},
			searchDo1() {
				this.$router.push({
					path: "/search",
					query: {
						keysWord: this.search,
						imgs: 0,
					},
				});
			},
			searchDo2(name) {
				this.$router.push({
					path: "/search",
					query: {
						keysWord: name,
						imgs: 0,
					},
				});
			},
			loginOut() {
				loginOut({}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.go(0);
					}
				});
			},
			go(url) {
				this.$router.push(url);
			},
			gourl(url) {
				if (url) {
					window.open(url, '_blank');
				}
			},
			gotags(url, title) {
				if (url) {
					window.open(url, '_blank');
				} else {
					this.$router.push({
						path: "/search",
						query: {
							keysWord: title,
							imgs: 0,
						},
					});
				}
			},
			arrangement(num) {
				this.arrange = !this.arrange;
				this.getDesigner();
			},
			//新锐设计师
			getDesigner() {
				designer({
					order_field: this.arrange == false ? "o_count" : "a_count",
				}).then((res) => {
					// console.log(res);
					this.designers = res.data;
				});
			},
			handleRemove() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;
					this.$router.push({
						url: "",
					});
				}
				this.$router.push({
					path: "/search",
					query: {
						keysWord: "",
						imgs: 1,
					},
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.page {
		background: #f8f8f8;

		.header-box {
			width: 100%;
			min-width: 1280px;
			overflow: hidden;
			height: 822px;
			position: relative;

			.header {
				width: 1480px;
				height: 72px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				z-index: 100;
				left: 50%;
				transform: translate(-50%);

				.header-left {
					width: 700px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 16px;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					cursor: pointer;

					.active {
						font-weight: bold;
					}

					.gongkai::after {
						content: "";
						width: 1px;
						height: 14px;
						background: #efefef;
						display: inline-block;
						margin: 0 9px;
					}
				}

				.header-right {
					display: flex;
					align-items: center;
					position: relative;

					.search-box {
						display: flex;
						align-items: center;
						//   width: 494px;
						height: 36px;
						background: #ffffff;
						border-radius: 6px 0px 0px 6px;
						padding-right: 19px;

						.tu-search-box {
							display: flex;
							align-items: center;

							.tu-search {
								font-size: 14px;
								font-weight: 400;
								color: #333333;
								line-height: 36px;
								padding: 0 18px;
							}
						}

						.tu-search-box::after {
							content: "";
							width: 1px;
							height: 20px;
							background: #cccccc;
							display: inline-block;
						}

						.search {
							input {
								background: none;
								outline: none;
								border: none;
								padding: 0 18px;
								padding-right: 46px;
							}
						}

						.search-camera {
							img {
								display: block;
							}
						}
					}

					.search-btn {
						width: 52px;
						height: 36px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}

					.login {
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						line-height: 36px;
						padding-left: 43px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}
			}

			.search-content {
				width: 1031px;
				height: 82px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.46);
				border-radius: 6px;
				position: absolute;
				z-index: 100;
				top: 382px;
				left: 444px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					display: flex;

					.search-select {
						display: flex;
						align-items: center;
						width: 140px;
						margin-left: 40px;

						/deep/.el-input__inner {
							border: none;
							font-size: 18px;
						}
					}

					.search-select::after {
						content: "";
						width: 1px;
						height: 29px;
						background: #929292;
						display: block;
					}

					/deep/.el-input__inner {
						border: none;
						width: 480px;
					}
				}

				.right {
					display: flex;
					align-items: center;

					.btn {
						width: 136px;
						height: 82px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						margin-left: 46px;
					}
				}

				.upload {
					position: absolute;
					top: 92px;

					/deep/.el-upload-dragger {
						height: 146px;
						width: 1031px;
					}
				}
			}
		}

		.Fitting-box {
			width: 100%;
			min-width: 1280px;
			overflow: hidden;
			padding-top: 71px;
			padding-bottom: 79px;

			.Fitting-content {
				width: 1280px;
				margin: 0 auto;
				display: flex;

				.Fitting-warp {
					cursor: pointer;

					img {
						display: block;
					}
				}
			}

			.Fitting-content .Fitting-warp:last-child {
				margin-left: 32px;
			}
		}

		.resources-box {
			width: 100%;
			min-width: 1280px;
			overflow: hidden;
			padding-bottom: 97px;

			.resources {
				width: 1280px;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;

				.title {
					font-size: 36px;
					font-weight: 400;
					color: #000000;
					// line-height: 130px;
					text-align: center;
					height: 49px;
				}

				.title::after {
					content: "";
					width: 347px;
					height: 1px;
					background: linear-gradient(-90deg, #ffffff, #08243c, #ffffff);
					display: block;
					margin-top: 35px;
				}

				.title-text {
					padding: 34px 0;
					font-size: 20px;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
				}

				.contribution {
					width: 190px;
					height: 56px;
					background: #08243c;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					font-weight: 400;
					color: #ffffff;
					line-height: 56px;
					cursor: pointer;

					img {
						display: block;
						margin-right: 13px;
					}
				}
			}

			.originality {
				padding-top: 55px;
				width: 1280px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.originality-item {
					width: 361px;
					height: 369px;
					background: #f1f2f3;
					border-radius: 15px;
					display: flex;
					flex-direction: column;
					align-items: center;

					.originality-title {
						font-size: 20px;
						font-weight: 400;
						color: #08243c;
						line-height: 20px;
						margin-top: 36px;
					}

					.originality-text {
						font-size: 16px;
						font-weight: 400;
						color: #555555;
						line-height: 16px;
						margin-top: 22px;
					}

					img {
						width: 247px;
						height: 192px;
						display: block;
						margin-top: 49px;
					}
				}
			}

			.special {
				width: 1280px;
				margin: 0 auto;
				margin-top: 80px;

				.special-warp {
					display: flex;
					align-items: center;
				}

				.special-warp div:first-child {
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
				}

				.special-warp div:first-child::after {
					content: "/";
					font-size: 16px;
					font-weight: 400;
					color: #afafaf;
					padding: 0 12px;
				}

				.special-warp div:last-child {
					font-size: 16px;
					font-weight: 400;
					color: #afafaf;
					line-height: 16px;
				}

				.special-content {
					padding-top: 34px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;

					.special-item {
						margin-bottom: 69px;
						width: 280px;
						height: 250px;
						background: #ffffff;
						border-radius: 6px;
						cursor: pointer;

						img {
							width: 280px;
							height: 250px;
							object-fit: cover;
							display: block;
						}

						.special-text {
							font-size: 16px;
							font-weight: 400;
							color: #333333;
							text-align: center;
							line-height: 51px;
						}
					}
				}
			}

			.designer {
				width: 1280px;
				margin: 0 auto;
				margin-top: 20px;
				z-index: -1;

				.designer-warp {
					display: flex;
					align-items: center;
				}

				.designer-warp div:first-child {
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
				}

				.designer-warp div:first-child::after {
					content: "/";
					font-size: 16px;
					font-weight: 400;
					color: #afafaf;
					padding: 0 12px;
				}

				.designer-warp div:last-child {
					font-size: 16px;
					font-weight: 400;
					color: #afafaf;
					line-height: 16px;
				}

				.designer-warp1 {
					display: flex;
					align-items: center;
					cursor: pointer;
				}

				.designer-warp1 div:first-child {
					font-size: 14px;
					font-weight: 400;
					// color: #E66617;
					line-height: 24px;
				}

				.designer-warp1 div:first-child::after {
					content: "|";
					font-size: 11px;
					font-weight: 400;
					color: #888888;
					padding: 0 12px;
				}

				.designer-warp1 div:last-child {
					font-size: 14px;
					font-weight: 400;
					// color: #333333;
					line-height: 16px;
				}

				.colorE66617 {
					color: #e66617;
				}

				.color333333 {
					color: #333333;
				}
			}

			.link {
				width: 1280px;
				margin: 0 auto;
				margin-top: 80px;

				.link-warp {
					display: flex;
					align-items: center;
				}

				.link-warp div:first-child {
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
				}

				.link-warp div:first-child::after {
					content: "/";
					font-size: 16px;
					font-weight: 400;
					color: #afafaf;
					padding: 0 12px;
				}

				.link-warp div:last-child {
					font-size: 16px;
					font-weight: 400;
					color: #afafaf;
					line-height: 16px;
				}

				.link-content {
					padding-top: 34px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.link-item {
						width: 20%;
						box-sizing: border-box;
						text-align: center;
						border: 1px solid #e6e6e5;
						border-left: none;
						height: 120px;
						line-height: 120px;
						background: #fff;
						cursor: pointer;

						img {
							max-height: 100px;
							vertical-align: middle;
							width: auto;
							height: auto;
						}
					}

					.link-item:nth-child(5n+1) {
						border-left: 1px solid #e6e6e5;
					}
				}
			}
		}
	}

	.upload-demo {
		position: absolute;
		z-index: 100;
		top: 36px;

		/deep/.el-upload-dragger {
			width: 322px;
			height: 146px;
		}
	}

	.suspension {
		width: 58px;
		height: 414px;
		// background: red;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		position: fixed;
		z-index: 999;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);

		.discount {
			width: 58px;
			height: 93px;
			background: #08243c;
			border-radius: 4px 4px 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			div {
				width: 17px;
				font-size: 14px;
				font-weight: bold;
				font-style: italic;
				color: #ffffff;
				line-height: 16px;
				text-align: center;
			}
		}

		.bottom {
			// height: 100%;
			display: flex;
			background: #f6f6f6;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			padding-bottom: 27px;

			.suspension-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 22px;
				cursor: pointer;

				.suspension-ico {
					img {
						display: block;
					}
				}

				.suspension-text {
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 12px;
					padding-top: 8px;
				}
			}
		}
	}

	.qq-text {
		font-size: 18px;
		font-weight: 400;
		color: #000000;
		line-height: 36px;
		text-align: center;
	}

	.qqbtn {
		// width: 120px;
		// height: 46px;
		background: #08243c;
		// border-radius: 4px;
		font-size: 18px;
		font-weight: 400;
		color: #ffffff;
		// line-height: 36px;
	}

	/deep/.el-carousel__container {
		height: 750px;
	}

	/deep/ .el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 300px;
		margin: 0;
	}

	/deep/.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	/deep/ .el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}

	/deep/.el-backtop {
		position: static;
		background: none;
		box-shadow: none;
		display: flex;
		flex-direction: column;
		align-items: center;

		.suspension-text {
			font-size: 12px;
			font-weight: 400;
			color: #666666;
			line-height: 12px;
			padding-top: 8px;
		}
	}

	.df {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.all {
		color: #000000;
		font-size: 14px;
		cursor: pointer;
		border: 1px solid #000000;
		width: fit-content;
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
		border-radius: 40px;
		text-align: center;
		font-size: 24px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.swiper-slide {
		background: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		width: 220px;
		margin-right: 45px;
		height: 280px;
		margin-bottom: 45px;
	}
	
	.swiper-slide:nth-child(5n){
		margin-right: 0;
	}

	.swiper-slide-text {
		font-size: 18px;
		font-weight: 400;
		color: #08243c;
		line-height: 18px;
	}

	.swiper-slide-more {
		width: 108px;
		height: 29px;
		border: 1px solid rgba(32, 57, 81, 0.2);
		border-radius: 15px;
		text-align: center;
		/* opacity: 0.2; */
	}

	.text {
		font-size: 14px;
		font-weight: 400;
		color: #e66617;
		line-height: 29px;
		opacity: 0.6;
		text-align: center;
	}

	.swiper-slide-container {
		width: 116px;
		height: 116px;
		border-radius: 50%;
		display: block;
	}

	.swiper-container-out {
		padding: 0 50px;
	}

	.swiper-wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 998;
	}

	.swiper-container {
		z-index: initial;
		position: relative;
	}

	.swiper-container-box {
		width: 100%;
		height: 100%;
		position: relative;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}

	.swiper-container-out .swiper-container-box {
		overflow: hidden;
	}

	.swiper-container>div[class^="swiper-pagination"] {
		position: absolute;
		z-index: 100;
	}

	.file-show-box {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		display: none;
		background: rgba(119, 136, 153, 0.4);
		box-sizing: border-box;
		padding: calc(15% - 1px) calc(25% - 1px);
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next,
	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev,
	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		z-index: 998;
	}

	.swiper-button-prev,
	.navigation-out.swiper-button-prev {
		left: 0;
	}

	.swiper-button-next,
	.navigation-out.swiper-button-next {
		right: 0;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		position: absolute;
		z-index: 998;
	}

	.swiper-button-prev.swiper-button-prev-shadow {
		height: 60px;
		width: 36px;
		background: url("../../static/swiper_button_shadow.png") no-repeat;
		background-position: 0 50%;
	}

	.swiper-button-next.swiper-button-next-shadow {
		height: 60px;
		width: 36px;
		background: url("../../static/swiper_button_shadow.png") no-repeat;
		background-position: -75px 50%;
	}

	/deep/.scroll-middle {
		overflow-x: hidden !important;
	}

	.hot-list {
		width: 1031px;
		position: absolute;
		z-index: 99;
		top: 480px;
		left: 444px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.hot-item {
			margin-right: 15px;
			margin-bottom: 15px;
			padding: 0 19px;
			height: 30px;
			background: rgba(0, 0, 0, .3);
			font-size: 14px;
			color: #fff;
			line-height: 30px;
			border-radius: 30px;
			cursor: pointer;
		}
	}
</style>