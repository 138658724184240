<template>
	<div class="home">
		<div id="demo1"></div>
	</div>
</template>
<script>
	// 引入 wangEditor
	import WangEditor from 'wangeditor'
	export default {
		props: {
			value: {
				type: String,
				default: ""
			},
			isClear: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			isClear(val) {
				// 触发清除文本域内容
				if (val) {
					this.editor.txt.clear();
					this.editorData = null;
				}
			},
			value: function(value) {
				if (value !== this.editor.txt.html()) {
					this.isClear = false;
					this.editor.txt.html(this.value); //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
				}
			}
		},
		data() {
			return {
				editor: null,
				editorData: ''
			}
		},
		mounted() {
			const editor = new WangEditor('#demo1')
			// 配置 onchange 回调函数，将数据同步到 vue 中
			editor.config.onchange = (newHtml) => {
				this.editorData = newHtml
				this.$emit('change', {
					val: this.editorData
				})
			}
			// 创建编辑器
			editor.create()
			// 初始化
			editor.txt.html(this.value)
			this.editor = editor
		},
		methods: {
			getEditorData() {
				// 通过代码获取编辑器内容
				let data = this.editor.txt.html()
				alert(data)
			}
		},
		beforeDestroy() {
			// 调用销毁 API 对当前编辑器实例进行销毁
			this.editor.destroy()
			this.editor = null
		}
	}
</script>
<style>
	.w-e-toolbar,
	.w-e-text-container,
	.w-e-menu-panel {
		background-color: transparent !important;
	}
</style>