<template>
	<div class="page">
		<div class="header-box">
			<top />
			<div class="block">
				<div id="edit-box" class="edit-box">
					<div class="sub-title">标题</div>
					<input class="input" type="text" v-model="ask_title" placeholder="请输入标题" />
					<div class="sub-title">封面图</div>
					<div class="images">
						<div class="add-img">
							<el-upload style="width: 100%;height: 100%;" drag
								action="/api/newapi/HomePage/searchImgUpload" multiple :show-file-list="false"
								:on-success="addimg">
								<span v-if="thumb == ''">+</span>
								<div class="img" v-else>
									<img :src="thumb" alt="" />
								</div>
							</el-upload>
						</div>
					</div>
					<div class="sub-title">内容</div>
					<wangEditor v-model="wangEditorDetail" :isClear="isClear" :placeholder="`请输入正文`"
						@change="wangEditorChange">
					</wangEditor>
					<button class="message-btn btn-blue" @click="add">发布</button>
				</div>
			</div>
		</div>
		<foot />
		<el-dialog :visible.sync="adShow" show-close width="25%" center>
			<a :href="`http://` + ahref" @click="adShow = false">
				<img style="width: 100%" :src="adimg" alt="" />
			</a>
		</el-dialog>
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer";
	import wangEditor from "../../components/wangEditor.vue"
	import {
		IndexData,
		loginOut,
		search,
		webConfigAd,
		askadd
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			wangEditor,
		},
		created() {
			this.getIndexData();
			this.head_pic = localStorage.getItem("head_pic");
			webConfigAd().then((res) => {
				if (res.data.ad_mode.value == "2") {
					// this.adShow = true;
					this.adimg = res.data.ad_content.value;
					this.ahref = res.data.ad_url.value;
					this.$forceUpdate();
				}
			});
		},
		data() {
			return {
				topCate: "", //顶部分类
				head_pic: "", //用户头像
				keysWord: "",
				search: "", //搜索
				topShow: false, //header文件上传
				adimg: "",
				adShow: false,
				ahref: "",
				wangEditorDetail: "",
				isClear: false,
				ask_title: '',
				thumb: ''
			};
		},
		methods: {
			//获取首页数据
			getIndexData() {
				IndexData({}).then((res) => {
					console.log(res);
					this.topCate = res.data.topCate;
				});
			},
			add() {
				var that = this
				if (this.ask_title == '') {
					that.$message({
						message: '请输入标题',
						type: "error",
					});
					return false
				}
				if (this.wangEditorDetail == '') {
					that.$message({
						message: '请输入内容',
						type: "error",
					});
					return false
				}
				askadd({
					ask_title: this.ask_title,
					content: this.wangEditorDetail,
					thumb: this.thumb
				}).then((res) => {
					if(res.code==200){
						that.$message({
							message: "发布成功",
							type: "success",
						});
						this.$router.push({
							path: `/recruit/`,
						});
					}else{
						that.$message({
							message: res.msg,
							type: "error",
						});
					}
				});
			},
			//公开区跳转
			goOpenArea(id) {
				console.log(id);
				this.$router.push({
					path: `/openArea/${id}`,
				});
			},
			searchDo1() {
				this.$router.push({
					path: "/search",
					query: {
						keysWord: this.search,
						imgs: 0,
					},
				});
			},
			loginOut() {
				loginOut({}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.go(0);
					}
				});
			},
			go(url) {
				this.$router.push(url);
			},
			addimg(e) {
				console.log(e)
				this.thumb = e.data.img
			},
			handleRemove() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;
					this.$router.push({
						url: "",
					});
				}
				this.$router.push({
					path: "/search",
					query: {
						keysWord: "",
						imgs: 1,
					},
				});
			},
			wangEditorChange(val) {
				console.log(val);
				this.wangEditorDetail = val.val
			},
		},
	};
</script>
<style lang="less" scoped>
	.page {
		background: #f8f8f8;

		.header-box {
			width: 100%;
			min-width: 1280px;
			overflow: hidden;
			position: relative;

			.header {
				width: 1480px;
				height: 72px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				z-index: 100;
				left: 50%;
				transform: translate(-50%);

				.header-left {
					width: 700px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 16px;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					cursor: pointer;

					.active {
						font-weight: bold;
					}

					.gongkai::after {
						content: "";
						width: 1px;
						height: 14px;
						background: #efefef;
						display: inline-block;
						margin: 0 9px;
					}
				}

				.header-right {
					display: flex;
					align-items: center;
					position: relative;

					.search-box {
						display: flex;
						align-items: center;
						//   width: 494px;
						height: 36px;
						background: #ffffff;
						border-radius: 6px 0px 0px 6px;
						padding-right: 19px;

						.tu-search-box {
							display: flex;
							align-items: center;

							.tu-search {
								font-size: 14px;
								font-weight: 400;
								color: #333333;
								line-height: 36px;
								padding: 0 18px;
							}
						}

						.tu-search-box::after {
							content: "";
							width: 1px;
							height: 20px;
							background: #cccccc;
							display: inline-block;
						}

						.search {
							input {
								background: none;
								outline: none;
								border: none;
								padding: 0 18px;
								padding-right: 46px;
							}
						}

						.search-camera {
							img {
								display: block;
							}
						}
					}

					.search-btn {
						width: 52px;
						height: 36px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}

					.login {
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						line-height: 36px;
						padding-left: 43px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}
			}

			.search-content {
				width: 1031px;
				height: 82px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.46);
				border-radius: 6px;
				position: absolute;
				z-index: 100;
				top: 382px;
				left: 444px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					display: flex;

					.search-select {
						display: flex;
						align-items: center;
						width: 140px;
						margin-left: 40px;

						/deep/.el-input__inner {
							border: none;
						}
					}

					.search-select::after {
						content: "";
						width: 1px;
						height: 29px;
						background: #929292;
						display: block;
					}

					/deep/.el-input__inner {
						border: none;
						width: 355px;
					}
				}

				.right {
					display: flex;
					align-items: center;

					.btn {
						width: 136px;
						height: 82px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						margin-left: 46px;
					}
				}

				.upload {
					position: absolute;
					top: 92px;

					/deep/.el-upload-dragger {
						height: 146px;
						width: 1031px;
					}
				}
			}
		}

		.block {
			width: 750px;
			min-height: 680px;
			margin: 0 auto;
			padding-top: 30px;

			.input {
				width: 100%;
				height: 40px;
				line-height: 40px;
				padding: 0 10px;
				background-color: transparent;
				border: 1px solid #c9d8db;
				outline: none;
			}

			.images {
				display: flex;
				flex-wrap: wrap;

				.add-img {
					width: 120px;
					height: 120px;
					text-align: center;
					line-height: 120px;
					font-size: 100px;
					color: #999;
					font-weight: 200;
					cursor: pointer;

					/deep/.el-upload-dragger {
						width: 120px;
						height: 120px;
					}

					.img {
						width: 120px;
						height: 120px;
					}

					img {
						width: 100%;
						height: 100%;
						display: block;
						object-fit: contain;
					}
				}
			}
		}

		.edit-box {
			width: 750px;
			margin: 0 auto;
			padding-bottom: 100px;
		}

		.sub-title {
			padding: 38px 0 20px;
			font-size: 14px;
			color: #333;
		}

		.message-btn {
			margin-top: 20px;
			width: 150px;
			height: 54px;
			border-radius: 4px;
		}

		.btn-blue {
			background: #317ADA !important;
			border: 1px solid #317ADA !important;
			color: #fff !important;
		}
	}

	.upload-demo {
		position: absolute;
		z-index: 100;
		top: 36px;

		/deep/.el-upload-dragger {
			width: 322px;
			height: 146px;
		}
	}
</style>