<template>
	<div class="page">
		<div class="header-box">
			<div class="header">
				<div class="header-left">
					<div>
						<img src="../../assets/logo1.png" style="width: 117px; height: 39px" />
					</div>
					<div @click="go('/')">首页</div>
					<div v-for="(item, index) in cate" :key="index" @click="goOpenArea(item.id)">
						{{ item.typename }}
					</div>
					<div @click="go('/recruit')">行业资讯</div>
					<div @click="go('/fitting')">试衣间</div>
				</div>
				<div class="header-right">
					<div class="search-box">
						<!-- <div class="tu-search-box">
              <div class="tu-search"  >以图搜图</div>
            </div> -->
						<div class="search">
							<input style="width: 220px" placeholder="请输入关键词，或截图后按Ctrl+v搜索" v-model="keysWord" />
						</div>
						<div class="shou search-camera">
							<img class="shou" src="@/assets/camera.png" title="以图搜图"
								@click="topShow = topShow ? false : true" />
						</div>
					</div>
					<div class="search-btn " @click="searchDo">
						<img class="shou" src="@/assets/search.png" />
					</div>
					<el-upload class="upload-demo" drag action="/api/newapi/HomePage/searchImgUpload"
						:show-file-list="false" :on-success="handleRemove" multiple v-if="topShow">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text"> 将文件拖到此处，或<em>点击上传</em>
						</div>
					</el-upload>
					<div class="login" v-if="pic">
						<div style="display: flex; align-item: center" @click="go('/buyer/buyOrder')">
							<el-avatar :size="35" :src="imageUrl(pic)"></el-avatar>
						</div>
						<span class="loginOut" @click="loginOut">退出</span>
					</div>
					<div class="login" @click="go('/login')" v-else>登录</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		loginOut,
		searchImgUpload,
		searchImgUploadBase64,
		topCate
	} from "../../request/api";
	export default {
		data() {
			return {
				keysWord: "",
				pic: "",
				cate: "",
				headerActive: 1,
				search: "", //搜索
				topShow: false, //header文件上传
				value: "",
			};
		},
		created() {
			this.pic = localStorage.getItem("head_pic");
			this.getCata();
			document.addEventListener('paste', this.paste)
		},
		destroyed(d) {
			document.removeEventListener('paste', this.paste)
		},
		methods: {
			//获取顶部分区
			getCata() {
				topCate().then((res) => {
					this.cate = res.data;
				});
			},
			//
			goOpenArea(id) {
				this.$router.push({
					path: `/openArea/${id}`,
				});
				this.$router.go(0);
			},
			//退出登录
			loginOut() {
				loginOut({}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.go(0);
					}
				});
			},
			paste(event) {
				let image = null;
				const items = (event.clipboardData || window.clipboardData).items;
				if (!items || items.length === 0) return this.$message.error('当前浏览器不支持本地打开图片，请复制粘贴文本内容！');
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf('image') !== -1) {
						image = items[i].getAsFile();
						break;
					}
				}
				if (image) {
					const reader = new FileReader();
					reader.readAsDataURL(image);
					reader.onload = e => {
						searchImgUploadBase64({
							file: e.target.result
						}).then((res) => {
							this.handleRemove();
						})
					};
				}
			},
			//搜索
			searchDo() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;
					this.$router.push({
						url: "",
					});
				}
				this.$router.push({
					path: "/search",
					query: {
						keysWord: this.keysWord,
						imgs: 0,
					},
				});
			},
			go(url) {
				this.$router.push(url);
			},
			handleRemove() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;
					this.$router.push({
						url: "",
					});
				}
				this.$router.push({
					path: "/search",
					query: {
						keysWord: "",
						imgs: 1,
					},
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.header-box {
		width: 100%;
		min-width: 1280px;
		// overflow: hidden;
		height: 70px;
		position: relative;
		background: #08243c;

		.header {
			width: 1280px;
			height: 72px;
			margin: 0 auto;
			//   background: red;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			z-index: 100;
			left: 50%;
			transform: translate(-50%);

			.header-left {
				width: 700px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				font-size: 16px;
				font-weight: 400;
				color: #ffffff;
				line-height: 36px;
				cursor: pointer;

				.active {
					font-weight: bold;
				}

				.gongkai::after {
					content: "";
					width: 1px;
					height: 14px;
					background: #efefef;
					display: inline-block;
					margin: 0 9px;
				}
			}

			.header-right {
				display: flex;
				align-items: center;
				position: relative;

				.search-box {
					display: flex;
					align-items: center;
					//   width: 494px;
					height: 36px;
					background: #ffffff;
					border-radius: 6px 0px 0px 6px;
					padding-right: 19px;

					.tu-search-box {
						display: flex;
						align-items: center;

						.tu-search {
							font-size: 14px;
							font-weight: 400;
							color: #333333;
							line-height: 36px;
							padding: 0 18px;
						}
					}

					.tu-search-box::after {
						content: "";
						width: 1px;
						height: 20px;
						background: #cccccc;
						display: inline-block;
					}

					.search {
						input {
							background: none;
							outline: none;
							border: none;
							padding: 0 18px;
							padding-right: 46px;
						}
					}

					.search-camera {
						img {
							display: block;
						}
					}
				}

				.search-btn {
					width: 52px;
					height: 36px;
					background: #e66617;
					border-radius: 0px 6px 6px 0px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.login {
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					padding-left: 43px;
					cursor: pointer;
					display: flex;
					align-items: center;

					.loginOut {
						padding-left: 10px;
					}
				}
			}
		}

		.search-content {
			width: 1031px;
			height: 82px;
			background: #ffffff;
			box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.46);
			border-radius: 6px;
			position: absolute;
			z-index: 100;
			top: 382px;
			left: 444px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;

				.search-select {
					display: flex;
					align-items: center;
					width: 140px;
					margin-left: 40px;

					/deep/.el-input__inner {
						border: none;
					}
				}

				.search-select::after {
					content: "";
					width: 1px;
					height: 29px;
					background: #929292;
					display: block;
				}

				/deep/.el-input__inner {
					border: none;
					width: 355px;
				}
			}

			.right {
				display: flex;
				align-items: center;

				.btn {
					width: 136px;
					height: 82px;
					background: #e66617;
					border-radius: 0px 6px 6px 0px;
					margin-left: 46px;
				}
			}

			.upload {
				position: absolute;
				top: 92px;

				/deep/.el-upload-dragger {
					height: 146px;
					width: 1031px;
				}
			}
		}
	}

	.upload-demo {
		position: absolute;
		z-index: 100;
		top: 36px;

		/deep/.el-upload-dragger {
			width: 322px;
			height: 146px;
		}
	}
</style>