<template>
	<div>
		<div class="top" v-show="userInfo">
			<div class="title">我的卡券</div>
			<div class="card">
				<div v-for="(item,index) in userInfo" :key="index">
					<div :class="item.level == 2?'vip':item.level == 3?'svip':item.level == 5?'month':'season'"
						v-if="item.level != 4">
						<div class="box">
							<div class="vip-name">{{ item.type_name }}</div>
							<div class="vip-text">有效期：{{item.start_time}}-{{item.end_time}}</div>
						</div>
					</div>
					<div class="original" v-if="item.level == 4">
						<div class="box">
							<div class="vip-name">{{ item.type_name }}</div>
							<div class="vip-text">有效期：{{item.start_time}}-{{item.end_time}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bottom">
			<div class="title">会员计划</div>
			<div v-for="(item,index) in vipInfo" :key="index">
				<div class="vip">
					<div class="name">{{ item.type_name }}计划</div>
					<div class="text">开通年卡即享折扣会员</div>
					<div class="table">
						<div class="item">
							<div>折扣卡</div>
							<div>{{item.discount}}折</div>
						</div>
						<div class="item">
							<div>会员费</div>
							<div>{{ item.price }}元</div>
						</div>
						<div class="item">
							<div>有效期</div>
							<div>{{ item.level_limit.maturity_days }}天</div>
						</div>
						<div class="item">
							<div>开通条件</div>
							<div class="note">(非押金，余额可随时消费)</div>
							<div>余额大于0</div>
						</div>
						<div class="item">
							<div>会员特权</div>
							<div>{{ item.activity }}</div>
						</div>
					</div>

					<div class="handle" @click="handle(item.level_id)">办理会员</div>
				</div>

				<div class="xian" v-if="index != 4"></div>
			</div>
		</div>

	<!-- vip弹框 -->

	<el-dialog title="提示" :visible.sync="vipShow" width="25%" center>
		<el-divider></el-divider>
		<div class="govip">确定要办理会员吗？</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="buy" style="background: #09253d; border: none; width: 120px">确 定
			</el-button>
			<el-button @click="vipShow = false" style="
            background: #c92323;
            color: #ffffff;
            border: none;
            width: 120px;
            margin-left: 94px;
          ">取 消</el-button>
		</span>
	</el-dialog>

	<!-- svip弹框 -->
	<el-dialog title="提示" :visible.sync="svipShow" width="25%" center>
	  <el-divider></el-divider>
	  <div class="govip">确定要办理超级会员吗？</div>
	  <span slot="footer" class="dialog-footer">
	    <el-button
	      type="primary"
	      @click="buy"
	      style="background: #09253d; border: none; width: 120px"
	      >确 定</el-button
	    >
	    <el-button
	      @click="svipShow = false"
	      style="
	        background: #c92323;
	        color: #ffffff;
	        border: none;
	        width: 120px;
	        margin-left: 94px;
	      "
	      >取 消</el-button
	    >
	  </span>
	</el-dialog>
	
	<!-- 原创会员弹框 -->
	<el-dialog title="提示" :visible.sync="originalShow" width="25%" center>
	  <el-divider></el-divider>
	  <div class="govip">确定要办理原创会员吗？</div>
	  <span slot="footer" class="dialog-footer">
	    <el-button
	      type="primary"
	      @click="buy"
	      style="background: #09253d; border: none; width: 120px"
	      >确 定</el-button
	    >
	    <el-button
	      @click="originalShow = false"
	      style="
	        background: #c92323;
	        color: #ffffff;
	        border: none;
	        width: 120px;
	        margin-left: 94px;
	      "
	      >取 消</el-button
	    >
	  </span>
	</el-dialog>
	
	<!-- 月卡会员弹框 -->
	<el-dialog title="提示" :visible.sync="monthShow" width="25%" center>
	  <el-divider></el-divider>
	  <div class="govip">确定要办理月卡会员吗？</div>
	  <span slot="footer" class="dialog-footer">
	    <el-button
	      type="primary"
	      @click="buy"
	      style="background: #09253d; border: none; width: 120px"
	      >确 定</el-button
	    >
	    <el-button
	      @click="monthShow = false"
	      style="
	        background: #c92323;
	        color: #ffffff;
	        border: none;
	        width: 120px;
	        margin-left: 94px;
	      "
	      >取 消</el-button
	    >
	  </span>
	</el-dialog>
	
	<!-- 季卡会员弹框 -->
	<el-dialog title="提示" :visible.sync="seasonShow" width="25%" center>
	  <el-divider></el-divider>
	  <div class="govip">确定要办理季卡会员吗？</div>
	  <span slot="footer" class="dialog-footer">
	    <el-button
	      type="primary"
	      @click="buy"
	      style="background: #09253d; border: none; width: 120px"
	      >确 定</el-button
	    >
	    <el-button
	      @click="seasonShow = false"
	      style="
	        background: #c92323;
	        color: #ffffff;
	        border: none;
	        width: 120px;
	        margin-left: 94px;
	      "
	      >取 消</el-button
	    >
	  </span>
	</el-dialog>
	</div>
</template>

<script>
	import {
		userVipInfo,
		rechargeCard
	} from "../../request/api";
	export default {
		data() {
			return {
				vipInfo: "", //vip 信息
				svipInfo: "", //svip 信息
				userInfo: '', //用户会员信息
				vipShow: false,
				svipShow: false,
				seasonShow: false,
				monthShow: false,
				originalShow: false,
				type_id: "",
			};
		},

		created() {
			this.getUserVipInfo();
		},
		methods: {
			//获取用户会员信息
			getUserVipInfo() {
				userVipInfo({}).then((res) => {
					console.log(res);
					// this.vipInfo = res.data.vipInfo
					// this.svipInfo = res.data.svipInfo
					this.userInfo = res.data.user_levels
					this.vipInfo = res.data.vip_levels


				});
			},

			handle(level_id) {
				switch (level_id) {
					case 2:
						this.vipShow = !this.vipShow;
						this.type_id = 1
						break;
					case 3:
						this.svipShow = !this.svipShow;
						this.type_id = 2
						break;
					case 4:
						this.originalShow = !this.originalShow;
						this.type_id = 4
						break;
					case 5:
						this.monthShow = !this.monthShow;
						this.type_id = 5
						break;
					case 6:
						this.seasonShow = !this.seasonShow;
						this.type_id = 6
						break;
				}
			},

			buy() {

				console.log(this.type_id)
				// return
				rechargeCard({
					type_id: this.type_id,
					pay_id: 0,
				}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});

						this.vipShow = false;
						this.svipShow = false;
						this.originalShow = false;
						this.monthShow = false;
						this.seasonShow = false;
						this.getUserVipInfo();
					}

					if (res.code == 500) {
						this.$message.error(res.data.ReturnMsg)
						this.vipShow = false;
						this.svipShow = false;
						this.originalShow = false;
						this.monthShow = false;
						this.seasonShow = false;
					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.top {
		background: #ffffff;
		border-radius: 6px;
		padding: 23px 23px 30px;
		margin-bottom: 14px;

		.title {
			font-size: 18px;
			font-weight: 400;
			color: #08243c;
			line-height: 18px;
		}

		.card {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 9px;
			padding: 0 100px;

			.vip {
				width: 310px;
				height: 130px;
				background: url("../../assets/vip.png") no-repeat;
				position: relative;

				.box {
					width: 290px;
					height: 118px;
					border: 1px solid #dfc58b;
					border-radius: 6px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.vip-name {
						font-size: 34px;
						font-weight: bold;
						font-style: italic;
						color: #08243c;
						line-height: 36px;
						background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-top: 19px;
						margin-left: 31px;
					}

					.vip-text {
						font-size: 14px;
						font-weight: 400;
						color: #b49961;
						line-height: 14px;
						margin-left: 31px;
						margin-top: 35px;
					}
				}
			}

			.svip {
				width: 310px;
				height: 130px;
				background: url("../../assets/svip.png") no-repeat;
				position: relative;

				.box {
					width: 290px;
					height: 118px;

					border: 1px solid #285880;
					border-radius: 6px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.vip-name {
						font-size: 34px;
						font-weight: bold;
						font-style: italic;
						color: #08243c;
						line-height: 34px;

						background: linear-gradient(-90deg, #1e4565 0%, #427cac 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-top: 19px;
						margin-left: 31px;
					}

					.vip-text {
						font-size: 14px;
						font-weight: 400;
						color: #28455d;
						line-height: 14px;
						margin-left: 31px;
						margin-top: 35px;
					}
				}
			}

			.month {
				width: 310px;
				height: 130px;
				background: url("../../assets/month.png") no-repeat;
				position: relative;

				.box {
					width: 290px;
					height: 118px;
					border: 1px solid #dfc58b;
					border-radius: 6px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.vip-name {
						font-size: 34px;
						font-weight: bold;
						font-style: italic;
						color: #08243c;
						line-height: 36px;
						background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-top: 19px;
						margin-left: 31px;
					}

					.vip-text {
						font-size: 14px;
						font-weight: 400;
						color: #b49961;
						line-height: 14px;
						margin-left: 31px;
						margin-top: 35px;
					}
				}
			}

			.original {
				width: 310px;
				height: 130px;
				background: url("../../assets/original.png") no-repeat;
				position: relative;

				.box {
					width: 290px;
					height: 118px;
					border: 1px solid #dfc58b;
					border-radius: 6px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.vip-name {
						font-size: 34px;
						font-weight: bold;
						font-style: italic;
						color: #08243c;
						line-height: 36px;
						background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-top: 19px;
						margin-left: 31px;
					}

					.vip-text {
						font-size: 14px;
						font-weight: 400;
						color: #b49961;
						line-height: 14px;
						margin-left: 31px;
						margin-top: 35px;
					}
				}
			}

			.season {
				width: 310px;
				height: 130px;
				background: url("../../assets/season.png") no-repeat;
				position: relative;

				.box {
					width: 290px;
					height: 118px;
					border: 1px solid #dfc58b;
					border-radius: 6px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.vip-name {
						font-size: 34px;
						font-weight: bold;
						font-style: italic;
						color: #08243c;
						line-height: 36px;
						background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-top: 19px;
						margin-left: 31px;
					}

					.vip-text {
						font-size: 14px;
						font-weight: 400;
						color: #b49961;
						line-height: 14px;
						margin-left: 31px;
						margin-top: 35px;
					}
				}
			}
		}
	}

	.bottom {
		background: #ffffff;
		border-radius: 6px;

		padding: 34px 23px 58px 23px;

		.title {
			font-size: 20px;
			font-weight: 400;
			color: #08243c;
			line-height: 36px;
			padding-left: 4px;
		}

		.title::after {
			content: "";
			width: 83px;
			height: 9px;
			background: #e66617;
			display: block;
			margin-top: -15px;
		}

		.vip {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 60px;

			.name {
				font-size: 22px;
				font-weight: 400;
				color: #000000;
				line-height: 22px;
			}

			.name::after {
				content: "";
				width: 139px;
				height: 1px;
				background: linear-gradient(-90deg, #ffffff, #08243c, #ffffff);
				display: block;
				margin-top: 19px;
			}

			.text {
				font-size: 16px;
				font-weight: 400;
				color: #08243c;
				line-height: 16px;
				padding-top: 18px;
			}

			.table {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin-top: 43px;

				.item {
					font-size: 16px;
					font-weight: 400;

					color: #666666;
					line-height: 36px;
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					position: relative;
				}

				.item div:last-child {
					margin-top: 59px;
					color: #000000;
				}

				.note {
					width: 200px;
					position: absolute;
					top: 25px;

					color: #08243c;
				}
			}

			.handle {
				width: 140px;
				height: 40px;
				background: #08243c;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 400;
				color: #ffffff;
				line-height: 40px;
				text-align: center;
				cursor: pointer;
				margin-top: 67px;
			}
		}

		.xian {
			height: 1px;
			background: #08243c;
			opacity: 0.04;
			margin-top: 50px;
		}
	}

	.govip {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		line-height: 18px;
		text-align: center;
		margin-top: 35px;
	}

	/deep/.el-dialog__header {
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #09253d;
		line-height: 24px;
	}

	/deep/.el-dialog__title {
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #09253d;
		line-height: 24px;
	}

	/deep/.el-divider {
		margin: 0;
	}
</style>
