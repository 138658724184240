export default {  
    install(Vue,options)  
    {  
      Vue.prototype.imgUrl =  'https://admin.yituhuitu.com/'
      
      Vue.prototype.getTitle = {  
        title:'',  
        isBack: true,  
        isAdd:  false,  
      };  
      Vue.prototype.showFootTab = {  
        isShow:false,  
        active:0,  
      }  
    }  
  }