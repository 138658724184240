<template>
  <div>
    <div class="bottom">
      <div class="top">
        <div class="title">账户充值</div>
        <div class="detail" @click="go('/seller/withdrawalDetail')">
          提现明细>>
        </div>
      </div>

      <div class="money">
        <div class="left">
          <div class="title">提现金额</div>
          <div class="price">
            ￥<el-input
              v-model="money"
              style="width: 250px"
              placeholder="请输入提现金额"
            ></el-input>
          </div>
          <div class="surplus">
            <span>可提现余额：{{ userMoney }}元</span>

            <span @click="getMoney">申请提现</span>
          </div>
        </div>

        <el-divider direction="vertical"></el-divider>
        <div class="right">
          <div class="title">已提现金额</div>
          <div class="num">￥{{ withdrawal }}</div>
        </div>
      </div>

      <div class="note">
        <div class="common" v-html="withdrawalagreement"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { withdrawal, commitWithdrawal } from "../../request/api";
export default {
  data() {
    return {
      userMoney: "", //用户可提现余额
      withdrawal: "", //用户已提现金额
      withdrawalagreement: "", //提现协议

      money: "", //提现金额
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      withdrawal().then((res) => {
        console.log(res);
        this.userMoney = res.data.userMoney;
        this.withdrawal = res.data.withdrawal;
        this.withdrawalagreement = this.showHtml(res.data.withdrawalagreement);
      });
    },

    //提现
    getMoney() {
      commitWithdrawal({
        money: this.money,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          this.getData()
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
    go(url) {
      console.log(111);
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.bottom {
  background: #ffffff;
  border-radius: 6px;
  margin-top: 14px;
  padding: 34px 23px 35px 23px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 400;
      color: #08243c;
      line-height: 20px;
    }

    .title::after {
      content: "";
      width: 100px;
      height: 9px;
      background: #e66617;
      display: block;
      margin-top: -8px;
    }

    .detail {
      font-size: 16px;
      font-weight: 400;
      color: #08243c;
      line-height: 16px;
      cursor: pointer;
    }
  }

  .money {
    display: flex;

    justify-content: space-evenly;
    margin-top: 24px;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #08243c;
        line-height: 20px;
      }

      .price {
        font-size: 32px;
        font-weight: 400;
        color: #08243c;
        line-height: 32px;
        margin-top: 33px;
      }

      .surplus {
        margin-top: 31px;
        span:first-child {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
        }

        span:last-child {
          font-size: 16px;
          font-weight: 400;
          color: #e66617;
          line-height: 16px;
          padding-left: 24px;
          cursor: pointer;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #08243c;
        line-height: 20px;
      }

      .num {
        font-size: 32px;
        font-weight: 400;
        color: #08243c;
        line-height: 32px;
        padding-top: 32px;
      }
    }
  }

  .note {
    margin-top: 60px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #08243c;
      line-height: 16px;
    }

    .common {
      font-size: 14px;
      font-weight: 400;
      color: #444444;
      line-height: 36px;
    }
  }
}

/deep/.el-divider--horizontal {
  margin-top: 31px;
  margin-bottom: 36px;
}

/deep/.el-divider,
.el-divider--vertical {
  height: 69px;
}

/deep/.el-input__inner {
  border: none;
  font-size: 26px;
}
</style>