<template>
  <div>
    <div class="top">
      <div class="title">我的关注</div>

      <div class="content">
 
     
        <div class="item" v-for="(item, index) in list" :key="index">
          <el-avatar :size="66" :src="imageUrl(item.head_pic)"></el-avatar>
          <div class="name">{{ item.nickname }}</div>
          <div class="more" @click="go(item.follow_id)">查看更多</div>
        </div>
      </div>

         <el-empty description="暂无关注" v-if="!list.length"></el-empty>

      <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myFollowList } from "../../request/api";

export default {
  data() {
    return {
      list: "",
      count: 1,
      size: 8,
      page: 1,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      myFollowList({
        page: this.page,
        size: this.size,
      }).then((res) => {
        console.log(res.data.followList);
        this.list = res.data.followList;
        this.count = res.data.count;
      });
    },

    go(id) {
      this.$router.push({
        path: "/author",
        query: {
          author_id: id,
        },
      });
    },

       //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  padding: 34px 23px 68px 23px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 20px;
  }

  .title::after {
    content: "";
    width: 100px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -8px;
  }

  .content {
    margin-top: 31px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .item {
      width: 200px;
      height: 245px;
      background: #ffffff;
      border: 1px solid #ebf0f5;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: 22px;

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #08243c;
        line-height: 16px;
      }

      .more {
        width: 108px;
        height: 29px;
        border: 1px solid rgba(32, 57, 81, 0.2);
        border-radius: 15px;
        text-align: center;
        line-height: 29px;
        font-size: 14px;
        font-weight: 400;
        color: #e66617;
        opacity: 0.6;
      }
    }

    .item:nth-child(4n-3) {
      margin-left: 0;
    }

    .item:nth-child(n + 5) {
      margin-top: 25px;
    }
  }
}

.page-box {
  overflow: hidden;
  .pages {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
</style>