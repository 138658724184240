<template>
	<div class="page">
		<div class="header-box">
			<top />
			<div class="block">
				<h1 class="title">{{detail.ask_title}}</h1>
				<div class="info-box flex">
					<img :src="imageUrl(detail.head_pic)" alt="">
					<span class="name">{{detail.nickname}}</span>
					<span>阅读 {{detail.click}}</span> <span>回复 {{detail.replies}}</span>
					<span>时间 {{detail.add_time}}</span>
				</div>
				<div class="cont">
					<div v-html="detail.content"></div>
				</div>
				<div class="message-view">
					<div class="message-h">留言</div>
					<div class="message-box">
						<div class="message flex sb" v-for="(item,index) in apply_list">
							<img :src="imageUrl(item.head_pic)" class="avatar">
							<div class="message-content">
								<div class="m-top flex sb">
									<div class="name">{{item.nickname}}</div>
									<div class="m-info">
										<div class="flex">
											<img v-if="item.is_like == 0" @click="clicklike(item.answer_id,index)"
												src="../../static/nolike.png" alt="">
											<img @click="clicklike(item.answer_id,index)" v-else
												src="../../static/like.png" alt="">
											<span class="like-num">{{item.click_like}}</span>
											<span>{{item.add_time}}</span>
										</div>
									</div>
								</div>
								<div class="m-text">
									<div v-html="item.content"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="page-box">
						<div class="pages">
							<el-pagination @current-change="handleCurrentChange" :page-size="size"
								layout="total, prev, pager, next ,jumper" :total="count" :current-page="page">
							</el-pagination>
						</div>
					</div>
				</div>
				<div style="border-top: 1px solid #eee;"></div>
				<div id="edit-box" class="edit-box">
					<div class="sub-title">评论</div>
					<wangEditor v-model="wangEditorDetail" :isClear="isClear" :placeholder="`请输入正文`"
						@change="wangEditorChange">
					</wangEditor>
					<button class="message-btn btn-blue" @click="askapplyadd">发布</button>
				</div>
			</div>
		</div>
		<foot />
		<el-dialog :visible.sync="adShow" show-close width="25%" center>
			<a :href="`http://` + ahref" @click="adShow = false">
				<img style="width: 100%" :src="adimg" alt="" />
			</a>
		</el-dialog>
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer";
	import wangEditor from "../../components/wangEditor.vue"
	import {
		IndexData,
		loginOut,
		search,
		webConfigAd,
		askdetail,
		askapply_list,
		askapply_add,
		askapply_prize,
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			wangEditor,
		},
		created() {
			console.log(this.$route.query)
			this.alkid = this.$route.query.id
			this.getIndexData();
			this.getaskdetail()
			this.getaskapply_list()
			this.head_pic = localStorage.getItem("head_pic");
			webConfigAd().then((res) => {
				if (res.data.ad_mode.value == "2") {
					// this.adShow = true;
					this.adimg = res.data.ad_content.value;
					this.ahref = res.data.ad_url.value;
					this.$forceUpdate();
				}
			});
		},
		data() {
			return {
				topCate: "", //顶部分类
				head_pic: "", //用户头像
				keysWord: "",
				search: "", //搜索
				topShow: false, //header文件上传
				adimg: "",
				adShow: false,
				ahref: "",
				wangEditorDetail: "",
				alkid: '',
				detail: '',
				page: 1,
				apply_list: '',
				count: 1,
				size: 20,
				page: 1,
				isClear: false
			};
		},
		methods: {
			getaskdetail() {
				askdetail({
					id: this.alkid
				}).then((res) => {
					console.log(res);
					this.detail = res.data;
				});
			},
			getaskapply_list() {
				askapply_list({
					ask_id: this.alkid,
					page: this.page
				}).then((res) => {
					console.log(res);
					this.apply_list = res.data.list;
					this.count = res.data.count
				});
			},
			askapplyadd() {
				var that = this
				var userInfo = localStorage.getItem("head_pic");
				if(!userInfo){
					this.$message({
						message: '请登录账号发布',
						type: "error",
					});
					return;
				}
				if (this.wangEditorDetail == '') {
					that.$message({
						message: '请输入内容',
						type: "error",
					});
					return false
				}
				askapply_add({
					ask_id: that.alkid,
					content: that.wangEditorDetail
				}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						that.$message({
							message: "发布成功",
							type: "success",
						});
						that.wangEditorDetail = ''
						that.isClear = true
						that.detail.replies++
						that.page = 1
						that.getaskapply_list()
					} else {
						that.$message({
							message: res.msg,
							type: "error",
						});
					}
				});
			},
			handleCurrentChange(val) {
				console.log(val);
				this.page = val;
				this.getaskapply_list()
			},
			clicklike(id, index) {
				askapply_prize({
					id: id
				}).then((res) => {
					if (this.apply_list[index].is_like == 0) {
						this.apply_list[index].is_like = 1
						this.apply_list[index].click_like++
					} else {
						this.apply_list[index].is_like = 0
						this.apply_list[index].click_like--
					}
				});
			},
			//获取首页数据
			getIndexData() {
				IndexData({}).then((res) => {
					console.log(res);
					this.topCate = res.data.topCate;
				});
			},
			//公开区跳转
			goOpenArea(id) {
				console.log(id);
				this.$router.push({
					path: `/openArea/${id}`,
				});
			},
			searchDo1() {
				this.$router.push({
					path: "/search",
					query: {
						keysWord: this.search,
						imgs: 0,
					},
				});
			},
			loginOut() {
				loginOut({}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.go(0);
					}
				});
			},
			go(url) {
				this.$router.push(url);
			},
			handleRemove() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;
					this.$router.push({
						url: "",
					});
				}
				this.$router.push({
					path: "/search",
					query: {
						keysWord: "",
						imgs: 1,
					},
				});
			},
			wangEditorChange(val) {
				// console.log(val)
				this.wangEditorDetail = val.val
			},
		},
	};
</script>
<style lang="less" scoped>
	.page {
		background: #f8f8f8;

		.header-box {
			width: 100%;
			min-width: 1280px;
			overflow: hidden;
			position: relative;

			.header {
				width: 1480px;
				height: 72px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				z-index: 100;
				left: 50%;
				transform: translate(-50%);

				.header-left {
					width: 700px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 16px;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					cursor: pointer;

					.active {
						font-weight: bold;
					}

					.gongkai::after {
						content: "";
						width: 1px;
						height: 14px;
						background: #efefef;
						display: inline-block;
						margin: 0 9px;
					}
				}

				.header-right {
					display: flex;
					align-items: center;
					position: relative;

					.search-box {
						display: flex;
						align-items: center;
						//   width: 494px;
						height: 36px;
						background: #ffffff;
						border-radius: 6px 0px 0px 6px;
						padding-right: 19px;

						.tu-search-box {
							display: flex;
							align-items: center;

							.tu-search {
								font-size: 14px;
								font-weight: 400;
								color: #333333;
								line-height: 36px;
								padding: 0 18px;
							}
						}

						.tu-search-box::after {
							content: "";
							width: 1px;
							height: 20px;
							background: #cccccc;
							display: inline-block;
						}

						.search {
							input {
								background: none;
								outline: none;
								border: none;
								padding: 0 18px;
								padding-right: 46px;
							}
						}

						.search-camera {
							img {
								display: block;
							}
						}
					}

					.search-btn {
						width: 52px;
						height: 36px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}

					.login {
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						line-height: 36px;
						padding-left: 43px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}
			}

			.search-content {
				width: 1031px;
				height: 82px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.46);
				border-radius: 6px;
				position: absolute;
				z-index: 100;
				top: 382px;
				left: 444px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					display: flex;

					.search-select {
						display: flex;
						align-items: center;
						width: 140px;
						margin-left: 40px;

						/deep/.el-input__inner {
							border: none;
						}
					}

					.search-select::after {
						content: "";
						width: 1px;
						height: 29px;
						background: #929292;
						display: block;
					}

					/deep/.el-input__inner {
						border: none;
						width: 355px;
					}
				}

				.right {
					display: flex;
					align-items: center;

					.btn {
						width: 136px;
						height: 82px;
						background: #e66617;
						border-radius: 0px 6px 6px 0px;
						margin-left: 46px;
					}
				}

				.upload {
					position: absolute;
					top: 92px;

					/deep/.el-upload-dragger {
						height: 146px;
						width: 1031px;
					}
				}
			}
		}

		.block {
			width: 750px;
			min-height: 680px;
			margin: 0 auto;
			padding-top: 50px;

			.title {
				font-size: 32px;
				line-height: 1.8;
				color: #333;
				font-weight: bold;
			}

			.info-box {
				margin: 20px 0;
				font-size: 14px;
				color: #999999;
				display: flex;
				align-items: center;

				img {
					width: 30px;
					height: 30px;
					border-radius: 30px;
					margin-right: 20px;
				}

				span {
					margin-right: 20px;
				}

				span.name {
					font-weight: bold;
				}
			}

			.message-view {
				margin-top: 60px;

				.message-h {
					padding: 38px 0 20px;
					font-size: 14px;
					color: #333;
				}

				.message-box {
					margin-bottom: 50px;

					.message {
						display: flex;
						display: -webkit-flex;
						align-items: flex-start;
						justify-content: space-between;
						-webkit-justify-content: space-between;
						margin-bottom: 30px;

						.avatar {
							width: 30px;
							height: 30px;
						}

						.message-content {
							width: 700px;

							.m-top {
								margin-bottom: 10px;
								justify-content: space-between;
								-webkit-justify-content: space-between;
								display: flex;
								display: -webkit-flex;
								align-items: center;
								-webkit-align-items: center;
							}

							.name {
								font-size: 14px;
								color: #999;
								line-height: 30px;
							}

							.m-info {
								font-size: 14px;
								color: #999;

								.flex {
									display: flex;
									display: -webkit-flex;
									align-items: center;
									-webkit-align-items: center;

									img {
										width: 20px;
										height: 20px;
									}

									span.like-num {
										color: #317ADA;
										margin: 0 20px 0 5px;
									}
								}
							}

							.m-text {
								font-size: 14px;
								color: #333;
								line-height: 1.5;
							}
						}
					}
				}
			}
		}

		.edit-box {
			width: 750px;
			margin: 0 auto;
			padding-bottom: 100px;
		}

		.sub-title {
			padding: 38px 0 20px;
			font-size: 14px;
			color: #333;
		}

		.message-btn {
			margin-top: 20px;
			width: 150px;
			height: 54px;
			border-radius: 4px;
		}

		.btn-blue {
			background: #317ADA !important;
			border: 1px solid #317ADA !important;
			color: #fff !important;
		}
	}

	.upload-demo {
		position: absolute;
		z-index: 100;
		top: 36px;

		/deep/.el-upload-dragger {
			width: 322px;
			height: 146px;
		}
	}

	.page-box {
		width: 100%;
		overflow: hidden;

		.pages {
			width: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 30px;
			padding-bottom: 60px;
		}
	}

	/deep/.el-pager li {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		line-height: 32px;
	}

	/deep/.el-pager li.active {
		width: 32px;
		height: 32px;
		background: #08243c;
		border-radius: 50%;
	}

	/deep/.el-pager li.active {
		color: #ffffff;
	}

	/deep/.el-dialog,
	/deep/.el-pager li {
		background: none;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		background: none;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 16px;
		line-height: 32px;
	}
</style>