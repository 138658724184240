<template>
  <div class="page">
    <div class="container">
      <div class="left"></div>

      <div class="right">
        <div class="title">验证码登录</div>
        <div class="item">
          <el-input placeholder="请输入手机号" v-model="tel">
            <!-- <i slot="prefix" class="el-input__icon el-icon-phone-outline"></i> -->
            <img
              class="ico"
              slot="prefix"
              style="width: 16px; height: 26px"
              src="@/assets/tel.png"
              alt=""
            />
          </el-input>
        </div>

        <div class="item">
          <el-input placeholder="请输入验证码" v-model.number="code">
            <img
              class="ico"
              slot="prefix"
              style="width: 20px; height: 22px"
              src="@/assets/pass.png"
              alt=""
            />
            <span class="second" slot="suffix" @click="getCode">
              {{ start ? num + "s" : "点击获取验证码" }}
            </span>
          </el-input>
        </div>

        <div class="item">
          <el-checkbox v-model="checked" class="agreement"
            >阅读并同意
            <span @click="protocol('loginterms')">《条款协议》</span>
          </el-checkbox>
        </div>

        <div class="item" style="text-align: right">
          <el-button class="login" @click="login"
            >登录<i
              class="el-icon-right"
              style="padding-left: 13px; color: #fff"
            ></i
          ></el-button>
        </div>

        <el-divider><span class="sanfang">第三方登录</span></el-divider>

        <div class="wx" @click="show">
          <img src="@/assets/wx.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendCode, login, getWx, wxCodeLogin } from "@/request/api.js";
export default {
  data() {
    return {
      tel: "",
      code: "",
      start: false,
      num: 59,
      checked: false,
      wxCode: "",
      incode: "",
      fromPath: "",
    };
  },

  created() {
    this.getWxCode();
    if (this.$route.query.invitation_code) {
      this.incode = this.$route.query.invitation_code;
    }
    console.log(window.location.host);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from, "from");

      vm.fromPath = from.path; //获取上一级路由的路径
    });
  },

  methods: {
    //获取验证码
    getCode() {
      if (this.start) {
        return;
      }
      sendCode({
        tel: this.tel,
      }).then((res) => {
        if (res.code == 200) {
          this.start = !this.start;
          var t = this.num;
          var time = setInterval(() => {
            this.num = t--;
            if (this.num == 0) {
              clearInterval(time);
              this.start = !this.start;
              this.num = 60;
            }
          }, 1000);
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },

    //微信ewm
    show() {
      if (!this.checked) {
        this.$message.error("请勾选条款协议");
        return;
      }

      getWx({
        url: window.location.host + this.fromPath,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          window.location.href = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    go() {
      this.$router.push(url);
    },

    login() {
      console.log(1111);

      if (this.tel == "" || this.tel == null) {
        this.$message.error("请输入手机号");
        return;
      }

      if (this.code == "" || this.code == null) {
        this.$message.error("请输入验证码");
        return;
      }

      if (!this.checked) {
        this.$message.error("请勾选条款协议");
        return;
      }

      console.log(this.checked);

      login({
        tel: this.tel,
        code: this.code,
        cheched: this.checked,
        invitation_code: this.incode,
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("nickname", res.data.nickname);
          this.$router.go(-1);
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },

    protocol(type) {
      this.$router.push({
        path: "/clause",
        query: {
          type: type,
        },
      });
    },

    getWxCode() {
      if (!this.$route.query.code) {
        return;
      }

      this.wxCode = this.$route.query.code;

      wxCodeLogin({
        code: this.wxCode,
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("nickname", res.data.nickname);
          this.$router.go(-3);
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
          let path = this.$route.path; //先获取路由路径
          this.$router.push(path);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  background: url("../../assets/loginBack.png") no-repeat;

  .container {
    width: 1050px;
    height: 593px;
    background: #ffffff;
    box-shadow: 0px 11px 23px 1px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;

    .left {
      width: 530px;
      height: 100%;
      background: url("../../assets/login.jpg") no-repeat;
      background-size: cover;
    }

    .right {
      width: 360px;
      //   height: 100%;
      //   background: red;
      padding: 47px 80px 29px 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .title {
        font-size: 24px;
        font-weight: 400;
        color: #08243c;
        line-height: 24px;
      }

      .item {
        .agreement {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          line-height: 36px;
        }

        .login {
          width: 133px;
          height: 48px;
          background: #08243c;
          border-radius: 24px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .sanfang {
        font-size: 12px;
        font-weight: 400;
        color: #888888;
        line-height: 12px;
      }

      .wx {
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .title-box {
    font-size: 24px;
    font-weight: 400;
    color: #08243c;
    line-height: 24px;
    text-align: center;
  }

  .title-box::after {
    content: "";
    width: 206px;
    height: 1px;
    background: linear-gradient(-90deg, #ffffff, #08243c, #ffffff);
    display: block;
    margin: 25px auto;
  }

  .content {
    padding: 40px;
    font-size: 18px;
    font-weight: 400;
    color: #08243c;
    line-height: 36px;
  }
}

.ico {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

/deep/.el-input__inner {
  border: none;
  border-bottom: 1px solid #08243c;
  border-radius: 0;
}

/deep/.el-input__suffix {
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.second {
  font-size: 14px;
  font-weight: 400;
  color: #e66617;
  line-height: 14px;
  cursor: pointer;
}
</style>