<template>
  <div>
    <div class="top">
      <div class="top-item">
        <div class="num">{{arctCheckNum}}</div>
        <div class="text">已发布</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{arctNoCheckNum}}</div>
        <div class="text">待审核</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{sellNum}}</div>
        <div class="text">售出作品</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{income}}</div>
        <div class="text">出售收入</div>
      </div>
    </div>

    <div class="bottom">
      <div class="title">个人中心</div>
      <div class="content" style="margin-top: 39px">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="img" label="作品展示图" align="center">
            <template slot-scope="scope">
              <img
                :src="imageUrl(scope.row.litpic)"
                alt=""
                style="width: 54px; height: 54px; display: block; margin: auto;object-fit: cover;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="title" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="id" label="编号" align="center">
          </el-table-column>

          <el-table-column prop="users_price" label="价格" align="center">
          </el-table-column>

          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.arcrank == '-1'">正在审核</span>       
              <span  v-if="scope.row.arcrank == '-2'">驳回</span>
              <span  v-if="scope.row.arcrank == '0'">审核通过</span>
            </template>
          </el-table-column>

          <el-table-column prop="add_time" label="时间" align="center" width="180px">
          </el-table-column>
        </el-table>
      </div>

        <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { memberTopData ,userArticleList} from "../../request/api";
export default {
  data() {
    return {
      arctCheckNum: "",//已发布
      arctNoCheckNum: "",//待审核
      income: "",//出售收入
      sellNum: "",//售出作品

      tableData: [
        
        
       
      ],
      count: 1,
      size: 4,
      page: 1,
    };
  },

  created() {
    this.getTopData()
    this.getData()
  },
  methods: {
    getTopData() {
      memberTopData({
        type: 2,
      }).then((res) => {
        this.arctCheckNum = res.data.arctCheckNum
        this.arctNoCheckNum = res.data.arctNoCheckNum
        this.income = res.data.income
        this.sellNum = res.data.sellNum
      })
    },

    getData(){
      userArticleList({
         page: this.page,
        size: this.size,
      }).then((res)=>{
        console.log(res)
       
        this.tableData =res.data.list
         this.count = res.data.count;
      })

    },

      //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 35px 0px 38px 0px;

  .top-item {
    display: flex;

    align-items: center;
    flex-direction: column;

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #08243c;
      line-height: 24px;
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      padding-top: 20px;
    }
  }

  .shu {
    width: 1px;
    height: 37px;
    background: #d7dadd;
  }
}

.bottom {
  background: #ffffff;
  border-radius: 6px;
  margin-top: 14px;
  padding: 23px 19px 23px 19px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 36px;
    padding-left: 4px;
  }

  .title::after {
    content: "";
    width: 83px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -15px;
  }
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}


.page-box {
  overflow: hidden;
  .pages {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
</style>