<template>
  <div>
    <div class="bottom">
      <div class="title">基本资料</div>

      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="#08243C"
      >
        <el-menu-item index="1">账户信息</el-menu-item>
        <el-menu-item index="2">基本信息</el-menu-item>
        <el-menu-item index="3">设置绑定</el-menu-item>
        <el-menu-item index="4">实名认证</el-menu-item>
      </el-menu>

      <!-- 账户信息 -->
      <div class="content1" v-if="activeIndex == 1">
        <el-form label-position="left" label-width="80px">
          <div class="notice">
            账户信息：您未设置支付宝提现账号，在下方输入相应的信息修改账户资料
          </div>
          <el-form-item label="收款账号">
            <el-input v-model="accountnum" style="width: 269px"></el-input>
          </el-form-item>
          <el-form-item label="收款人">
            <el-input v-model="accountmem" style="width: 269px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-upload
              drag
              class="avatar-uploader"
              action="/api/newapi/Archives/articleImgUpload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="alicode" :src="imageUrl(alicode)" class="avatar" />
              <i
                v-else
                class="el-icon-plus el-icon-circle-plus"
                style="font-size: 47px; color: #7c8b98"
              ></i>
              <div class="upload-text" v-if="!alicode">上传支付宝收款码</div>
            </el-upload>

            <div class="hedui">请核对支付宝收款码是否正确</div>
          </el-form-item>
        </el-form>
        <div class="tijiao" @click="addCollectionInforDo">保存</div>
      </div>

      <!-- 基本信息 -->
      <div class="content2" v-if="activeIndex == 2">
        <el-form :inline="true" class="demo-form-inline" label-width="70px">
          <el-form-item label="头像" style="display: flex; align-item: center">
            <div class="touxiang">
              <el-avatar :size="52" :src="imageUrl(head_pic)"></el-avatar>
              <div class="text">
                <el-upload
                  class="avatar-uploader"
                  action="/api/newapi/Archives/articleImgUpload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                >
                  <i id="upload">修改头像</i>
                </el-upload>
              </div>
            </div>
          </el-form-item>
        </el-form>

        <el-form :inline="true" class="demo-form-inline" label-width="70px">
          <el-form-item label="昵称">
            <el-input
              v-model="username"
              placeholder="设计师"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名" style="margin-left: 131px">
            <el-input v-model="nickname" placeholder="设计师"></el-input>
          </el-form-item>
        </el-form>

        <el-form :inline="true" class="demo-form-inline" label-width="70px">
          <el-form-item label="邮箱">
            <el-input v-model="email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="QQ" style="margin-left: 131px">
            <el-input v-model="qq" placeholder="请输入QQ"></el-input>
          </el-form-item>
        </el-form>

        <el-form :inline="true" class="demo-form-inline" label-width="70px">
          <el-form-item label="微信号">
            <el-input v-model="weixin" placeholder="请输入微信号"></el-input>
          </el-form-item>
          <el-form-item label="公司名称" style="margin-left: 131px">
            <el-input v-model="address" placeholder="请输入公司名称"></el-input>
          </el-form-item>
        </el-form>
		
		<el-form :inline="true" class="demo-form-inline" label-width="70px">
		  <el-form-item label="热门标签" style="width: 100%;">
		    <el-input v-model="tags" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" resize="none" placeholder="请输入热门标签，多个标签用英文逗号隔开"></el-input>
		  </el-form-item>
		</el-form>

        <div class="tijiao" @click="editUserInfoDo">保存</div>
      </div>

      <!-- 设置绑定 -->
      <div class="content3" v-if="activeIndex == 3">
        <el-form label-width="100px">
          <el-form-item label="已绑定手机号" v-if="phone">
            <el-input style="width: 227px" v-model="phone" disabled></el-input>
          </el-form-item>

          <el-form-item label="手机号" v-else>
            <el-input style="width: 227px" v-model="phones"></el-input>
          </el-form-item>

          <el-form-item label="验证码" v-if="!phone">
            <div style="display: flex; align-item: center">
              <el-input style="width: 227px" v-model="code"></el-input>

              <div class="huoqu" @click="getPhoneCode">
                {{ start ? num + "s" : "点击获取验证码" }}
              </div>
            </div>
          </el-form-item>

          <div class="tijiao" @click="bindingPhoneOpDo" v-if="!phone">
            确定绑定
          </div>
        </el-form>

        <el-divider content-position="center">
          <span class="sanfang">第三方登录</span>
        </el-divider>
        <div class="wx">
          <el-tooltip
            class="item"
            effect="dark"
            content="已绑定微信"
            placement="top-start"
            v-if="isBindingWx == 'yes'"
          >
            <img
              src="@/assets/wx1.png"
              alt=""
              style="width: 42px; height: 42px"
            />
          </el-tooltip>

          <img
            src="@/assets/wx.png"
            alt=""
            style="width: 42px; height: 42px"
            @click="show"
            v-if="isBindingWx == 'no'"
          />
        </div>
      </div>

      <!-- 实名认证 -->
      <div class="content4" v-if="activeIndex == 4">
        <el-form label-position="right" label-width="80px">
          <el-form-item label="姓名">
            <el-input
              v-model="real_name"
              style="width: 227px; margin-left: 50px"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input
              v-model="id_number"
              style="width: 227px; margin-left: 50px"
              placeholder="请输入身份证号码"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-checkbox-group v-model="checked">
              <el-checkbox>
                <span class="text1">授权同意签署</span>
                <span class="text2" @click="protocol('exclusivesigning')"
                  >《独家签约协议》</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>

        <div class="tijiao" @click="approve">提交审核</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  realName,
  collectionInfor,
  addCollectionInfor,
  usersInfo,
  editUserInfo,
  realNameInfo,
  bangWx,
  bindingWx,
  bindingPhone,
  bindingPhoneOp,
} from "@/request/api.js";
export default {
  data() {
    return {
      //--------账户信息
      activeIndex: "1", //menu 默认显示
      accountnum: "", //收款账号
      accountmem: "", //收款人
      alicode: "", //支付宝二维码

      // 基本信息 ----------------------------
      username: "",
      nickname: "",
      head_pic: "",
      email: "",
      qq: "",
      weixin: "",
      address: "",

      // ------------------------------
      phone: "",

      id_number: "",
      real_name: "",

      //----------
      alicode: "", //收款码

      wxCode: "", //code

      name: "",
      region: "",
      type: "",

      type: 1,

      real_name: "", //姓名--实名认证
      id_number: "", //身份证号
      checked: false,
      isBindingWx: "",

      start: false,
      num: 5,
      code: "",
      phones: "",
      flag: false,
	  
	  tags:""
    };
  },

  created() {
    if (localStorage.getItem("tabbar")) {
      this.activeIndex = localStorage.getItem("tabbar");
    }

    this.getCollectionInfor();
    this.getUsersInfo();
    this.geTRealNameInfo();
    this.getWxCode();
  },

  methods: {
    //获取用户账户信息
    getCollectionInfor() {
      collectionInfor({}).then((res) => {
        console.log("111", res);
        if (res.code == 200) {
          this.accountmem = res.data.accountmem;
          this.accountnum = res.data.accountnum;
          this.alicode = res.data.alicode;
          this.flag = true;
        }
      });
    },

    //修改账号信息
    addCollectionInforDo() {
      if (this.accountmem == "" || this.accountmem == null) {
        this.$message.error("请输入收款人");
        return;
      }

      if (this.accountnum == "" || this.accountnum == null) {
        this.$message.error("请输入收款账号");
        return;
      }

      //    if(this.accountnum.length != 11){
      //     this.$message.error("请输入正确收款账号");
      //     return;
      //  }

      if (this.alicode == "" || this.alicode == null) {
        this.$message.error("请上传支付宝二维码");
        return;
      }

      addCollectionInfor({
        accountnum: this.accountnum,
        accountmem: this.accountmem,
        alicode: this.alicode,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },

    //实名认证操作
    approve() {
      if (this.real_name == "" || this.real_name == null) {
        this.$message.error("请输入姓名");
        return;
      }

      if (this.id_number == "" || this.id_number == null) {
        this.$message.error("请输入身份证号");
        return;
      }

      if (!this.checked) {
        this.$message.error("请同意独家签约协议");
        return;
      }

      realName({
        real_name: this.real_name,
        id_number: this.id_number,
        checked: this.checked,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },

    //获取用户基本信息
    getUsersInfo() {
      usersInfo().then((res) => {
        console.log(res);
        this.username = res.data.username;
        this.nickname = res.data.nickname;
        this.head_pic = res.data.head_pic;
        this.email = res.data.email;
        this.qq = res.data.qq;
        this.weixin = res.data.weixin;
        this.address = res.data.address;
        this.phone = res.data.phone;
        this.isBindingWx = res.data.isBindingWx;
        this.real_name = res.data.real_name;
        this.id_number = res.data.id_number;
		this.tags = res.data.tags;
      });
    },
    //修改用户基本信息
    editUserInfoDo() {
      editUserInfo({
        username: this.username,
        nickname: this.nickname,
        head_pic: this.head_pic,
        email: this.email,
        qq: this.qq,
        weixin: this.weixin,
        address: this.address,
		tags:this.tags
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getCollectionInfor();
          this.getUsersInfo();
          // this.geTRealNameInfo();
          this.getWxCode();
        } else {
          this.$message.error(ress.msg);
        }
      });
    },

    //用户实名认证回填

    geTRealNameInfo() {
      if (this.id_number != "" && this.real_name != "") {
        realNameInfo().then((res) => {
          this.id_number = res.data.id_number;
          this.real_name = res.data.real_name;
        });
      }
    },
    handleSelect(key) {
      // console.log(key, keyPath);
      localStorage.setItem("tabbar", key);
      this.activeIndex = key;
    },

    //基本资料 文件
    handleAvatarSuccess(res, file) {
      this.alicode = res.data.img;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 PNG 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 5MB!");
      // }
      return isJPG && isLt2M;
    },

    //修改头像
    handleAvatarSuccess1(res, file) {
      this.head_pic = res.data.img;
    },
    //微信绑定
    show() {
      bangWx().then((res) => {
        if (res.code == 200) {
          window.location.href = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //截取 code
    getWxCode() {
      if (!this.$route.query.code) {
        return;
      }

      this.wxCode = this.$route.query.code;

      bindingWx({
        code: this.wxCode,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          let path = this.$route.path; //先获取路由路径

          this.$router.push(path);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //获取验证码
    getPhoneCode() {
      alert(111);
      if (this.start) {
        return;
      }
      bindingPhone({
        tel: this.phones,
      }).then((res) => {
        if (res.code == 200) {
          this.start = !this.start;
          var t = this.num;
          var time = setInterval(() => {
            this.num = t--;
            if (this.num == 0) {
              clearInterval(time);
              this.start = !this.start;
              this.num = 60;
            }
          }, 1000);
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },

    //填写验证码确认
    bindingPhoneOpDo() {
      bindingPhoneOp({
        tel: this.phones,
        code: this.code,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    protocol(type) {
      this.$router.push({
        path: "/clause",
        query: {
          type: type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
#upload {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e66617;
  line-height: 14px;
}
.bottom {
  background: #ffffff;
  border-radius: 6px;

  padding: 23px 19px 23px 19px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 36px;
    padding-left: 4px;
  }

  .title::after {
    content: "";
    width: 83px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -15px;
  }

  .content1 {
    margin-top: 39px;

    .notice {
      font-size: 14px;
      font-weight: 400;
      color: #c92323;
      line-height: 14px;
      padding-bottom: 40px;
    }

    .hedui {
      width: 269px;
      font-size: 14px;
      font-weight: 400;
      color: #c92323;
      line-height: 36px;
      text-align: center;
    }
  }

  .content2 {
    margin-top: 39px;

    .touxiang {
      display: flex;
      align-items: center;

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #e66617;
        line-height: 14px;
        padding-left: 17px;
        cursor: pointer;
      }
    }
  }

  .content3 {
    margin-top: 39px;
    // padding: 0 229px;

    .huoqu {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #e66617;
      padding-left: 22px;
      cursor: pointer;
    }

    .sanfang {
      font-size: 14px;

      font-weight: 400;
      color: #666666;
      line-height: 36px;
    }

    .wx {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        padding-left: 15px;
      }
    }
  }

  .content4 {
    margin-top: 39px;

    .yzm {
      font-size: 16px;
      font-weight: 400;
      color: #e66617;
      line-height: 16px;
      padding-left: 37px;
      cursor: pointer;
    }

    .text1 {
      font-size: 14px;

      font-weight: 400;
      color: #010101;
      line-height: 36px;
    }

    .text2 {
      font-size: 14px;

      font-weight: 400;
      color: #08243c;
      line-height: 36px;
    }

    .tijiao {
      width: 186px;
      height: 50px;
      background: #08243c;
      border-radius: 6px;

      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.tijiao {
  width: 186px;
  height: 50px;
  background: #08243c;
  border-radius: 6px;

  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

/deep/.avatar {
  width: 100%;
  height: 100%;

  display: block;
}

/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #08243c;
}

/deep/.el-input__inner {
  background: rgba(8, 36, 60, 0.06);
}

/deep/.el-upload-dragger {
  width: 269px;
  height: 154px;
  background: rgba(8, 36, 60, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/deep/.el-divider--horizontal {
  margin-top: 52px;
  margin-bottom: 35px;
}
/deep/.el-textarea__inner {
  background: rgba(8, 36, 60, 0.06);
}
/deep/.el-form-item__content{
	width:100% !important;
}
</style>