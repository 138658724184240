import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index'
import Login from '../pages/login'
import Openarea from '../pages/openArea'
import OpenareaDetail from '../pages/openArea/detail.vue'
import all from "../pages/all/all.vue"
import Author from '../pages/author'
import Clause from '../pages/clause'
import Fitting from '../pages/fitting'
import Buyer from "../pages/buyer"
import BuyOrder from "../pages/buyer/order.vue"
import BuyCar from "../pages/buyer/car.vue"
import MyFollow from "../pages/buyer/myFollow.vue"
import Data from "../pages/buyer/data.vue"
import Vip from "../pages/buyer/vip.vue"
import Recharge from "../pages/buyer/recharge.vue"
import RechargeDetails from "../pages/buyer/rechargeDetails.vue"
import promote from "../pages/buyer/promote.vue"
import talk from "../pages/buyer/talk.vue"
import mail from "../pages/seller/mail.vue"
import Seller from "../pages/seller"
import Center from "../pages/seller/center"
import Upload from "../pages/seller/upload.vue"
import Uploads from "../pages/seller/uploads.vue"
import Manage from "../pages/seller/manage.vue"
import Order from "../pages/seller/order.vue"
import ManageDetail from "../pages/seller/manageDetail.vue"
import SellerData from "../pages/seller/data.vue"
import SellerInfo from "../pages/seller/sellerInfo.vue"
import Withdrawal from "../pages/seller/withdrawal.vue"
import WithdrawDetail from "../pages/seller/withdrawalDetail.vue"
import search from "../pages/search"
import ManageUpdata from "../pages/seller/manageUpdata.vue"
import Recruit from "../pages/recruit/index.vue"
import RecruitDetail from "../pages/recruit/detail.vue"
import RecruitRelease from "../pages/recruit/release.vue"
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		redirect: '/index'
	},
	//首页
	{
		path: '/index',
		name: 'index',
		component: Index
	},
	//招聘
	{
		path: '/recruit',
		component: Recruit
	},
	//招聘
	{
		path: '/recruitDetail',
		component: RecruitDetail
	},
	//招聘
	{
		path: '/recruitRelease',
		component: RecruitRelease
	},
	//登录
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	//公开区
	{
		path: '/openArea/:id',
		component: Openarea
	},
	//全部精品
	{
		path: '/all/all',
		component: all
	},
	//公开区详情
	{
		path: '/openAreaDetail',
		component: OpenareaDetail,
		hidden: true
	},
	//作者主页
	{
		path: '/author',
		component: Author
	},
	//条款协议
	{
		path: '/clause',
		component: Clause
	},
	//试衣间
	{
		path: '/fitting',
		component: Fitting
	},
	//买家中心
	{
		path: '/buyer',
		component: Buyer,
		children: [
			//我的订单
			{
				path: 'buyOrder',
				component: BuyOrder
			},
			//我的购物车
			{
				path: 'buycar',
				component: BuyCar
			},
			//我的关注
			{
				path: 'myFollow',
				component: MyFollow
			},
			//数据中心
			{
				path: 'data',
				component: Data
			},
			//vip
			{
				path: 'vip',
				component: Vip
			},
			//充值
			{
				path: 'recharge',
				component: Recharge
			},
			//充值详情
			{
				path: 'rechargeDetails',
				component: RechargeDetails
			},
			//我的推广
			{
				path: 'promote',
				component: promote
			},
			//我的话题
			{
				path: 'talk',
				component: talk
			},
		]
	}, {
		//卖家中心
		path: '/seller',
		component: Seller,
		children: [
			//充值详情
			{
				path: 'center',
				component: Center
			},
			//上传作品
			{
				path: 'upload',
				component: Upload
			},
			//批量上传作品
			{
				path: 'uploads',
				component: Uploads
			},
			//作品管理
			{
				path: 'manage',
				component: Manage
			},
			//销售订单
			{
				path: 'order',
				component: Order
			},
			//作品详情
			{
				path: 'manageDetail',
				component: ManageDetail
			},
			//数据中心
			{
				path: 'sellerData',
				component: SellerData
			},
			//基本信息
			{
				path: 'sellerInfo',
				component: SellerInfo
			},
			//提现记录
			{
				path: 'withdrawal',
				component: Withdrawal
			},
			//提现详情
			{
				path: 'withdrawalDetail',
				component: WithdrawDetail
			},
			//作品修改
			{
				path: 'manageUpdata',
				component: ManageUpdata
			},
			//站内信
			{
				path: 'mail',
				component: mail
			}
		]
	},
	//susuo
	{
		path: '/search',
		component: search
	},
]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	}
})
export default router