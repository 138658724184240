<template>
  <div>
    <div class="bottom">
      <div class="title">站内信息</div>
      <div class="content" style="margin-top: 39px">
        <div class="information" v-for="(v, k) in tableData" :key="k">
          <div style="max-width: 80%">{{ v.remark }}</div>
          <div>
            <el-button>{{ v.readstatus }}</el-button>
            <el-button @click="detile(v.id)">详情</el-button>
          </div>
        </div>
      </div>
      <el-dialog
        title="站内信息"
        @close="closeThePopUpWindow()"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <span>{{ text }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Messagelist, MessagelistDetlle } from "../../request/api";
export default {
  data() {
    return {
      tableData: [],
      count: 1,
      size: 10,
      page: 1,
      information: [],
      dialogVisible: false,
      text: "",
    };
  },

  created() {
    this.getTopData();
  },
  methods: {
    getTopData() {
      Messagelist({}).then((res) => {
        // console.log(res);
        this.tableData = res.data.list;
        this.count = res.data.count;
        this.$forceUpdate();
      });
    },
    closeThePopUpWindow() {
      // this.getTopData();
      this.$router.go(0)
    },
    detile(v) {
      MessagelistDetlle({
        id: v,
      }).then((res) => {
        this.text = res.data;
        this.dialogVisible = true;
      });
    },

    //分页
    handleCurrentChange(val) {
      this.page = val;
      Messagelist({
        limit: this.page,
      }).then((res) => {
        // console.log(res);
        this.tableData = res.data.list;
        this.count = res.data.count;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 35px 0px 38px 0px;

  .top-item {
    display: flex;

    align-items: center;
    flex-direction: column;

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #08243c;
      line-height: 24px;
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      padding-top: 20px;
    }
  }

  .shu {
    width: 1px;
    height: 37px;
    background: #d7dadd;
  }
}

.bottom {
  background: #ffffff;
  border-radius: 6px;
  margin-top: 14px;
  padding: 23px 19px 23px 19px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 36px;
    padding-left: 4px;
  }

  .title::after {
    content: "";
    width: 83px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -15px;
  }
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}

.page-box {
  overflow: hidden;

  .pages {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  // background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
.information {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
