<template>
	<div class="dashboard-editor-container top">
		<!-- <el-upload class="avatar-uploader" action="" :http-request="handleChange" :on-change="handleChange"
			:on-remove="remove" :file-list="fileList" :limit="20" :multiple="true" :auto-upload="true"
			list-type="picture" accept=".png,.jpg"> -->
		<el-upload class="avatar-uploader" action="" :on-change="handleChange" :file-list="fileList"
			:show-file-list="true" list-type="picture" :limit="20" :auto-upload="false" :multiple="true"
			accept=".png,.jpg" :before-upload="beforeUpload">
			<div>
				<i class="el-icon-plus el-icon-circle-plus" style="font-size: 47px"></i>
				<div style="font-size: 16px; margin-top: 10px; color: rgba(0, 0, 0, 1)"> 点击上传多个预览图 </div>
				<div style="
            font-size: 12px;
            margin-top: 10px;
            color: rgba(102, 102, 102, 1);
          "> 正方形jpg格式或png格式，72分辨率，建议尺寸20*20cm </div>
			</div>
			<!-- <div class="upload-text">点击上传作品</div> -->
		</el-upload>
		<el-upload class="avatar-uploader" action="" :on-change="handleChange2" :file-list="fileList2"
			:show-file-list="false" :limit="20" :auto-upload="false" :multiple="true"
			accept=".JCH,.JAR,.JCS,.JCG,.JC8,.JC1,.tiff,.ai,.cdr,.eps,.pdf,.tif,.psd,.zip,.7z,.rar"
			:before-upload="beforeUpload2">
			<div>
				<i class="el-icon-plus el-icon-circle-plus" style="font-size: 47px"></i>
				<div style="font-size: 16px; margin-top: 10px; color: rgba(0, 0, 0, 1)"> 点击上传多个源文件 </div>
			</div>
		</el-upload>
		<div v-for="(v, k) in fileList2" :key="k" style="margin-bottom: 10px">
			<span>
				{{ v.name }}
			</span>
			<div class="df_flex">
				<el-progress style="margin-top: 10px; width: 80%" :text-inside="true" :stroke-width="18"
					:percentage="Number(v.percentage.toFixed(2))"></el-progress>
				<div class="x shou" @click="detileCor(v.name, k)">×</div>
			</div>
		</div>
		<span style="">请保持预览图和源文件前缀名一致!</span>
		<div size="small" type="success" @click="submitForm()" class="uploadDo"> 上传作品 </div>
		<el-dialog :visible.sync="show" width="23%" center>
			<div class="titles">您尚未进行实名认证</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" style="background: #08243c" @click="go('/seller/sellerInfo')">点 击 认 证
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		articleImgsUploades,
		realNameAuth
	} from "../../request/api";
	let OSS = require("ali-oss"); // 引入ali-oss插件
	const client = new OSS({
		region: "oss-cn-hangzhou",
		success_action_status: "200", // 默认200
		accessKeyId: "LTAI5tKTamXV5wVyLAqD8gwX",
		accessKeySecret: "uegS74bKck0j6LpceJTswm3rs1OgRC",
		bucket: "drawing-by-drawing",
	});
	export default {
		data() {
			return {
				fileList: [],
				fileList2: [],
				file: null,
				tempCheckpoint: null, // 用来缓存当前切片内容
				uploadId: "",
				uploadStatus: null, // 进度条上传状态
				percentage: 0, // 进度条百分比
				uploadName: "", //Object所在Bucket的完整路径
				PreviewList: [],
				sourceFileList: [],
				show: false, //是否认证
			};
		},
		created() {
			this.realNameAuth();
		},
		methods: {
			//判断用户是否实名认证
			realNameAuth() {
				realNameAuth().then((res) => {
					// console.log(res);
					this.disabled = false;
					if (res.code == 500) {
						this.disabled = true;
						this.show = !this.show;
					}
				});
			},
			beforeUpload2(file) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
				const whiteList = ['JCH', 'JAR', 'JCS', 'JCG', 'JC8', 'JC1', 'tiff', 'ai', 'cdr', 'eps', 'pdf', 'tif',
					'psd', 'zip', '7z', 'rar'
				];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message.warning("上传源文件格式错误");
					return false;
				}
			},
			isJson(obj) {
				return (typeof obj === "object" && Object.prototype.toString.call(obj) === "[object Object]");
			},
			beforeUpload(file) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
				const whiteList = ["jpg", "png"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message.warning("上传预览图只能是 jpg,png格式");
					return false;
				}
			},
			go() {
				localStorage.setItem("tabbar", "4");
				this.$router.push("/seller/sellerInfo");
			},
			// 点击上传至服务器
			submitForm(url, file) {
				if (this.fileList.length == 0) {
					this.$message.error("请上传预览图");
					return;
				}
				if (this.fileList2.length == 0) {
					this.$message.error("请上传源文件");
					return;
				}
				if (this.PreviewList.length != this.sourceFileList.length) {
					this.$message.error("请保持预览图 源文件数量一致");
					return;
				}
				let arr = new Array();
				//   raw_litpic: JSON.stringify(
				//   arr.concat(this.PreviewList).concat(this.sourceFileList)
				// ),
				articleImgsUploades({
					pre: this.PreviewList,
					sor: this.sourceFileList,
				}).then((res) => {
					console.log(res.code);
					if (res.code == 200) {
						console.log(66666);
						this.$message.success(res.msg);
						this.fileList = [];
						this.fileList2 = [];
						this.PreviewList = [];
						this.sourceFileList = [];
					} else {
						console.log(777777);
						this.$message.error(res.msg);
					}
				});
			},
			detileCor(v, k) {
				for (let index = 0; index < this.sourceFileList.length; index++) {
					if (this.sourceFileList[index].raw_name == v) {
						this.sourceFileList.splice(index, 1);
					}
				}
				this.fileList2.splice(k, 1);
			},
			// 切片上传
			async multipartUpload(url, file, percentage) {
				console.log(percentage);
				if (!file) {
					this.$message.error("请选择文件");
					return;
				}
				this.uploadStatus = null;
				this.percentage = 0;
				try {
					const headers = {
						"Content-Disposition": "inline",
						"Content-Type": file.type,
					};
					let result = await client.multipartUpload(url + Date.now() + "_" + file.name, file, {
						headers: headers,
						progress: (p, checkpoint) => {
							if (checkpoint != undefined) {
								this.tempCheckpoint = checkpoint;
								this.upload = checkpoint.uploadId;
								this.uploadName = checkpoint.name;
								if (url == this.getpath()) {
									// this.fileList[this.fileList.length - 1].percentage = p * 100;
								} else {
									this.fileList2.forEach((element, index) => {
										if (checkpoint.name.indexOf(element.name) != -1) {
											this.fileList2[index].percentage = p * 100;
										}
									});
								}
							} else {
								this.fileList2.forEach((element, index) => {
									this.fileList2[index].percentage = 100;
								});
							}
						},
						parallel: 4,
						// 设置分片大小。默认值为1 MB，最小值为100 KB。
						partSize: 1024 * 1024,
						meta: {
							year: 2020,
							people: "dev"
						},
						mime: file.type,
					});
					console.log(result);
					if (url == this.getpath()) {
						this.PreviewList.push({
							litpic: `https://image.yituhuitu.com${result.name}`,
							true_name: file.name,
						});
						this.$forceUpdate();
					} else {
						this.sourceFileList.push({
							raw_litpic: `https://image.yituhuitu.com${result.name}`,
							raw_name: file.name,
						});
					}
					console.log();
					this.$message.success("上传成功");
				} catch (e) {
					console.log(e);
					window.addEventListener("online", this.resumeUpload); // 该监听放在断网的异常处理
					// 捕获超时异常。
					if (e.code === "ConnectionTimeoutError") {
						// 请求超时异常处理
						this.uploadStatus = "exception";
					}
				}
			},
			// 选择文件发生改变
			handleChange(file, fileList) {
				console.log('123456789');
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
				const whiteList = ["jpg", "png"];
				const isLt2M = file.size / 1024 / 1024 < 1;
				if (!isLt2M) {
					this.$message.error('上传预览图大小不能超过 1MB!');
					return false;
				}
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message.warning("上传预览图只能是 jpg,png格式");
					return false;
				} else {
					this.fileList = fileList;
					this.multipartUpload(this.getpath(), file.raw, file);
				}
			},
			getpath() {
				const today = new Date();
				const year = today.getFullYear();
				const month = String(today.getMonth() + 1).padStart(2, '0');
				const day = String(today.getDate()).padStart(2, '0');
				return "/imgs/" + year + month + day + '/';
			},
			remove(file, fileList) {
				console.log(file.name, fileList);
				for (let index = 0; index < this.PreviewList.length; index++) {
					if (this.PreviewList[index].true_name == file.name) {
						this.PreviewList.splice(index, 1);
					}
				}
				console.log(this.PreviewList);
			},
			handleChange2(file, fileList) {
				this.fileList2 = fileList;
				const today = new Date();
				const year = today.getFullYear();
				const month = String(today.getMonth() + 1).padStart(2, '0');
				const day = String(today.getDate()).padStart(2, '0');
				this.multipartUpload("/sourceFile/" + year + month + day + '/', file.raw, file);
			},
		},
	};
</script>
<style lang='less' scoped>
	.upload-demo {
		margin-bottom: 100px;
	}

	.avatar-uploader {
		margin-bottom: 20px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	/deep/ .el-upload {
		width: 237px;
		height: 154px;
		background: rgba(8, 36, 60, 0.06);
		// opacity: 0.06;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;

		.upload-text {
			font-size: 16px;
			font-weight: 400;
			color: #000000;
			line-height: 36px;
		}
	}

	.uploadDo {
		width: 186px;
		height: 50px;
		background: #08243c;
		border-radius: 6px;
		font-size: 18px;
		font-weight: 400;
		color: #ffffff;
		line-height: 50px;
		text-align: center;
		margin-top: 15px;
		cursor: pointer;
	}

	.top {
		background: #ffffff;
		border-radius: 6px;
		padding: 34px 54px 26px 54px;
	}

	.x {
		font-size: 20px;
	}

	.df_flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>