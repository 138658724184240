<template>
  <div>
    <div class="top">
      <div class="top-item">
        <div class="num">{{ ConAmount }}</div>
        <div class="text">消费金额</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{ buyNum }}</div>
        <div class="text">购买次数</div>
      </div>
      <div class="shu"></div>
      <div class="top-item">
        <div class="num">{{ moneyOrder }}</div>
        <div class="text">充值金额</div>
      </div>
      <div class="shu"></div>

      <div class="top-item">
        <div class="num">{{ freedownloads }}</div>
        <div class="text">免费下载次数</div>
      </div>
    </div>

    <div class="bottom">
      <div class="title">我的订单</div>
      <div class="content" style="margin-top: 39px">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="img" label="作品展示图" align="center">
            <template slot-scope="scope">
              <img
                :src="imageUrl(scope.row.product_litpic)"
                alt=""
                style="width: 54px; height: 54px; display: block; margin: auto;object-fit: cover;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="product_name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="product_id" label="编号" align="center">
          </el-table-column>

          <el-table-column prop="order_code" label="订单号" align="center">
          </el-table-column>

          <el-table-column
            prop="add_time"
            label="订单时间"
            width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="order_amount"
            label="价格"
            width="60"
            align="center"
          >
          </el-table-column>

          <el-table-column label="交易状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.order_status == 0">未付款</span>
              <span v-if="scope.row.order_status == 1">待收货</span>
              <span v-if="scope.row.order_status == 2">已完成</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                style="background: #08243c; border: none"
                @click="confirmOrderDo(scope.row.order_id)"
                v-if="scope.row.order_status == 1"
                >确认收货
              </el-button>

              <el-button
                size="mini"
                type="success"
                @click="goUpload(scope.row.product_id)"
                style="border: none"
                >去下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-box">
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { memberTopData, myOrder, confirmOrder } from "../../request/api";
export default {
  data() {
    return {
      ConAmount: "", //消费金额
      buyNum: "", //购买数量

      moneyOrder: "", //充值金额
      freedownloads: "",
      tableData: [],
      count: 1,
      size: 4,
      page: 1,
    };
  },

  created() {
    this.getTopData();
    this.getMyOrder();
  },
  methods: {
    getTopData() {
      memberTopData({
        type: 1,
      }).then((res) => {
        this.ConAmount = res.data.ConAmount;
        this.buyNum = res.data.buyNum;
        this.moneyOrder = res.data.moneyOrder;
        this.freedownloads = res.data.freedownloads;
      });
    },

    goUpload(aid) {
      this.$router.push({
        path: "/openAreaDetail",
        query: {
          aid: aid,
        },
      });
    },

    getMyOrder() {
      myOrder({
        page: this.page,
        size: this.size,
      }).then((res) => {
        console.log(res);
        console.log(111111111);
        this.tableData = res.data.order;
        this.count = res.data.count;
      });
    },

    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getMyOrder();
    },

    //确认收货
    confirmOrderDo(order_id) {
      confirmOrder({
        order_id: order_id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getMyOrder();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 35px 0px 38px 0px;

  .top-item {
    display: flex;

    align-items: center;
    flex-direction: column;

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #08243c;
      line-height: 24px;
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      padding-top: 20px;
    }
  }

  .shu {
    width: 1px;
    height: 37px;
    background: #d7dadd;
  }
}

.bottom {
  background: #ffffff;
  border-radius: 6px;
  margin-top: 14px;
  padding: 23px 19px 23px 19px;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: #08243c;
    line-height: 36px;
    padding-left: 4px;
  }

  .title::after {
    content: "";
    width: 83px;
    height: 9px;
    background: #e66617;
    display: block;
    margin-top: -15px;
  }
}

/deep/.el-table th {
  background: rgba(8, 36, 60, 0.04);
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

/deep/.el-table td {
  padding: 25px 0;
}

.page-box {
  overflow: hidden;

  .pages {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
</style>
